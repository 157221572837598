// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.callback-block {
  width: 100%;
  height: 350px;
  background: linear-gradient(to right, #f89a00, #fce30b);
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding-top: 100px;
  box-sizing: border-box;
}

.callback-header {
  font-size: 36px;
  line-height: 44px;
}

.callback-desc {
  padding: 0px 30% 0px 30%;
  line-height: 24px;
  vertical-align: top;
}

/*.callback-desc::before {*/
/*content: '-';*/
/*display: inline-block;*/
/*position: relative;*/
/*color: #fff;*/
/*}*/

.callback-button {
  margin-top: 30px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  line-height: 18px;
  padding: 18px 44px;
  display: inline-block;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/assets/static/style/CallBack/CallBack.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uDAAuD;EACvD,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA,2BAA2B;AAC3B,gBAAgB;AAChB,yBAAyB;AACzB,sBAAsB;AACtB,eAAe;AACf,IAAI;;AAEJ;EACE,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":[".callback-block {\n  width: 100%;\n  height: 350px;\n  background: linear-gradient(to right, #f89a00, #fce30b);\n  color: #fff;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: center;\n  padding-top: 100px;\n  box-sizing: border-box;\n}\n\n.callback-header {\n  font-size: 36px;\n  line-height: 44px;\n}\n\n.callback-desc {\n  padding: 0px 30% 0px 30%;\n  line-height: 24px;\n  vertical-align: top;\n}\n\n/*.callback-desc::before {*/\n/*content: '-';*/\n/*display: inline-block;*/\n/*position: relative;*/\n/*color: #fff;*/\n/*}*/\n\n.callback-button {\n  margin-top: 30px;\n  cursor: pointer;\n  background-color: #fff;\n  color: #000;\n  line-height: 18px;\n  padding: 18px 44px;\n  display: inline-block;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
