// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
  width: 100%;
}

.contact-info {
  width: 40%;
  display: inline-block;
  box-sizing: border-box;
  padding-left: 100px;
  vertical-align: top;
}

.contact-mrg {
  margin-top: 90px;
}

.contact-info-icon {
  display: inline-block;
  width: 12%;
  vertical-align: top;
  text-align: right;
  margin-right: 20px;
}

.contact-info-text {
  width: 69%;
  color: #262626;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
  display: inline-block;
}

.contact-info-text-title {
  font-weight: 600;
}

.contact-info-text-desc {
  padding-top: 20px;
}

.contact-map {
  width: 60%;
  display: inline-block;
}

.contact-map-gray-layer {
}

/*настройка карты*/
`, "",{"version":3,"sources":["webpack://./src/assets/static/style/Contacts.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,qBAAqB;AACvB;;AAEA;AACA;;AAEA,kBAAkB","sourcesContent":[".contact {\n  width: 100%;\n}\n\n.contact-info {\n  width: 40%;\n  display: inline-block;\n  box-sizing: border-box;\n  padding-left: 100px;\n  vertical-align: top;\n}\n\n.contact-mrg {\n  margin-top: 90px;\n}\n\n.contact-info-icon {\n  display: inline-block;\n  width: 12%;\n  vertical-align: top;\n  text-align: right;\n  margin-right: 20px;\n}\n\n.contact-info-text {\n  width: 69%;\n  color: #262626;\n  font-size: 24px;\n  font-weight: 300;\n  line-height: 29px;\n  display: inline-block;\n}\n\n.contact-info-text-title {\n  font-weight: 600;\n}\n\n.contact-info-text-desc {\n  padding-top: 20px;\n}\n\n.contact-map {\n  width: 60%;\n  display: inline-block;\n}\n\n.contact-map-gray-layer {\n}\n\n/*настройка карты*/\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
