import React, { Component } from 'react'
import EcommerceBlock from 'components/Solutions/Ecommerce'
import TitleEcommerce from 'components/TitleBlock/title_ecommerce'
import Header from 'components/Header/Header'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class ECommerce extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="КИ Импортозамещение – создания автоматизированных систем на Российских комплектующих"
          keywords="КРИЗИС ИНТЕГРАТОР"
          description="Решения для автоматизированных систем на Российских комплектующих."
        />
        <Header />
        <TitleEcommerce />
        <EcommerceBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default ECommerce
