import React, { Component } from 'react'
import Header from 'components/Header/Header'
import TitleIndustry from 'components/TitleBlock/title_industry'
import IndustryBlock from 'components/Industry/Industry'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Industry extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="Отрасли применения решений КРИЗИС ИНТЕГРАТОР"
          keywords="государство, транспорт, электронная коммерция, жилищно-коммунальное хозяйство, безопасность, программное обеспечение, ПО, разработка, Москва, Россия, КРИЗИС ИНТЕГРАТОР"
          description="КРИЗИС ИНТЕГРАТОР представляет реализацию инновационных решений в отраслях: государство, транспорт, электронная коммерция, жилищно-коммунальное хозяйство, безопасность."
        />
        <Header />
        <TitleIndustry />
        <IndustryBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Industry
