import React from 'react'
import { Link, IndexLink } from 'react-router'
import classnames from 'classnames'
import Slide3d from '../Slider/Slider3D/Slider3D'

class TsSecurity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slide: 'one',
    }
    this.checkVerif = this.checkVerif.bind(this)
  }

  checkVerif(value) {
    this.setState({
      slide: value,
    })
  }

  render() {
    const images = [
      'https://609111.selcdn.ru/ki/static/images/slider-ux/transport-security/1.1.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/transport-security/2.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/transport-security/3.png',
    ]
    return (
      <div className="transport-security-block">
        <div className="partner-solution">
          <div className="transport-security-title">
            <div className="first-tab-title">
              КРИЗИС ИНТЕГРАТОР (Транспортная безопасность)
            </div>
            <span>Возможности</span>
            <span className="about-text-decs">
              Российская аппаратно-программная платформа транспортной
              безопасности на базе отечественных разработок в области
              нейротехнологий, машинного обучения и геопозиционирования для
              внедрения федеральной системы мониторинга, контроля и
              предупреждения инцидентов на транспорте
            </span>
          </div>
          <div className="partner-solution-block">
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/Group51.svg"
                alt="icon"
              />
              <div className="partner-solution-elem-title">
                Транспортная безопасность
              </div>
              <div className="partner-solution-elem-decs">
                Снижение влияния человеческого фактора на транспортную
                безопасность
              </div>
            </div>
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/Group_43.svg"
                alt="icon"
              />
              <div className="partner-solution-elem-title">
                Риск-ориентированный подход
              </div>
              <div className="partner-solution-elem-decs">
                Предвосхищение негативных сценариев на транспорте вместо борьбы
                с последствиями
              </div>
            </div>
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/Group_52.svg"
                alt="icon"
              />
              <div className="partner-solution-elem-title">
                Информационное обеспечение
              </div>
              <div className="partner-solution-elem-decs">
                Доказательная база для страховых случаев на транспорте
              </div>
            </div>
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/Group_42.svg"
                alt="icon"
              />
              <div className="partner-solution-elem-title">
                Антитеррористические меры
              </div>
              <div className="partner-solution-elem-decs">
                Снижение вероятности террористических актов на транспорте
              </div>
            </div>
          </div>
        </div>
        <div className="ts-security-solution-desc">
          <div className="ts-security-solution-desc-title">
            <div className="ts-security-solution-desc-title-btn">
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  {
                    'ts-security-btn-active': this.state.slide === 'one',
                  },
                )}
                onClick={() => this.checkVerif('one')}
              >
                Концепция системы
              </div>
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  {
                    'ts-security-btn-active': this.state.slide === 'two',
                  },
                )}
                onClick={() => this.checkVerif('two')}
              >
                Архитектура системы
              </div>
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  {
                    'ts-security-btn-active': this.state.slide === 'three',
                  },
                )}
                onClick={() => this.checkVerif('three')}
              >
                Состав системы
              </div>
            </div>
            <div className="ts-security-solution-desc-title-btn-logo">
              Описание системы
            </div>
          </div>
          <div
            className={classnames('', {
              block: this.state.slide === 'one',
              'display-none': this.state.slide !== 'one',
            })}
          >
            <div className="ts-security-solution-desc-slide">
              <div className="ts-security-solution-desc-slide-text">
                <h2>Как это работает</h2>
                <span>
                  Система позволяет в реальном времени реализовать полный цикл
                  сбора, анализа, хранения, визуализации информации о
                  местоположении транспортного средства, физическом и
                  психоэмоциональном состоянии водителя, техническом состоянии
                  ТС. Время отработки цикла – до 2 секунд
                </span>
              </div>
              <div className="ts-security-solution-desc-slide-block hadr-block">
                <div className="hadr-block-title">
                  <div className="hadr-block-title-icon">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_10.svg"
                      alt="icon"
                    />
                    <div>Глонас</div>
                  </div>
                  <div className="hadr-block-title-icon w60">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_37.svg"
                      alt="icon"
                    />
                    <div>GPRS</div>
                    <div className="hadr-block-title-arrow-border" />
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_37.svg"
                      alt="icon"
                    />
                    <div>GPRS</div>
                  </div>
                </div>
                <div className="hadr-block-body">
                  <div className="hadr-block-body-block-icon">
                    <div className="hadr-block-body-block-icon-top">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/auto.svg"
                        alt="icon"
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/boat.svg"
                        alt="icon"
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/bus.svg"
                        alt="icon"
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/plane.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="hadr-block-body-block-icon-body">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/Page1.png"
                        alt="icon"
                      />
                    </div>
                    <div className="hadr-block-body-block-icon-bottom">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/bus1.svg"
                        alt="icon"
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/group-31.svg"
                        alt="icon"
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/bus2.svg"
                        alt="icon"
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/auto.svg"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="hadr-block-body-block-main">
                    <div className="hadr-block-body-block-main-elem">
                      <div className="hadr-block-body-block-main-elem-title">
                        Транспортное средство
                      </div>
                      <div className="hadr-block-body-block-main-elem-text cam span-yellow-dot">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_34.svg"
                          alt="icon"
                        />
                        <ul>
                          <li>Ненормальное поведен</li>
                          <li>Возбужденное или подавленное состояние</li>
                        </ul>
                      </div>
                      <div className="hadr-block-body-block-main-elem-text mind span-yellow-dot">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/group-17.svg"
                          alt="icon"
                        />
                        <ul>
                          <li>Сонливость</li>
                          <li>Усталость</li>
                          <li>Стресс</li>
                          <li>Сонливость</li>
                        </ul>
                      </div>
                      <div className="hadr-block-body-block-main-elem-text watch span-yellow-dot">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_35.svg"
                          alt="icon"
                        />
                        <ul>
                          <li>Усталость</li>
                          <li>Стресс</li>
                        </ul>
                      </div>
                    </div>
                    <div className="hadr-block-body-block-main-elem-icon">
                      <div className="hadr-block-body-block-main-elem-icon-block">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_38.svg"
                          alt="icon"
                        />
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_39.svg"
                          alt="icon"
                        />
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_40.svg"
                          alt="icon"
                        />
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/heart.svg"
                          alt="icon"
                        />
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_41.svg"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="hadr-block-body-block-arrow">
                    <div className="hadr-block-body-block-arrow-elem">
                      <img
                        className="revert-90"
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                        alt="icon"
                      />
                      <div className="">Данные</div>
                      <img
                        className="revert90"
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="hadr-block-body-block-arrow-elem">
                      <img
                        className="revert-90"
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                        alt="icon"
                      />
                      <div className="">Данные</div>
                      <img
                        className="revert90"
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="hadr-block-body-block-main-two">
                    <div className="hadr-block-body-block-main-two-elem">
                      <div className="hadr-block-body-block-main-two-elem-title">
                        Центр обработки данных
                      </div>
                      <div className="hadr-block-body-block-main-two-elem-img">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/ecosystem.svg"
                          alt="icon"
                        />
                        {/* <span>Экосистема</span>*/}
                      </div>
                      {/* <div className="hadr-block-body-block-main-two-elem-text span-yellow-dot">*/}
                      {/*    <ul>*/}
                      {/*        <li>Сбор</li>*/}
                      {/*        <li>Анализ</li>*/}
                      {/*        <li>Моделирование</li>*/}
                      {/*        <li>Прогнозирование</li>*/}
                      {/*        <li>Расчет рисков</li>*/}
                      {/*    </ul>*/}
                      {/* </div>*/}
                    </div>

                    <div className="hadr-block-body-block-main-two-arrow">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow-small.svg"
                        alt="icon"
                      />
                      <span>Данные</span>
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow-small.svg"
                        alt="icon"
                      />
                    </div>

                    <div className="hadr-block-body-block-main-two-elem">
                      <div className="hadr-block-body-block-main-two-elem-icon">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_12.svg"
                          alt="icon"
                        />
                        <div>Служба мониторинга и раннего предупреждения</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classnames('', {
              block: this.state.slide === 'two',
              'display-none': this.state.slide !== 'two',
            })}
          >
            <div className="ts-security-solution-desc-slide media-hard">
              <div className="ts-security-solution-desc-slide-text">
                <h2>Как это работает</h2>
                <span>
                  <b>2G/3G/4G</b> – передача данных через сети сотовой связи с
                  различной пропускной способностью
                </span>
                <span>
                  <b>Wi-Fi</b> – Стандарт беспроводной связи, обеспечивающий
                  высокую пропускную способность
                </span>
                <span>
                  <b>CAN</b> – Стандарт для передачи данных в автомобильной
                  автоматике
                </span>
                <span>
                  <b>REST</b> – архетиктура взаимодействия клиент-серверных
                  приложений в сети
                </span>
              </div>
              <div className="ts-security-solution-desc-slide-block hadr-block-two">
                <div className="hadr-block-two-body">
                  <div className="hadr-block-two-body-left">
                    <div className="hadr-block-two-body-left-title">
                      Программно-аппаратные блоки, устанавливаемые в
                      странспортное сердство
                    </div>
                    <div className="hadr-block-two-body-left-elem">
                      <div className="hadr-block-two-body-left-elem-block">
                        <div>
                          <img
                            src="https://609111.selcdn.ru/ki/static/images/era-glonass.png"
                            alt=""
                          />
                        </div>
                        <span>ЭРА-ГЛОНАСС</span>
                      </div>
                      <div className="hadr-block-two-body-left-elem-block">
                        <div>
                          <img
                            src="https://609111.selcdn.ru/ki/static/images/camm.png"
                            alt=""
                          />
                        </div>
                        <span>3D камера</span>
                      </div>
                      <div className="hadr-block-two-body-left-elem-block">
                        <div>
                          <img
                            src="https://609111.selcdn.ru/ki/static/images/fitnes.png"
                            alt=""
                          />
                        </div>
                        <span>БФА</span>
                      </div>
                      <div className="hadr-block-two-body-left-elem-block">
                        <div>
                          <img
                            src="https://609111.selcdn.ru/ki/static/images/block-data.png"
                            alt=""
                          />
                        </div>
                        <span>Блок данных о состоянии</span>
                      </div>
                    </div>
                    <div className="hadr-block-two-body-left-block">
                      <div className="hadr-block-two-body-left-block-elem w100">
                        <div className="top-arrow-transport-security">
                          <span>USB</span>
                          <img
                            src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                            alt=""
                          />
                        </div>
                      </div>

                      <div className="hadr-block-two-body-left-block-elem w50">
                        <div className="center-arrow-transport-security">
                          <img
                            className="revert-90"
                            src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                            alt=""
                          />
                          <span>USB</span>
                        </div>
                        <div className="center-arrow-transport-security">
                          <img
                            className="revert-90"
                            src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                            alt=""
                          />
                          <span>BT</span>
                        </div>
                      </div>
                      <div className="hadr-block-two-body-left-block-elem list w50">
                        <span>Video+</span>
                        <span>Transport</span>
                      </div>
                      <div className="hadr-block-two-body-left-block-elem w100">
                        <div className="bottom-arrow-transport-security">
                          <span>USB</span>
                          <img
                            className="revert180"
                            src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hadr-block-two-body-center">
                    <div>2G\3G\4G</div>
                    <div>WI-FI</div>
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-28.svg"
                      alt=""
                    />
                  </div>
                  <div className="hadr-block-two-body-right">
                    <div className="hadr-block-two-body-right-title">
                      Серверная часть БПМН
                    </div>
                    <div className="hadr-block-two-body-right-body">
                      <div className="hadr-block-two-body-right-elem w70">
                        <div className="hadr-block-two-body-right-elem-item">
                          <img
                            src="https://609111.selcdn.ru/ki/static/images/fill-538.svg"
                            alt=""
                          />
                          <span>Аналитика</span>
                        </div>
                        <div className="hadr-block-two-body-right-elem-item">
                          <img
                            src="https://609111.selcdn.ru/ki/static/images/fill-539.svg"
                            alt=""
                          />
                          <span>Хранилище данных</span>
                        </div>
                      </div>
                      <div className="hadr-block-two-body-right-elem-arrow w30" />
                    </div>
                  </div>
                </div>
                <div className="hadr-block-two-bottom">
                  <div className="hadr-block-two-bottom-left">
                    2G\3G\4G\WI-FI
                  </div>
                  <div className="hadr-block-two-bottom-center">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/fill-540.svg"
                      alt=""
                    />
                  </div>
                  <div className="hadr-block-two-bottom-right">HTTP</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classnames('', {
              block: this.state.slide === 'three',
              'display-none': this.state.slide !== 'three',
            })}
          >
            <div className="ts-security-solution-desc-slide back-none">
              <div className="ts-security-solution-desc-slide-block-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/134.svg"
                  alt=""
                />
                <div className="ts-security-solution-desc-slide-block-elem-text">
                  <h3>Блок сбора-анализа-передачи данных (БСАП)</h3>
                  <span>
                    Система агрегации данных с различных источников,
                    взаимодействующая с водителем, ТС, диспетчером и серверными
                    системами. Отвечает за применение моделей анализа данных,
                    получаемых из блока построения нейросетевой модели режиме
                    реального времени
                  </span>
                </div>
              </div>
              <div className="ts-security-solution-desc-slide-block-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/rty.svg"
                  alt=""
                />
                <div className="ts-security-solution-desc-slide-block-elem-text">
                  <h3>Блок данных о состоянии ТС (БДТС)</h3>
                  <span>
                    Система сбора, обработки и анализа данных о техническом
                    состоянии ТС, включая данные о скоростном режиме, ошибках
                    двигателя, ДТП
                  </span>
                </div>
              </div>
              <div className="ts-security-solution-desc-slide-block-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/4564.svg"
                  alt=""
                />
                <div className="ts-security-solution-desc-slide-block-elem-text">
                  <h3>
                    Рабочее место оператора/Рабочее место руководителя (РМО/РМР)
                  </h3>
                  <span>
                    Система поддержки принятия решений с гибким и интуитивным
                    интерфейсом, позволяющим оператору Ситуационного центра
                    мгновенно отследить критическое событие и принять
                    соответствующие решения
                  </span>
                </div>
              </div>
              <div className="ts-security-solution-desc-slide-block-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/Group_35.svg"
                  alt=""
                />
                <div className="ts-security-solution-desc-slide-block-elem-text">
                  <h3>Блок физиологической активности (БФА)</h3>
                  <span>
                    Система контроля физиологического состояния водителя
                    транспортного средства с целью предотвращения засыпания,
                    контроля агрессии
                  </span>
                </div>
              </div>
              <div className="ts-security-solution-desc-slide-block-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/78.svg"
                  alt=""
                />
                <div className="ts-security-solution-desc-slide-block-elem-text">
                  <h3>Блок построения нейросетевой модели (БПНМ)</h3>
                  <span>
                    Когнитивная система анализа и обработки данных, построенная
                    на базе машинного обучения, с целью получения
                    высокопроизводительных предиктивных моделей, в дальнейшем
                    использующихся в блоке САП
                  </span>
                </div>
              </div>
              <div className="ts-security-solution-desc-slide-block-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/775.svg"
                  alt=""
                />
                <div className="ts-security-solution-desc-slide-block-elem-text">
                  <h3>Блок видеоаналитики (БВА)</h3>
                  <span>
                    Система автоматизированного мониторинга инцидентов как
                    внутри кабины водителя, так и во вне. Позволяет выделять из
                    видеопотока факты нарушений ПДД и записывать видео – файл с
                    нарушением, используемый в качестве доказательной базы
                  </span>
                </div>
              </div>
              <div className="ts-security-solution-desc-slide-block-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/385.svg"
                  alt=""
                />
                <div className="ts-security-solution-desc-slide-block-elem-text">
                  <h3>Хранилище данных (ХД)</h3>
                  <span>Система сбора, хранения и агрегации данных </span>
                </div>
              </div>
              <div className="ts-security-solution-desc-slide-block-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-10.svg"
                  alt=""
                />
                <div className="ts-security-solution-desc-slide-block-elem-text">
                  <h3>Государственная система «ЭРА-ГЛОНАСС» </h3>
                  <span>
                    Обеспечивает оперативное получение информации о
                    дорожно-транспортных и об иных происшествиях на
                    автомобильных дорогах в Российской Федерации, ее обработку,
                    хранение и передачу в экстренные оперативные службы
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Slide3d
          clas="tSec ux-slide"
          type="not"
          image={images}
          titleGray="КРИЗИС ИНТЕГРАТОР (Транспортная безопасность)"
          title="Интерфейс"
          description="Система мониторинга транспортной безопасности и поддержки принятия решений с гибким и интуитивным интерфейсом"
        />

        <div className="ts-security-analytics">
          <div className="ts-security-analytics-text">
            <h2>Прогнозирование</h2>
            <span>
              На основе статистики инцидентов и агрегации данных о состоянии
              водителя, транспортного средства, данных внешней среды в различных
              временных разрезах становится возможным прогнозировать вероятность
              наступления инцидента в следующем временном периоде
            </span>
          </div>
          <div className="ts-security-analytics-block">
            <div className="ts-security-analytics-block-elem">
              <div className="complex-model-bold">01.</div>
              <div>Агрегация данных</div>
            </div>
            <div className="triangle-right" />
            <div className="ts-security-analytics-block-elem">
              <div className="complex-model-bold">02.</div>
              <div>Объединение с накопленной статистикой</div>
            </div>
            <div className="triangle-right" />
            <div className="ts-security-analytics-block-elem">
              <div className="complex-model-bold">03.</div>
              <div>
                Разработка нейросетевой модели, обученной на статистике
                инцидентов
              </div>
            </div>
            <div className="triangle-right" />
            <div className="ts-security-analytics-block-elem-oops" />
            <div className="ts-security-analytics-block-elem">
              <div className="complex-model-bold">04.</div>
              <div>
                Данные передаются в модель, которая выдает вероятность
                наступления инцидента
              </div>
            </div>
            <div className="triangle-right" />
            <div className="ts-security-analytics-block-elem">
              <div className="complex-model-bold">05.</div>
              <div>Прогноз визуализируется в интерфейсе Системы</div>
            </div>
          </div>
        </div>
        <div className="ts-security-effects">
          <div className="ts-security-effects-text">
            <h2>Эффекты от внедрения системы</h2>
            {/* <span>Российская аппаратно-программная платформа транспортной безопасности на базе*/}
            {/* отечественных разработок в области нейротехнологий, машинного*/}
            {/* обучения и геопозиционирования для внедрения федеральной системы мониторинга, контроля*/}
            {/* и предупреждения инцидентов на транспорте*/}
            {/* </span>*/}
          </div>
          <div className="ts-security-effects-block">
            <div className="ts-security-effects-elem">
              <div className="ts-security-effects-elem-percent">
                <div className="title-percent">
                  до <span>60%</span>
                </div>
                <div className="line-percent">
                  <div className="line-percent-two w60" />
                </div>
                <div className="desc-percent">
                  Снижение количества ДТП по вине водителя
                </div>
              </div>
              <div className="ts-security-effects-elem-percent">
                <div className="title-percent">
                  до <span>30%</span>
                </div>
                <div className="line-percent">
                  <div className="line-percent-two w30" />
                </div>
                <div className="desc-percent">
                  Снижение общего ущерба от ДТП
                </div>
              </div>
              <div className="ts-security-effects-elem-percent">
                <div className="title-percent">
                  до <span>70%</span>
                </div>
                <div className="line-percent">
                  <div className="line-percent-two w70" />
                </div>
                <div className="desc-percent">
                  Снижение количества ДТП со смертельным исходом на общественном
                  транспорте
                </div>
              </div>
            </div>
            <div className="ts-security-effects-elem">
              <div className="ts-security-effects-elem-item">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-45.png"
                  alt=""
                />
                <div className="ts-security-effects-elem-item-text">
                  <h3>Статистика по проблемным маршрутам</h3>
                  <span>
                    Система позволит формировать статистику по проблемным
                    маршрутам и участкам дорог в соответствии с данными о
                    состоянии водителей, включая оценку качества дорожного
                    покрытия
                  </span>
                </div>
              </div>
              <div className="ts-security-effects-elem-item">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/contacts_addres.svg"
                  alt=""
                />
                <div className="ts-security-effects-elem-item-text">
                  <h3>Трекинг транспортных средств</h3>
                  <span>
                    Система позволит осуществлять трекинг транспортных средств в
                    регионе с привязкой к координатам, состоянию водителя,
                    режиму езды в реальном времени
                  </span>
                </div>
              </div>
              <div className="ts-security-effects-elem-item">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-47.png"
                  alt=""
                />
                <div className="ts-security-effects-elem-item-text">
                  <h3>Ранжирование водителей</h3>
                  <span>
                    Система позволит осуществлять ранжирование водителей по
                    манере езды и уровню профессионализма (для водителей
                    общественного транспорта)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TsSecurity
