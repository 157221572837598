import React from 'react'
import { Link } from 'react-router'
// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class Slider extends React.Component {
  render() {
    return (
      <div className="main-page-slider">
        {/* <EventListener target={document} onClick={(event) => this.sliders(event)}/>*/}

        <Carousel autoPlay interval={3000000} infiniteLoop>
          <div>
            <img
              src="https://609111.selcdn.ru/ki/static/images/slider/slider1.png"
              alt="slide1"
            />
            <h1 className="slider-text-title">
              Время инноваций в КРИЗИС ИНТЕГРАТОР
            </h1>

            <div className="slider-text-desc">
              Компания КРИЗИС ИНТЕГРАТОР - Создание и внедрение в ИТ-архитектуру
              заказчика отечественных цифровых решений
            </div>
            <div className="slider-text-anotate">
              <div className="partner-button">
                <Link to="/automation">Узнать больше</Link>
              </div>
            </div>
            {/* <div className="partner-button" onClick={this.handleClick}>Стать партнером</div>*/}
          </div>
          <div>
            <img
              src="https://609111.selcdn.ru/ki/static/images/slider/slider2.jpeg"
              alt="slide2"
            />

            <h2 className="slider-text-title">Умное импортозамещение</h2>

            <div className="slider-text-desc">
              КРИЗИС ИНТЕГРАТОР (Управление данными) – система бюджетного
              планирования в реестре российского ПО Минкомсвязи
            </div>
            <div className="slider-text-anotate">
              <div className="partner-button">
                <Link to="/data-control">Возможности</Link>
              </div>
            </div>
            {/* <div className="partner-button" onClick={this.handleClick}>Стать партнером</div>*/}
          </div>
          <div>
            <img
              src="https://609111.selcdn.ru/ki/static/images/slider/slider3.jpeg"
              alt="slide3"
            />

            <h2 className="slider-text-title">
              Безопасный транспорт - безопасное общество
            </h2>

            <div className="slider-text-desc">
              Технологии компьютерного зрения, искусственного интеллекта и
              геопозиционирования для безопасности общественного транспорта
            </div>
            <div className="slider-text-anotate">
              <div className="partner-button">
                <Link to="/transport-security">
                  Возможности КИ Безопасность
                </Link>
              </div>
            </div>
            {/* <div className="partner-button" onClick={this.handleClick}>Стать партнером</div>*/}
          </div>
        </Carousel>
        <div className="yellow-line" />
        {/* {*/}
        {/* this.state.isShowingModal &&*/}

        {/* <ModalContainer onClose={this.handleClose}>*/}
        {/* <ModalDialog className="callback-modal" onClose={this.handleClose}>*/}
        {/* <div className="modal-request">*/}
        {/* <div className="modal-request-form-block">*/}
        {/* <h2>Заявка на партнерство</h2>*/}
        {/* <div className="form-callback">*/}
        {/* <div className="form-callback-elem">*/}
        {/* <span className="form-callback-elem-text">Как к Вам обращаться?</span>*/}
        {/* <input type="text" placeholder="Ф.И.О" required/>*/}
        {/* </div>*/}
        {/* <div className="form-callback-elem">*/}
        {/* <span className="form-callback-elem-text">Должность</span>*/}
        {/* <input type="text" placeholder="Например, Генеральный директор" required/>*/}
        {/* </div>*/}
        {/* <div className="form-callback-elem twin-elem">*/}
        {/* <span className="form-callback-elem-text">E-mail</span>*/}
        {/* <input type="text" placeholder="sample@mail.ru" required/>*/}
        {/* </div>*/}
        {/* <div className="form-callback-elem twin-elem">*/}
        {/* <span className="form-callback-elem-text">Телефон</span>*/}
        {/* <input type="tel" placeholder="+7 (___) ___-__-__" required/>*/}
        {/* </div>*/}
        {/* </div>*/}
        {/* <div className="upload-file">*/}
        {/* <img src="https://609111.selcdn.ru/ki/static/images/download_file.png" alt=""/>*/}
        {/* <div className="file-text">*/}
        {/* <span className="file-title">Загрузить документы</span>*/}
        {/* <span className="file-desc">pdf, doc, jpg не более 15 mb</span>*/}
        {/* </div>*/}
        {/* </div>*/}
        {/* <div className="form-request-button-submit">*/}
        {/* Отправить заявку*/}
        {/* </div>*/}
        {/* <div className="offer-check">*/}
        {/* <label>*/}
        {/* <input type="checkbox" className="checkbox" hidden/>*/}
        {/* <span className="checkbox-custom"></span>*/}
        {/* <span className="offer-check-text">Согласен с условиями партнерского соглашения</span>*/}
        {/* </label>*/}
        {/* </div>*/}
        {/* </div>*/}
        {/* <div className="modal-request-offer-block">*/}
        {/* <h2>Партнерское соглашение</h2>*/}
        {/* <div className="modal-request-offer-block-text">*/}
        {/* <span className="modal-request-offer-block-text-elem">Вам будут доступны продукты КРИЗИС ИНТЕГРАТОР, позволяющие мгновенно и эффективно обрабатывать большие объемы данных.</span>*/}
        {/* <span className="modal-request-offer-block-text-elem">Скачайте пользовательское соглашение и загрузите необходимые документы для полноценной работы.</span>*/}
        {/* </div>*/}
        {/* <div className="download-file">*/}
        {/* <img src="https://609111.selcdn.ru/ki/static/images/pdf.svg" alt=""/>*/}
        {/* <div className="file-text">*/}
        {/* <span className="file-title">Партнерское соглашение</span>*/}
        {/* <span className="file-desc">pdf, 163 kb</span>*/}
        {/* </div>*/}
        {/* </div>*/}
        {/* <div className="form-request-button-lk">*/}
        {/* <img src="https://609111.selcdn.ru/ki/static/images/user_button.svg" alt=""/>*/}
        {/* Личный кабинет партнера*/}
        {/* </div>*/}
        {/* </div>*/}
        {/* </div>*/}
        {/* </ModalDialog>*/}
        {/* </ModalContainer>*/}
        {/* }*/}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Slider
