import React from 'react'
import { Link, IndexLink } from 'react-router'
import ReactDOM from 'react-dom'
import EventListener, { withOptions } from 'react-event-listener'
// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class SliderPartners extends React.Component {
  render() {
    return (
      <div>
        <div className="solutions-slider-top-block">
          <div className="solution-text">
            <span className="">Партнерская программа</span>
            <span className="about-text-decs">
              {' '}
              Основным каналом продвижения продуктов КРИЗИС ИНТЕГРАТОР является
              продажа программного обеспечения и услуг технической поддержки
              через авторизованных партнеров КРИЗИС ИНТЕГРАТОР
            </span>
          </div>
          <div className="slider-team solution-slider">
            {/* <EventListener target={document} onClick={(event) => this.sliders(event)}/>*/}

            <Carousel interval={3000} showThumbs={false}>
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon3@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Развитие экосистемы продуктов по отраслевому и
                      территориальному признакам
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon2.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Приобретение мультиотраслевого опыта внедрения продуктов с
                      возможностью развития дополнительной функциональности для
                      решения широкого круга бизнес-задач
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/compucter2.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Формирование «лучших практик» в форме преднастроенных
                      решений продуктов для различных видов организаций
                      заказчиков, структур, процессов и групп пользователей
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-29.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Формирование стабильного квалифицированного кадрового
                      состава на рынке консалтинговых услуг по реализации
                      проектов на базе КРИЗИС ИНТЕГРАТОР
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon5.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Использование маркетингового потенциала партнеров для
                      продвижения продукта и обеспечения обратной связи от
                      конечных пользователей
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon6.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Использование партнерских разработок в среде КРИЗИС
                      ИНТЕГРАТОР для формирования дополнительной
                      функциональности и ценности продуктов
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default SliderPartners
