import React, { Component } from 'react'
import Header from 'components/Header/Header'
import TitlePerformance from 'components/TitleBlock/title_performance'
import PerformanceBlock from 'components/Solutions/Performance'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Performance extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="КРИЗИС ИНТЕГРАТОР  - Представление – мультиплатформенная Программное обеспечение генерации и визуального представления аналитической отчетности на базе данных, полученных из разнородных источников, включая хранилища данных, приложения, файлы"
          keywords="Visualization, визуализация аналитических данных, разработка, Москва, Россия, КРИЗИС ИНТЕГРАТОР"
          description="Программное обеспечение КРИЗИС ИНТЕГРАТОР  - Представление позволяет вводить, сортировать, фильтровать, агрегировать и дезагрегировать данные в любых разрезах."
        />
        <Header />
        <TitlePerformance />
        <PerformanceBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Performance
