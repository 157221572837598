import React, { Component } from 'react'
import Header from 'components/Header/Header'
import TitleAnalytics from 'components/TitleBlock/title_analytics'
import AnalyticsBlock from 'components/Solutions/Analytics'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Analytics extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="КРИЗИС ИНТЕГРАТОР Аналитика – Программное обеспечение для анализа данных, выявления взаимосвязей и закономерностей в массиве данных для формирования прогнозных моделей"
          keywords="Аналитика, разработка, анализ данных, прогнозные модели, программное обеспечение, Москва, Россия, КРИЗИС ИНТЕГРАТОР"
          description="КРИЗИС ИНТЕГРАТОР Аналитика – Программное обеспечение обработки и анализа бизнес-данных, построения прогнозных моделей для формирования финансовых стратегий в режиме реального времени."
        />
        <Header />
        <TitleAnalytics />
        <AnalyticsBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Analytics
