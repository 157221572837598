import React from 'react'
import { Link, IndexLink } from 'react-router'

class Info extends React.Component {
  render() {
    return (
      <div>
        <div className="gray-line" />
        <div className="tabs">
          <div className="tabs-block  first-block">
            <div className="first-tab-title">Услуги</div>
            <div className="first-tab-desc">
              От формирования концепции до разработки и сопровождения
            </div>
            {/* <div className="first-tab-link">Подробнее <span className="infotabs-block-text-link-icon"></span></div>*/}
          </div>
          <div className="tabs-block">
            <div className="tabs-block-elem">
              <div className="tabs-block-elem-icon">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/react_color.svg"
                  alt=""
                />
              </div>
              <div className="tabs-block-elem-info">
                <div className="elem-info-title">Разработка</div>
                <div className="elem-info-desc">
                  Полный цикл создания продуктов с использованием лучших
                  подходов и технологий
                </div>
              </div>
            </div>
            <div className="tabs-block-elem">
              <div className="tabs-block-elem-icon">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/compucter.svg"
                  alt=""
                />
              </div>
              <div className="tabs-block-elem-info">
                <div className="elem-info-title">Внедрение и сопровождение</div>
                <div className="elem-info-desc">
                  Синергия знаний, опыта и технологий для успешной реализации
                  проекта
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-block">
            <div className="tabs-block-elem">
              <div className="tabs-block-elem-icon">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/docs.svg"
                  alt=""
                />
              </div>
              <div className="tabs-block-elem-info">
                <div className="elem-info-title">Консалтинг</div>
                <div className="elem-info-desc">
                  Разработка стратегии трансформации бизнеса с целью внедрения
                  новых платформ и технологий
                </div>
              </div>
            </div>
            <div className="tabs-block-elem">
              <div className="tabs-block-elem-icon">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/processor.svg"
                  alt=""
                />
              </div>
              <div className="tabs-block-elem-info">
                <div className="elem-info-title">Data - процессинг</div>
                <div className="elem-info-desc">
                  Предоставление вычислительной инфраструктуры для извлечения
                  данных, анализа, построения предиктивных моделей с целью
                  решения отраслевых задач и Интернета вещей
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Info
