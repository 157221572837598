import React, { Component } from 'react'
import Header from 'components/Header/Header'
import TitleAbout from 'components/TitleBlock/title_about'
import About from 'components/AboutCompany/About'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import Contacts from 'components/Contacts/Contacts'
import MetaTags from 'components/MetaTags/MetaTags'

class Partners extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="О компании КРИЗИС ИНТЕГРАТОР"
          keywords="разработка, программное обеспечение, ПО, Москва, Россия, инновации, технологии, сколково, Стратегические партнеры, Сколково, Отраслевой Союз НЕЙРОНЕТ, АО ГЛОНАСС, Отзывы заказчиков, Пенсионный фонд РФ, Оргкомитет Форума Армия 2016, Федеральное Государственное Автономное Учреждение Конгрессно-выставочный центр Патриот, КРИЗИС ИНТЕГРАТОР"
          description="Компания КРИЗИС ИНТЕГРАТОР является резидентом Сколково с 2019 года. Стратегические партнеры: Сколково, Отраслевой Союз НЕЙРОНЕТ, АО ГЛОНАСС. Отзывы заказчиков."
        />
        <Header />
        <TitleAbout />
        <About />
        <Contacts />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Partners
