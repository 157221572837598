import React from 'react'

class Contacts extends React.Component {
  render() {
    return (
      <div>
        <div className="contact">
          <div
            itemScope
            itemType="http://schema.org/Organization"
            className="contact-info"
          >
            <div className="display-none">
              <div itemProp="name">КРИЗИС ИНТЕГРАТОР</div>
            </div>
            {/* <div className="contact-call contact-mrg">*/}
            {/*    <div className="contact-info-icon">*/}
            {/*        <img src="https://609111.selcdn.ru/ki/static/images/contacts_call.svg" alt="icon"/>*/}
            {/*    </div>*/}
            {/*    <div className="contact-info-text">*/}
            {/*        <div className="contact-info-text-title">Телефон:</div>*/}
            {/*        <div itemProp="telephone" className="contact-info-text-desc"><a href='tel:+74952801597'>+7 (495) 280 15 97</a></div>*/}
            {/*    </div>*/}
            {/* </div>*/}
            <div className="contact-mail contact-mrg">
              <div className="contact-info-icon">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/contacts_mail.svg"
                  alt="icon"
                />
              </div>
              <div className="contact-info-text">
                <div className="contact-info-text-title">E-mail:</div>
                <div itemProp="email" className="contact-info-text-desc">
                  <a href="mailto:office@КРИЗИС ИНТЕГРАТОР.ru">
                    office@crisis-integrator.ru
                  </a>
                </div>
              </div>
            </div>
            <div className="contact-address contact-mrg">
              <div className="contact-info-icon">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/contacts_addres.svg"
                  alt="icon"
                />
              </div>
              <div className="contact-info-text">
                <div className="contact-info-text-title">Адрес:</div>
                <div className="contact-info-text-desc">
                  <span itemScope itemType="http://schema.org/PostalAddress">
                    {' '}
                    <span itemProp="postalCode">121205,</span>
                  </span>{' '}
                  <span itemProp="address">
                    {' '}
                    г. Москва, территория инновационного центра Сколково, ул.
                    Нобеля, д. 7
                  </span>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d5349.709884211335!2d37.34258576199794!3d55.6841903171573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sru!4v1516983246166"
              width="100%"
              height="650"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Contacts
