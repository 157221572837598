import React from 'react'
import { Link, IndexLink } from 'react-router'

class InfoTabs extends React.Component {
  render() {
    return (
      <div className="infotabs-link">
        <div className="infotabs-link-block-first first-block">
          <div className="first-tab-title">Отрасли</div>
          <div className="first-tab-desc">
            Цифровая экономика для государственных структур, компаний, людей
          </div>
          {/* <Link to="/industry">*/}
          {/* <div className="first-tab-link">Подробнее <span className="infotabs-block-text-link-icon"></span></div>*/}
          {/* </Link>*/}
        </div>
        <div className="infotabs-link-block">
          <div className="infotabs-link-block-elem security-link">
            <div className="infotabs-link-block-elem-title">
              <Link to="/industry">
                {/* <Link to='/industry/security'>*/}
                <img
                  src="https://609111.selcdn.ru/ki/static/images/secure.svg"
                  alt=""
                />
                <span>Безопасность</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="infotabs-link-block">
          <div className="infotabs-link-block-elem ts-link">
            <div className="infotabs-link-block-elem-title">
              <Link to="/industry">
                {/* <Link to='/industry/transport'>*/}
                <img
                  src="https://609111.selcdn.ru/ki/static/images/buss.png"
                  alt=""
                />
                <span>Транспорт</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="infotabs-link-block">
          <div className="infotabs-link-block-elem zhkh-link">
            <div className="infotabs-link-block-elem-title">
              <Link to="/industry">
                {/* <Link to='/industry/jkh'>*/}
                <img
                  src="https://609111.selcdn.ru/ki/static/images/jkh.svg"
                  alt=""
                />
                <span>ЖКХ</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="infotabs-link-block">
          <div className="infotabs-link-block-elem e-comm-link">
            <div className="infotabs-link-block-elem-title">
              <Link to="/automation">
                {/* <Link to='/industry/ecommerce'>*/}
                <img
                  src="https://609111.selcdn.ru/ki/static/images/ecomm.svg"
                  alt=""
                />
                <span>КИ Импортозамещение</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="infotabs-link-block">
          <div className="infotabs-link-block-elem gos-link">
            <div className="infotabs-link-block-elem-title">
              <Link to="/industry">
                {/* <Link to='/industry/gos'>*/}
                <img
                  src="https://609111.selcdn.ru/ki/static/images/gos.svg"
                  alt=""
                />
                <span>Государство</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default InfoTabs
