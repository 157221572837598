import React from 'react'

import Modal from './modal'

class CallBack extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true })
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  render() {
    return (
      <div>
        <div className="callback-block-two">
          <div className="header-call">
            <div className="callback-header">Присоединяйтесь к нам!</div>
          </div>
          <div className="callback-button" onClick={this.handleOpen}>
            Отправить заявку
          </div>
          <div className="footer-call">
            <div className="callback-desc">
              Возможность продавать уникальные продукты КРИЗИС ИНТЕГРАТОР
            </div>
            <div className="callback-desc">
              Доступ к нашей технической и маркетинговой поддержке
            </div>
            <div className="callback-desc">
              Доступ к новым версиям продуктов КРИЗИС ИНТЕГРАТОР
            </div>
          </div>
        </div>
        <Modal showModal={this.state.modalOpen} close={this.handleClose} />
      </div>
    )
  }
}

export default CallBack
