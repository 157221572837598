import React from 'react'
import { Link, IndexLink } from 'react-router'
import ReactDOM from 'react-dom'
import EventListener, { withOptions } from 'react-event-listener'
// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class SliderEcosystem extends React.Component {
  render() {
    return (
      <div>
        <div className="solutions-slider-top-block not-button">
          <div className="solution-text">
            <div className="first-tab-title">
              КРИЗИС ИНТЕГРАТОР (Экосистема)
            </div>
            <span>Возможности</span>
            <span className="about-text-decs">
              КК рассчитан на применение в качестве интеграционной
              процессинговой и аналитической платформы в проектах разных
              масштабов в бизнесе и социальной сфере. Отдельные компоненты
              решения могут поставляться как самостоятельные продукты для
              интеграции с уже имеющимися у заказчика средствами и системами
              аналитики
            </span>
          </div>
          <div className="slider-team solution-slider">
            {/* <EventListener target={document} onClick={(event) => this.sliders(event)}/>*/}

            <Carousel showThumbs={false} showArrows={false}>
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-4.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Системы управления городами с приставкой «умный»,
                      «безопасный» с использованием устройств «Интернета Вещей»
                      и геопозиционирования для решения задач ЖКХ
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_77.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Создание систем транспортной безопасности, включая
                      предупреждение инцидентов, связанных с человеческим
                      фактором, и предотвращение террористических атак
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-8.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Умный E-commerce. Создание систем онлайн рекомендаций и
                      персонализаци
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_13.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Системы управления производственно-хозяйственной
                      деятельностью государственных организаций, корпораций,
                      субъектов среднего и малого бизнеса с возможностью
                      формирования стратегии в режиме реального времени
                    </div>
                  </div>
                </div>
              </div>
              {/* <div>*/}
              {/* <div className="">*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/group-4.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">*/}
              {/* Системы управления городами с приставкой «умный», «безопасный» с*/}
              {/* использованием устройств «Интернета Вещей» и геопозиционирования для решения задач ЖКХ*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_77.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">*/}
              {/* Создание систем транспортной безопасности, включая предупреждение инцидентов,*/}
              {/* связанных с человеческим фактором, и предотвращение террористических атак*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/group-8.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">*/}
              {/* Умный E-commerce. Создание систем онлайн рекомендаций и персонализаци*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_13.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">*/}
              {/* Системы управления производственно-хозяйственной деятельностью государственных организаций, корпораций,*/}
              {/* субъектов среднего и малого бизнеса с возможностью формирования стратегии в режиме реального времени*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* </div>*/}
            </Carousel>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default SliderEcosystem
