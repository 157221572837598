import React from 'react'
import { Link } from 'react-router'

class TitleTransportSecurity extends React.Component {
  render() {
    return (
      <div>
        <div className="title-block title-transport-security-block">
          <div className="title-block-text">
            <h2>КРИЗИС ИНТЕГРАТОР</h2>
            <h1 id="transport-security">Транспортная безопасность</h1>
            <span>
              Интеллектуальная система транспортной безопасности на базе
              нейротехнологий, машинного обучения и геопозиционирования для
              осуществления мониторинга, контроля и предупреждения инцидентов на
              транспорте
            </span>
          </div>
        </div>
        <div className="yellow-line" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TitleTransportSecurity
