import React from 'react'
import { Link } from 'react-router'

class TitleContacts extends React.Component {
  render() {
    return (
      <div>
        <div className="title-block title-contacts-block">
          <div className="title-block-text">
            <h1>Контакты</h1>
            <span>Мы находимся в эпицентре инноваций. Технопарк Сколково</span>
          </div>
        </div>
        <div className="yellow-line" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TitleContacts
