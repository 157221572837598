import isEmpty from 'lodash/isEmpty'

export default function sendEmailValidator(data) {
  const errors = {}

  function validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  if (!data.description || data.description === '') {
    errors.fio = 'Описание не введено'
  }

  if (!data.fio || data.fio === '') {
    errors.fio = 'ФИО не введено'
  }

  if (validateEmail(data.email) === false) {
    errors.email = 'Email не введен или введен некорректно'
  }

  if (!data.phone || data.tele === '') {
    errors.phone = 'Номер не введен'
  }

  if (data.checkbox === false) {
    errors.checkbox = 'Вам необходимо согласиться с условиями'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export const sendLogin = (data) => {
  const errors = { password: 'Неверный пароль' }

  function validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  if (!data.password || data.password === '') {
    errors.password = 'Пароль не указан'
  }
  if (validateEmail(data.email) === false) {
    errors.email = 'Email не введен или введен некорректно'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}
