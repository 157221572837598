import React from 'react'
import { Link } from 'react-router'
import SliderTop from '../Slider/SliderAnalytics/SliderAnalytics'
import Slide3d from '../Slider/Slider3D/Slider3D'

class Analytics extends React.Component {
  render() {
    const images = [
      'https://609111.selcdn.ru/ki/static/images/slider-ux/analytics/8.jpg',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/analytics/9.jpg',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/analytics/10.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/analytics/11.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/analytics/15.png',
    ]
    return (
      <div className="analytics">
        <SliderTop />
        <div className="work-complex-block analytics-complex">
          <div className="complex-model-title">
            <h2>Функциональная архитектура платформы КРИЗИС ИНТЕГРАТОР</h2>
            <div className="complex-model-title-logo">
              КРИЗИС ИНТЕГРАТОР (Аналитика)
            </div>
          </div>
          <div className="work-complex-block-top">
            <div className="work-complex-logo">
              КРИЗИС ИНТЕГРАТОР (Аналитика)
            </div>
            <div className="work-complex-info">
              <div className="work-complex-info-block w-one">
                <div className="work-complex-info-elem-item w-four">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/icon1@2x.png"
                    alt=""
                  />
                  <span>Обрабатывает более 200 000 временных рядов</span>
                </div>
                <div className="work-complex-info-elem-item w-four">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/icon3@2x.png"
                    alt=""
                  />
                  <span>
                    Строит прогнозы в рамках сложных структур процессов
                    бюджетирвоания
                  </span>
                </div>
                <div className="work-complex-info-elem-item w-four">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/icon5@2x.png"
                    alt=""
                  />
                  <span>
                    Определяет годовой тренд, сезонность, а также связь с
                    предыдущими месяцами
                  </span>
                </div>
                <div className="work-complex-info-elem-item w-four">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/icon7@2x.png"
                    alt=""
                  />
                  <span>
                    Выбирает из четырех рассчитываемых системой моделей,
                    основанных на методе минимизации эмпирического риска
                    Вапника-Червоненкиса наиболее оптимальную
                  </span>
                </div>
              </div>
              <div className="work-complex-info-block w-one">
                <div className="work-complex-info-elem-item w-four">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/134.svg"
                    alt=""
                  />
                  <span>Работает с неполными данными</span>
                </div>
                <div className="work-complex-info-elem-item w-four">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/group4.svg"
                    alt=""
                  />
                  <span>Обучается на небольшом количестве данных</span>
                </div>
                <div className="work-complex-info-elem-item w-four">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/icon6@2x.png"
                    alt=""
                  />
                  <span>Интерпретирует модели </span>
                </div>
              </div>
            </div>
          </div>
          <div className="work-complex-pointer-top">
            <div className="work-complex-pointer-top-left">
              <img
                src="https://609111.selcdn.ru/ki/static/images/Group_30(копия).svg"
                alt=""
              />
              <span>Прогнозные данные</span>
              <img
                className="revert180"
                src="https://609111.selcdn.ru/ki/static/images/Group_30(копия).svg"
                alt=""
              />
              <span>Данные для построения прогноза</span>
            </div>
            <div className="work-complex-pointer-top-right">
              <img
                src="https://609111.selcdn.ru/ki/static/images/Group_30(копия).svg"
                alt=""
              />
              <span>Прогнозные данные</span>
            </div>
          </div>
          <div className="work-complex-block-bottom">
            <div className="complex-block-bottom-left">
              <div className="work-complex-logo">
                КРИЗИС ИНТЕГРАТОР (Управление данными)
                <Link to="/data-control">
                  <div className="first-tab-link">
                    Подробнее <span className="infotabs-block-text-link-icon" />
                  </div>
                </Link>
              </div>
              <div className="work-complex-info">
                <div className="work-complex-info-block w-one">
                  <div className="work-complex-info-elem-item w-two">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/visual7.png"
                      alt=""
                    />
                    <span>Анализ и контроль расходов</span>
                  </div>
                  <div className="work-complex-info-elem-item w-two">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/visual8.png"
                      alt=""
                    />
                    <span>Планирование и контроль доходов</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="complex-block-bottom-right">
              <div className="work-complex-logo">
                КРИЗИС ИНТЕГРАТОР (Представление данных)
                <Link to="performance">
                  <div className="first-tab-link">
                    Подробнее <span className="infotabs-block-text-link-icon" />
                  </div>
                </Link>
              </div>
              <div className="work-complex-info">
                <div className="work-complex-info-block w-one">
                  <div className="work-complex-info-elem-item w-two">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/visual10.png"
                      alt=""
                    />
                    <span>
                      Представление аналитических данных в виде:
                      <span className="bottom-ul">
                        <ul>
                          <li>- таблиц</li>
                          <li>- диаграмм</li>
                          <li>- графиков</li>
                        </ul>
                      </span>
                    </span>
                  </div>
                  <div className="work-complex-info-elem-item w-two">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/visual5.png"
                      alt=""
                    />
                    <span>Настройка отчетов и их выгрузка в PDF</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Slide3d
          clas="analytics ux-slide"
          image={images}
          titleGray="КРИЗИС ИНТЕГРАТОР Аналитика"
          title="Интерфейс"
          description="Система позволяет вводить, сортировать, фильтровать, агрегировать и дезагрегировать прогнозные данные"
        />

        <div className="analytics-complex analytics-complex-model">
          <div className="complex-model-title">
            <h2>Моделирование</h2>
            <div className="complex-model-title-logo">КРИЗИС ИНТЕГРАТОР</div>
          </div>
          <div className="complex-model-top">
            <div className="complex-model-top-title">
              <h3>Построение модели</h3>
              <h3>Определение пригодности временного ряда к моделированию </h3>
            </div>
            <div className="complex-model-top-block">
              <div className="complex-model-top-block-left">
                <div className="complex-model-top-block-left-elem">
                  <div className="complex-model-top-block-left-elem-info">
                    <div className="complex-model-bold">01.</div>
                    <div>Построение модели</div>
                  </div>
                  <div className="complex-model-top-block-left-elem-arrow">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_29.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="complex-model-top-block-left-elem">
                  <div className="complex-model-top-block-left-elem-info">
                    <div className="complex-model-bold">02.</div>
                    <div>Определение критерия качества модели (R-квадрат)</div>
                  </div>
                  <div className="complex-model-top-block-left-elem-arrow">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_29.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="complex-model-top-block-right">
                <div className="complex-model-top-block-right-elem">
                  <div className="complex-model-top-block-right-elem-info">
                    <div className="complex-model-bold">03.</div>
                    <div>Критерий качества > требуемого уровня</div>
                  </div>
                </div>
                <div className="complex-model-top-block-right-elem relative-block-arrow">
                  <div className="complex-model-top-block-right-elem-arrow">
                    <div className="one-box-arrow" />
                    <div className="two-box-arrow">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/Group_29.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="complex-model-top-block-right-elem-arrow-revert">
                    <div className="one-box-arrow" />
                    <div className="two-box-arrow">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/Group_29.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="complex-model-top-block-right-elem relative-block">
                  <div className="complex-model-top-block-right-elem-info">
                    <div className="complex-model-bold">Да</div>
                    <div>Временной ряд пригоден к моделированию</div>
                  </div>
                  <div className="complex-model-top-block-right-elem-info">
                    <div className="complex-model-bold">Нет</div>
                    <div>Временной ряд не пригоден к моделированию</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="complex-model-bottom">
            <div className="complex-model-bottom-title">
              <h2>Описание процесса моделирования</h2>
            </div>
            <div className="complex-model-bottom-block">
              <div className="complex-model-bottom-block-text">
                <span>Процесс моделирования происходит последовательно</span>
              </div>
              <div className="complex-model-bottom-block-module">
                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">01.</div>
                  <div>Выгрузка данных из базы данных</div>
                </div>
                <div className="triangle-right" />

                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">02.</div>
                  <div>Обработка данных</div>
                </div>
                <div className="triangle-right" />

                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">03.</div>
                  <div>Моделирование</div>
                </div>
                <div className="triangle-right" />

                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">04.</div>
                  <div>Прогнозирование</div>
                </div>
                <div className="triangle-right" />

                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">05.</div>
                  <div>Загрузка таблицы с прогнозом в базу данных</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Analytics
