import React from 'react'
import EventListener from 'react-event-listener'

// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class SliderComments extends React.Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {
  //         isShowingModalComm: false,
  //         commImg: ''
  //     };
  //
  //
  // }
  //
  // handleClick = () => this.setState({isShowingModalComm: true});
  // handleClose = () => this.setState({isShowingModalComm: false});
  //
  //
  // checkClick(event) {
  //
  //     this.setState({isShowingModalComm: false});
  //     // let image = event.target.innerHTML;
  //     debugger
  //     let image = event.target.firstElementChild.src;
  //
  //     if (event || images !== undefined) {
  //         if (event.target.className==="docs-img") {
  //             this.setState({commImg: image});
  //             this.handleClick(event.target.innerHTML);
  //
  //
  //         }
  //         // if (event.target.className('docs-img')) {
  //         //     this.setState({sertImg: image});
  //         //     this.handleClick(event.target.innerHTML);
  //         //
  //         // }
  //
  //     }
  //
  //
  // }

  render() {
    return (
      <div className="">
        <div className="comment-slider">
          <div className="solution-text">
            <span>Отзывы наших заказчиков и партнеров</span>
          </div>
          <div className="slider-team solution-slider">
            {/* <EventListener target={document} onClick={(event) => this.sliders(event)}/>*/}
            <EventListener
              target="window"
              onClick={(event) => this.checkClick(event)}
            />
            <Carousel interval={3000} showThumbs={false}>
              <div>
                <div className="docs-img">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/comment/pfr.png"
                    alt=""
                  />
                </div>
                <div className="docs-text">
                  <div className="docs-text-title">
                    <div className="docs-text-title-company">
                      Пенсионный фонд РФ
                    </div>
                    <div className="docs-text-title-person">О. В. Никитина</div>
                    <div className="docs-text-title-person-post">
                      Начальник Департамента финансового обеспечения системы ПФР
                    </div>
                  </div>
                  <div className="docs-text-desc">
                    <span>
                      Команда КРИЗИС ИНТЕГРАТОР получила высокую оценку своей
                      работы по внедрению подсистемы «Прогнозирование» в составе
                      Автоматизированной Системы Бюджетирование в Пенсионном
                      Фонде РФ на базе решений КРИЗИС ИНТЕГРАТОР.
                    </span>
                    <span>
                      Использование технологий предиктивного анализа и машинного
                      обучения, применяемых в системе, позволило повысить
                      эффективность планирования расходов ПФР
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="docs-img">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/comment/Army.png"
                    alt=""
                  />
                </div>
                <div className="docs-text">
                  <div className="docs-text-title">
                    <div className="docs-text-title-company">
                      Оргкомитет Форума «Армия 2016»
                    </div>
                    <div className="docs-text-title-person" />
                    <div className="docs-text-title-person-post" />
                  </div>
                  <div className="docs-text-desc">
                    <span>
                      По приглашению организаторов Форума «Армия 2016» и
                      министерства обороны РФ КРИЗИС ИНТЕГРАТОР принял участие в
                      обеспечении безопасности проведения мероприятия с
                      использованием системы КИ Безопасность
                    </span>
                    <span>
                      Работа системы на центральном контрольно-пропускном пункте
                      показала ее высокую эффективность в выявлении
                      подозрительных лиц и случаев девиантного поведения
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="docs-img">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/comment/Army-Video.jpg"
                    alt=""
                  />
                </div>
                <div className="docs-text">
                  <div className="docs-text-title">
                    <div className="docs-text-title-company">
                      Федеральное Государственное Автономное Учреждение
                      «Конгрессно-выставочный центр «Патриот»
                    </div>
                    <div className="docs-text-title-person">С. Жильцов</div>
                    <div className="docs-text-title-person-post">Директор</div>
                  </div>
                  <div className="docs-text-desc">
                    <span>
                      В ходе мероприятий Форума «Армия 2016» успешно проведена
                      опытная эксплуатация комплексной системы безопасности КИ
                      Безопасность.
                    </span>
                    <span>
                      По результатам испытаний со стороны представителей
                      выставочного центра «Патриот» и министерства обороны РФ
                      была отмечена инновационность реализованного в системе
                      подхода и перспективность ее дальнейшего внедрения с целью
                      обеспечения безопасности объектов Министерства Обороны РФ
                    </span>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

        {/* <div>*/}

        {/* {*/}
        {/* this.state.isShowingModalComm &&*/}
        {/* <ModalContainer onClose={this.handleClose}>*/}
        {/* <ModalDialog className="img-popup-block" onClose={this.handleClose}>*/}
        {/* <img className="img-popup" src={this.state.commImg} alt=""/>*/}
        {/* </ModalDialog>*/}
        {/* </ModalContainer>*/}
        {/* }*/}
        {/* </div>*/}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default SliderComments
