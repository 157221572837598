import React, { Component } from 'react'
import EcosystemBlock from 'components/Solutions/Ecosystem'
import TitleEcosystem from 'components/TitleBlock/title_ecosystem'
import Header from 'components/Header/Header'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Ecosystem extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="КРИЗИС ИНТЕГРАТОР (Экосистема) – когнитивная платформа для работы с большими данными для Интернета Вещей, электронной коммерции и систем безопасности"
          keywords="Экосистема, интернет вещей, интеграционная платформа, аналитическая платформа, системы безопасности, электронная коммерция, разработка, программное обеспечение, Москва, Россия, КРИЗИС ИНТЕГРАТОР"
          description="Платформа Экосистема предназначена для применения в качестве интеграционной процессинговой и аналитической платформы в проектах разных масштабов в бизнесе и социальной сфере. "
        />
        <Header />
        <TitleEcosystem />
        <EcosystemBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Ecosystem
