import React from 'react'
import { Link, IndexLink } from 'react-router'

class NotFound extends React.Component {
  render() {
    return (
      <div className="notefound-page">
        <div className="notefound-page-img">
          <img src="https://609111.selcdn.ru/ki/static/images/4.svg" alt="" />
          <img
            src="https://609111.selcdn.ru/ki/static/images/black-hole.svg"
            alt=""
          />
          <img src="https://609111.selcdn.ru/ki/static/images/4.svg" alt="" />
        </div>
        <div className="notefound-page-text">Страница не найдена</div>
        <Link to="/">
          <div className="notefound-page-button partner-button">На главную</div>
        </Link>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default NotFound
