import React from 'react'
import { Link } from 'react-router'

class TitleIndustry extends React.Component {
  render() {
    return (
      <div>
        <div className="title-block title-industry-block">
          <div className="title-block-text">
            <h2> </h2>
            <h1>Отрасли</h1>
            <span>
              Решения КРИЗИС ИНТЕГРАТОР нацелены на изменение жизни людей к
              лучшему. Мы прикладываем свои усилия в тех сегментах, от развития
              которых зависит качество жизни и безопасность общества
            </span>
          </div>
        </div>
        <div className="yellow-line" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TitleIndustry
