import React from 'react'
import { Link, IndexLink } from 'react-router'
import classnames from 'classnames'
import Slider from '../Slider/SliderSecurity/SliderSecurity'

class Security extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slide: 'one',
    }
    this.checkVerif = this.checkVerif.bind(this)
  }

  checkVerif(value) {
    this.setState({
      slide: value,
    })
  }

  render() {
    return (
      <div className="security-block">
        <Slider />
        <div className="security-solution-desc">
          <div className="ts-security-solution-desc-title">
            <div className="ts-security-solution-desc-title-btn">
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  {
                    'ts-security-btn-active': this.state.slide === 'one',
                  },
                )}
                onClick={() => this.checkVerif('one')}
              >
                Концепция системы
              </div>
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  {
                    'ts-security-btn-active': this.state.slide === 'two',
                  },
                )}
                onClick={() => this.checkVerif('two')}
              >
                Состав системы
              </div>
            </div>
            <div
              className={classnames('', {
                'ts-security-solution-desc-title-btn-logo':
                  this.state.slide === 'one',
                'display-none': this.state.slide !== 'one',
              })}
            >
              Концепция системы
            </div>
            <div
              className={classnames('', {
                'ts-security-solution-desc-title-btn-logo':
                  this.state.slide === 'two',
                'display-none': this.state.slide !== 'two',
              })}
            >
              Состав системы
            </div>
          </div>
          <div
            className={classnames('', {
              block: this.state.slide === 'one',
              'display-none': this.state.slide !== 'one',
            })}
          >
            <div className="ecosystem-solution-desc-slide back-none">
              <div className="hard-block-three">
                <div className="hard-block-three-top">
                  <div className="hard-block-three-top-left">
                    <div className="hard-block-three-top-left-title">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/group1.png"
                        alt="icon"
                      />
                      <span>Объект (Модель безопасности Объекта) </span>
                    </div>
                    <div className="hard-block-three-top-left-block">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/hyita.png"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="hard-block-three-top-center">
                    <span>Видео поток</span>
                    <img
                      className="revert180"
                      src="https://609111.selcdn.ru/ki/static/images/Group_29.svg"
                      alt="icon"
                    />
                    <span>Данные СКУД</span>
                    <img
                      className="revert180"
                      src="https://609111.selcdn.ru/ki/static/images/Group_29.svg"
                      alt="icon"
                    />
                    <span>Данные Биометрии</span>
                    <img
                      className="revert180"
                      src="https://609111.selcdn.ru/ki/static/images/Group_29.svg"
                      alt="icon"
                    />
                  </div>
                  <div className="hard-block-three-top-right">
                    <div className="hard-block-three-top-right-title">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/group2.svg"
                        alt="icon"
                      />
                      <span>
                        Экосистема Видео - Кластер приложений обработчиков
                      </span>
                    </div>
                    <div className="hard-block-three-top-right-block">
                      <div className="hard-block-three-top-right-block-elem">
                        <span>
                          1 9 - Система контроля и управления доступом (СКУД).
                          Подсчет входящего и исходящего потоков людей.
                        </span>
                        <span>
                          2 - Система видеонаблюдения, обеспечивающая 80-100%
                          охват объекта
                        </span>
                        <span>
                          3 - Треккинг отдельных индивидуумов внутри объекта
                        </span>
                        <span>
                          4 - Определение количества людей в отдельном сегменте
                          объекта
                        </span>
                      </div>
                      <div className="hard-block-three-top-right-block-elem">
                        <span>5 - Выявление девиантного поведения</span>
                        <span>
                          6 - Выделение определенного сектора объекта со своими
                          показателями безопасности
                        </span>
                        <span>7 - Идентификация «бесхозных» предметов</span>
                        <span>
                          8 - Дистанционное биометрическое сканирование
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hard-block-three-bottom">
                  <div className="hard-block-three-bottom-left">
                    <div className="hard-block-three-bottom-left-title">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/group3.png"
                        alt="icon"
                      />
                      <span>
                        Система принятия решений. Рабочие места руководителей и
                        сотрудников службы безопасности
                      </span>
                      <div className="hard-block-three-bottom-left-title-arrow">
                        <img
                          className="revert180"
                          src="https://609111.selcdn.ru/ki/static/images/arrow-white.svg"
                          alt="icon"
                        />
                        <span>Управление</span>
                      </div>
                    </div>
                    <div className="hard-block-three-bottom-left-block">
                      <div className="hard-block-three-bottom-left-block-left-elem">
                        <ul className="span-yellow-dot">
                          <li>
                            Интерпретация данных (графики/диаграммы/схемы
                            объектов/видео)
                          </li>
                          <li>Прогнозирование событий</li>
                          <li>Карта рисков</li>
                          <li>Система сигналов и оповещений</li>
                        </ul>
                      </div>
                      <div className="hard-block-three-bottom-left-block-left-elem">
                        <ul className="check-dot">
                          <li>Мобильность</li>
                          <li>Интуитивно понятный интерфейс</li>
                          <li>Полнота информации</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="hard-block-three-bottom-center">
                    <span>Данные</span>
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_29.svg"
                      alt="icon"
                    />
                    <span>Запросы</span>
                    <img
                      className="revert180"
                      src="https://609111.selcdn.ru/ki/static/images/Group_29.svg"
                      alt="icon"
                    />
                  </div>
                  <div className="hard-block-three-bottom-right">
                    <div className="hard-block-three-bottom-right-title">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/group4.svg"
                        alt="icon"
                      />
                      <span>Экосистема – Система управления данными</span>
                    </div>
                    <div className="hard-block-three-bottom-right-block">
                      <div className="hard-block-three-bottom-right-block-elem">
                        <ul className="span-yellow-dot">
                          <li>СУБД</li>
                          <li>Хранилище</li>
                          <li>Алгоритмы</li>
                          <li>Модели</li>
                        </ul>
                      </div>
                      <div className="hard-block-three-bottom-right-block-elem">
                        <ul className="check-dot">
                          <li>Надежность</li>
                          <li>Высокая производительность</li>
                          <li>Универсальность</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classnames('', {
              block: this.state.slide === 'two',
              'display-none': this.state.slide !== 'two',
            })}
          >
            <div className="ecosystem-solution-desc-slide back-none">
              <div className="ecosystem-solution-desc-slide-top-arrow">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-29-copy.svg"
                  alt="icon"
                />
              </div>
              <div>
                <div className="ecosystem-solution-desc-slide-elem">
                  <h3>Экосистема</h3>
                  <div className="ecosystem-solution-desc-slide-elem-body-text">
                    <span>Реализует хранение и обработку данных</span>
                    <span>
                      Позволяет осуществлять предиктивный анализ поведения с
                      использованием нейронных сетей
                    </span>
                    <span>
                      Повышает эффективность алгоритмов, используя когнитивные
                      технологии
                    </span>
                    <span>
                      Представляет собой отказоустойчивый кластер на базе Apache
                      Hadoop
                    </span>
                  </div>
                  <div className="ecosystem-solution-desc-slide-elem-bottom-block">
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem">
                      Data Processing
                    </div>
                  </div>
                </div>
                <div className="ecosystem-solution-desc-slide-elem">
                  <h3>Экосистема Видео</h3>
                  <div className="ecosystem-solution-desc-slide-elem-body-text">
                    <span>
                      Агрегирует и подготавливает данные для дальнейшего
                      моделирования
                    </span>
                    <span>
                      Поддерживает работу с десятками видеокамер и
                      неограниченным количеством других устройств
                    </span>
                    <span>
                      Генерирует события и риски на основе данных из КИ
                      Экосистема
                    </span>
                    <span>
                      Интегрируется с различными внешними источниками данных
                    </span>
                    <span>
                      Генерирует данные для КРИЗИС ИНТЕГРАТОР - Представление
                    </span>
                  </div>
                  <div className="ecosystem-solution-desc-slide-elem-bottom-block">
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem">
                      Features Extraction
                    </div>
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem">
                      Data Transform
                    </div>
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem">
                      Event Management
                    </div>
                  </div>
                </div>
                <div className="ecosystem-solution-desc-slide-elem">
                  <h3>КРИЗИС ИНТЕГРАТОР - Представление</h3>
                  <div className="ecosystem-solution-desc-slide-elem-body-text">
                    <span>
                      Использует большинство известных форм представления
                    </span>
                    <span>
                      Оперирует широким спектром функциональных возможностей и
                      средствами интерактивной работы с аналитикой
                    </span>
                    <span>
                      Обладает продвинутым интерфейсом и является идеальным
                      средством для работы аналитика
                    </span>
                  </div>

                  <div className="ecosystem-solution-desc-slide-elem-bottom-block">
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-kostili">
                      <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                        Graphs
                      </div>
                      <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                        Timetables
                      </div>
                      <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                        Heat maps
                      </div>
                    </div>
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-kostili">
                      <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                        Maps
                      </div>
                      <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                        Calculations
                      </div>
                      <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                        Color workshop
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ecosystem-solution-desc-slide-elem back-none">
                  <div className="ecosystem-solution-desc-slide-elem-item span-yellow-dot">
                    <h3>Пользователи</h3>
                    <span>Events</span>
                    <span>Lost items</span>
                    <span>Risks</span>
                    <span>Person Identification</span>
                    <span>Unusual behavior</span>
                  </div>
                  <div className="ecosystem-solution-desc-slide-elem-item">
                    <h3>Видеокамеры/Передающие устройства/Датчики</h3>
                    <div className="ecosystem-solution-icon-block">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/123.svg"
                        alt="icon"
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/invalid-name(3).svg"
                        alt="icon"
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/2343.svg"
                        alt="icon"
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/345.svg"
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ecosystem-solution-desc-slide-bottom-arrow">
                <div className="ecosystem-solution-desc-slide-bottom-arrow-one">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/group-29(1).svg"
                    alt="icon"
                  />
                </div>
                <div className="ecosystem-solution-desc-slide-bottom-arrow-two">
                  <img
                    className="revert"
                    src="https://609111.selcdn.ru/ki/static/images/group-29-copy.svg"
                    alt="icon"
                  />
                </div>
                <div className="ecosystem-solution-desc-slide-bottom-arrow-three">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/group-29-copy-2.svg"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="security-solution back-none">
          <div className="transport-security-title">
            <h2>Дорожная карта внедрения системы</h2>
            <span />
          </div>
          <div className="partner-solution-block">
            <div className="security-tree-block security-tree-block-one">
              <div className="security-tree-block-title">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/house.svg"
                  alt="icon"
                />
                <span>Объект</span>
              </div>
              <div className="security-tree-block-desc">
                <span>
                  Исследование объекта. Определение текущего уровня безопасности
                  и технической оснащенности (видеокамеры, СКУД, биометрия и
                  другое)
                </span>
                <span>
                  Разделение объекта на подобъекты и секторы. Формирование
                  показателей безопасности для каждого подобъекта/сектора
                </span>
                <span>
                  Формирование карты рисков объекта/Модели безопасности объекта
                </span>
                <span>
                  Разработка схемы оснащения видеокамерами для 100% покрытия
                  объекта, исходя из карты рисков
                </span>
                <span>
                  Монтаж видеокамер, средств биометрии, СКУД,
                  телекоммуникационного оборудования
                </span>
              </div>
            </div>
            <div className="triangle-right" />
            <div className="security-tree-block security-tree-block-two">
              <div className="security-tree-block-title">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/vhs.svg"
                  alt="icon"
                />
                <span>Информация / Экосистема</span>
              </div>
              <div className="security-tree-block-desc">
                <span>
                  Настройка приложений КИ Экосистема видео для работы с
                  источниками данных объекта (видеокамерами, биометрическими
                  системами, СКУД)
                </span>
                <span>
                  Реализация обработки данных для наполнения KPI’s объекта в
                  режиме реального времени в соответствии с моделью безопасности
                  объекта
                </span>
                <span>Конфигурация хранилища данных КИ Экосистема</span>
                <span>
                  Реализация алгоритмов машинного обучения для сопровождения
                  модели безопасности объекта в режиме реального времени
                </span>
              </div>
            </div>
            <div className="triangle-right" />
            <div className="security-tree-block security-tree-block-three">
              <div className="security-tree-block-title">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/pie.svg"
                  alt="icon"
                />
                <span>Представление</span>
              </div>
              <div className="security-tree-block-desc">
                <span>
                  Настройка панелей рабочих мест руководителей и сотрудников
                  службы безопасности в соответствии с правами доступа
                  средствами КРИЗИС ИНТЕГРАТОР - (Представление)
                </span>
                <span>
                  Визуальная реализация Карты рисков/Модели безопасности объекта
                  в виде dash-boards
                </span>
                <span>
                  Реализация системы рекомендаций по ключевым кейсам превышения
                  допустимого уровня опасности объекта
                </span>
                <span>Реализация системы оповещения по каждому кейсу</span>
                <span>Адаптация для мобильных устройств</span>
              </div>
            </div>
          </div>
        </div>
        <div className="partner-solution">
          <div className="transport-security-title">
            <div className="first-tab-title">
              КРИЗИС ИНТЕГРАТОР Безопасность
            </div>
            <h2>Преимущества</h2>
            <span className="about-text-decs">
              КИ Безопасность позволяет осуществлять мониторинг безопасности
              объекта в реальном времени, прогнозировать негативные сценарии
              развития ситуации, обеспечивает соответствующие службы информацией
              для оперативного реагирования на угрозы
            </span>
          </div>
          <div className="partner-solution-block">
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/q.png"
                alt="icon"
              />
              <div className="partner-solution-elem-title">Кастомизация</div>
              <div className="partner-solution-elem-decs">
                Гибкость в настройке логики работы системы: обработка данных с
                различных источников, настройка системы под любые требования
                заказчика
              </div>
            </div>
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/pazl.png"
                alt="icon"
              />
              <div className="partner-solution-elem-title">Когнитивность</div>
              <div className="partner-solution-elem-decs">
                Самообучение и автоматическая адаптация алгоритмов для наиболее
                эффективной работы системы в текущих условиях. Развитие системы
                на принципах искусственного интеллекта
              </div>
            </div>
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/blocks.png"
                alt="icon"
              />
              <div className="partner-solution-elem-title">Модульность</div>
              <div className="partner-solution-elem-decs">
                Коллекция модулей, из которых можно составить систему,
                подходящую под различные требования в плане функциональности и
                стоимости
              </div>
            </div>
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/invalid-name.png"
                alt="icon"
              />
              <div className="partner-solution-elem-title">
                Отказоустойчивость
              </div>
              <div className="partner-solution-elem-decs">
                Мультисерверная архитектура, позволяющая обеспечить
                работоспособность системы в режиме 24/7 даже при выходе из строя
                части компонентов
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Security
