import React from 'react'
import { Link } from 'react-router'

class TitleEcommerce extends React.Component {
  render() {
    return (
      <div>
        <div className="title-block title-e-commerce-block">
          <div className="title-block-text">
            <h2>КРИЗИС ИНТЕГРАТОР</h2>
            <h1>Импортозамещение</h1>
            <span>
              Создание и проектирование отечественных автоматизованных систем с
              нуля под нужды заказчика
            </span>
          </div>
        </div>
        <div className="yellow-line" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TitleEcommerce
