import React from 'react'
import { IndexRoute, Route } from 'react-router'
import App from 'containers/App'
import HomePage from 'containers/HomePage'
import Contacts from 'containers/Contacts'
import Partners from 'containers/Partners'
import About from 'containers/About'
import Analytics from 'containers/Analytics'
import Sdm from 'containers/Sdm'
import Performance from 'containers/Performance'
import Industry from 'containers/Industry'
import Services from 'containers/Services'
import TransportSecurity from 'containers/TransportSecurity'
import Security from 'containers/Security'
import Ecosystem from 'containers/Ecosystem'
import ECommerce from 'containers/ECommerce'
import NotFound from 'containers/NotFound'

const Router = (
  <Route path="/" component={App}>
    <IndexRoute component={HomePage} />
    <Route path="partners" components={Partners} />
    <Route path="contacts" components={Contacts} />
    <Route path="about" components={About} />
    <Route path="analytics" components={Analytics} />
    <Route path="data-control" components={Sdm} />
    <Route path="performance" components={Performance} />
    <Route path="services" components={Services} />
    {/* <Route path="industry" components={Industry} />*/}
    <Route path="industry">
      <IndexRoute components={Industry} />
      <Route path=":anchor" components={Industry} />
    </Route>
    <Route path="transport-security" components={TransportSecurity} />
    <Route path="ecosystem" components={Ecosystem} />
    <Route path="security" components={Security} />
    <Route path="automation" components={ECommerce} />
    <Route path="*" components={NotFound} />
  </Route>
)

export default Router
