import React from 'react'
import { Link, IndexLink, withRouter, browserHistory } from 'react-router'
import InfoTabs from '../InfoTab/InfoTabs'

class Industry extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    // if (!window || !this.props.params.anchor) {
    //     scrollTo('#' + this.props.params.anchor)
    // } else {
    //     scrollTo(0, 0)
    // }
  }

  render() {
    return (
      <div className="industry-block">
        <div className="industry-block-elem" id="gos">
          <div className="industry-block-elem-title">
            <div className="industry-block-elem-text-title">
              <div className="industry-block-elem-text-title-bottom">
                Государство
              </div>
            </div>
          </div>
          <div className="industry-block-elem-text">
            <div className="industry-block-elem-text-article">
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Один из критериев, по которому оценивается уровень жизни в
                  обществе, – это качество предоставляемых государством услуг,
                  связанных как с решением повседневных вопросов населения, так
                  и с реализацией масштабных программ по обеспечению эффективной
                  работы городской инфраструктуры, поддержанию безопасности,
                  развитию образовательных, энергетических, экологических
                  проектов, повышению качества медицинского обслуживания.
                </div>
                <div className="industry-block-elem-text-desc-elem">
                  Ключевым инструментом, позволяющим достичь высокого уровня
                  государственного управления с целью удовлетворения нужд людей,
                  является информатизация государственных учреждений, городов и
                  целых регионов.
                </div>
              </div>
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Реализация государственных проектов с приставками «умный» и
                  «безопасный», развитие таких концепций, как «Интернет вещей»,
                  «Электронное правительство», «Онлайн розничная торговля»,
                  позволит перейти к Цифровой экономике, а значит сделать жизнь
                  лучше.
                </div>
              </div>
            </div>
            <div className="industry-block-elem-text-icon">
              <div className="industry-block-elem-text-icon-title">
                <span>КРИЗИС ИНТЕГРАТОР для государства</span>
              </div>
              <Link to="/data-control">
                <div className="industry-block-elem-icon-item data-control-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Управление данными
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Для создания систем бюджетного планирования в
                      государственных структурах
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/ecosystem">
                <div className="industry-block-elem-icon-item ecosystem-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Экосистема
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Облачная платформа для решения отраслевых задач
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/transport-security">
                <div className="industry-block-elem-icon-item tsecurity-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Транспортная безопасность
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Позволит решить задачу транспортной безопасности в РФ
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/security">
                <div className="industry-block-elem-icon-item security-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Безопасность
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Система обеспечения безопасности государственных объектов
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/automation">
                <div className="industry-block-elem-icon-item automation-ki-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Импортозамещение
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      КИ успешно реализует проекты по переходу на отечественное
                      программное обеспечение и оборудование
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="industry-block-elem" id="transport">
          <div className="industry-block-elem-title">
            <div className="industry-block-elem-text-title">
              <div className="industry-block-elem-text-title-bottom">
                Транспорт
              </div>
            </div>
          </div>
          <div className="industry-block-elem-text">
            <div className="industry-block-elem-text-article">
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Высокий уровень развития транспортного сообщения и
                  транспортной инфраструктуры определяет темп экономического
                  роста и конкурентоспособность страны. Прогресс в этой области
                  требует постоянного поиска новых решений для обеспечения
                  безопасности транспорта, соблюдения экологических норм,
                  использования новых источников энергии, оптимизации
                  логистических маршрутов, формирования предпосылок для перехода
                  на беспилотные средства передвижения. Новое качество жизни
                  людей в городах зависит от качества и безопасности
                  общественного транспорта.
                </div>
              </div>
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Для обеспечения эффективного мониторинга и контроля в
                  транспортной сфере необходимо в каждый момент времени по
                  каждому транспортному средству отвечать на базовые вопросы:
                  Что? (какое событие произошло), Где? (в какой точке
                  пространства), Когда? (в какой момент времени).
                </div>
              </div>
            </div>

            <div className="industry-block-elem-text-icon">
              <div className="industry-block-elem-text-icon-title">
                <span>КРИЗИС ИНТЕГРАТОР для транспорта</span>
              </div>
              <Link to="/transport-security">
                <div className="industry-block-elem-icon-item tsecurity-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Транспортная безопасность
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Позволит решить задачу транспортной безопасности в РФ
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/news/2017/2017-12-09">
                <div className="industry-block-elem-icon-item title-transport-security-block">
                  <div className="new ">Новости</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Транспортная безопасность
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      КРИЗИС ИНТЕГРАТОР стал партнером АО «ГЛОНАСС» в сфере
                      транспортной безопасности
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="industry-block-elem" id="ecommerce">
          <div className="industry-block-elem-title">
            <div className="industry-block-elem-text-title">
              <div className="industry-block-elem-text-title-bottom">
                Импортозамещение
              </div>
            </div>
          </div>
          <div className="industry-block-elem-text">
            <div className="industry-block-elem-text-article">
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Для российских компания возникла ситуация, когда существует
                  угроза отключения поддержки иностранного ПО, возможности его
                  обновлений и даже возможности того, что обновление может
                  нарушить отказоустойчивость бизнеса, сейчас остро стоит
                  проблема поиска аналогов иностранному ПО, переходу на аналоги.
                  Наш сайт импортозамещения поможет перейти организациям на
                  российское ПО и подобрать импортозамещение оборудования. В
                  сферы импортозамещения попали услуги программистов,
                  иностранные сервисы, хостинг и аренда ПО на иностранных
                  серверах.{' '}
                </div>
              </div>
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Области импортозамещения затронули и иностранное оборудование,
                  включая его поддержку и ремонт при отсутствии комплектующих.
                  Учитывая все это, а также подписанный Президентом РФ указу «О
                  национальных целях и стратегических задачах развития
                  Российской Федерации на период до 2024 года» по которому
                  госорганы, органам местного самоуправления и гос. организациям
                  должны на отечественное программное обеспечение.
                </div>
              </div>
            </div>

            <div className="industry-block-elem-text-icon">
              <div className="industry-block-elem-text-icon-title">
                <span>КРИЗИС ИНТЕГРАТОР для автоматизации</span>
              </div>

              <Link to="/automation">
                <div className="industry-block-elem-icon-item automation-ki-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Импортозамещение
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Комплекс решений в области автоматизации
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="industry-block-elem" id="jkh">
          <div className="industry-block-elem-title">
            <div className="industry-block-elem-text-title">
              <div className="industry-block-elem-text-title-bottom">
                Жилищно-коммунальное хозяйство
              </div>
            </div>
          </div>
          <div className="industry-block-elem-text">
            <div className="industry-block-elem-text-article">
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Современные урбанистические тенденции ведут к концентрации
                  населения в крупных мегаполисах, изменению модели проживания и
                  работы людей в городах, модернизации транспортной
                  инфраструктуры, применению новых подходов в строительной
                  сфере. Эти и другие изменения накладывают дополнительные
                  требования к энергоснабжению городов, включая электричество,
                  тепло, водоснабжение. Рост потребления энергоресурсов привел к
                  необходимости разработки новых решений для оптимизации работы
                  жилищно-коммунального хозяйства.
                </div>
                <div className="industry-block-elem-text-desc-elem">
                  Одной из базовых концепций, на которой строится стратегия
                  развития умных и безопасных городов, является «Интернет
                  вещей». Она предполагает использование интеллектуальных
                  датчиков, сенсоров, приборов, устройств, способных собирать,
                  предварительно обрабатывать и передавать информацию, в том
                  числе об использовании энергоресурсов. Объединение методов и
                  технологий сбора и анализа информации о потреблении ресурсов
                  позволяет оптимизировать их расход, найти и использовать
                  резервы экономии, реализовать дистанционный мониторинг и
                  контроль конечных потребителей, включая организации и
                  физических лиц.
                </div>
              </div>
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Экспертиза КРИЗИС ИНТЕГРАТОР ориентирована на применение
                  современных технологий телематического дистанционного
                  управления комплексом ЖКХ в комбинации с методами и
                  технологиями машинного обучения, искусственного интеллекта,
                  прогнозного моделирования и визуального представления данных.
                </div>
                <div className="industry-block-elem-text-func">
                  <div className="industry-block-elem-text-func-title">
                    <span>
                      Компонентами решения КРИЗИС ИНТЕГРАТОР для ЖКХ являются
                    </span>
                  </div>
                  <div className="industry-block-elem-text-func-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/compucter2.svg"
                      alt="icon"
                    />
                    <span>
                      Система сбора и предварительной обработки информации с
                      датчиков и приборов
                    </span>
                  </div>
                  <div className="industry-block-elem-text-func-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-12.svg"
                      alt="icon"
                    />
                    <span>
                      Система хранения и анализа данных, прогнозного
                      моделирования и формирования рекомендаций на базе
                      нейросетей
                    </span>
                  </div>
                  <div className="industry-block-elem-text-func-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/battery.svg"
                      alt="icon"
                    />
                    <span>
                      Система геопозиционирования объектов учета
                      энергопотребления
                    </span>
                  </div>
                  <div className="industry-block-elem-text-func-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/compucter3.svg"
                      alt="icon"
                    />
                    <span>
                      Портал мониторинга, контроля и отчетности для
                      дистанционной работы пользователей в режиме реального
                      времени
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="industry-block-elem-text-icon">
              <div className="industry-block-elem-text-icon-title">
                <span>КРИЗИС ИНТЕГРАТОР для ЖКХ</span>
              </div>
              <Link to="/ecosystem">
                <div className="industry-block-elem-icon-item ecosystem-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Экосистема
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Интеллектуальная платформа процессинга данных для
                      «Интернет вещей» и ЖКХ
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="performance">
                <div className="industry-block-elem-icon-item stv-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Представление
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Гибкая система аналитической отчетности
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="industry-block-elem" id="security">
          <div className="industry-block-elem-title">
            <div className="industry-block-elem-text-title">
              <div className="industry-block-elem-text-title-bottom">
                Безопасность
              </div>
            </div>
          </div>
          <div className="industry-block-elem-text">
            <div className="industry-block-elem-text-article">
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Опыт последних десятилетий показал, насколько высокой может
                  быть уязвимость населения развитых мегаполисов в условиях
                  мирной жизни от проявлений внутренней и внешней агрессии.
                  Экстремизм, террористические атаки, нарушение правил
                  безопасности на производстве и на транспорте, инциденты в
                  местах большого скопления людей и на режимных объектах,
                  преступность, банальное хулиганство – неполный перечень угроз
                  настоящего времени, которые напрямую влияют на уровень
                  безопасности жизни.
                </div>
                <div className="industry-block-elem-text-desc-elem">
                  В современном обществе существует запрос на все виды
                  безопасности, как залога благополучия и базового условия
                  стабильности. Для поддержания высокого гарантированного уровня
                  безопасности необходимо уметь прогнозировать возможные
                  негативные сценарии с целью предотвращения опасных событий еще
                  до их возникновения.
                </div>
              </div>
              <div className="industry-block-elem-text-desc">
                <div className="industry-block-elem-text-desc-elem">
                  Технологии, позволяющие применять риск-ориентированный подход,
                  являются ключевым инструментом для обеспечения безопасности.
                </div>
                <div className="industry-block-elem-text-desc-elem">
                  Компания КРИЗИС ИНТЕГРАТОР разрабатывает решения с
                  использованием компьютерного зрения, машинного обучения,
                  искусственного интеллекта, видеоанализа, технологий
                  геопозиционирования для мониторинга, анализа, прогнозирования
                  и раннего предупреждения инцидентов на режимных объектах,
                  объектах повышенной опасности, в публичных местах, местах
                  отдыха и образовательных учреждениях.
                </div>
              </div>
            </div>
            <div className="industry-block-elem-text-func">
              <div className="industry-block-elem-text-func-title">
                <span>
                  Компонентами решения КРИЗИС ИНТЕГРАТОР для безопасности
                  являются
                </span>
              </div>
              <div className="industry-block-elem-text-func-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-32.svg"
                  alt="icon"
                />
                <span>Дистанционная идентификация человека</span>
              </div>
              <div className="industry-block-elem-text-func-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-8_(1).svg"
                  alt="icon"
                />
                <span>
                  Онлайн обработка и анализ всех видов данных, влияющих на
                  уровень безопасности объекта
                </span>
              </div>
              <div className="industry-block-elem-text-func-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-24.svg"
                  alt="icon"
                />
                <span>
                  Дистанционное определение поведенческого профиля человека или
                  группы людей
                </span>
              </div>
              <div className="industry-block-elem-text-func-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-11.svg"
                  alt="icon"
                />
                <span>
                  Автоматический мониторинг уровня безопасности объекта с
                  использованием пользовательского портала
                </span>
              </div>
              <div className="industry-block-elem-text-func-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-17_(1).svg"
                  alt="icon"
                />
                <span>
                  Дистанционная идентификация потенциально опасных предметов
                </span>
              </div>
              <div className="industry-block-elem-text-func-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/Group_15.svg"
                  alt="icon"
                />
                <span>Прогнозное моделирование ситуаций и рисков</span>
              </div>
              <div className="industry-block-elem-text-func-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/Group_42.svg"
                  alt="icon"
                />
                <span>Трекинг людей с выявлением девиантного поведения</span>
              </div>
            </div>
            <div className="industry-block-elem-text-icon">
              <div className="industry-block-elem-text-icon-title">
                <span>КРИЗИС ИНТЕГРАТОР для безопасности</span>
              </div>
              <Link to="/security">
                <div className="industry-block-elem-icon-item security-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Безопасность
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Комплексная система безопасности объекта
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/transport-security">
                <div className="industry-block-elem-icon-item tsecurity-img">
                  <div className="new ">Решение</div>
                  <div className="industry-block-elem-icon-item-text">
                    <div className="industry-block-elem-icon-item-text-title">
                      КИ Транспортная безопасность
                    </div>
                    <div className="industry-block-elem-icon-item-text-desc">
                      Cистема безопасности на транспорте
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="gray-line" />
        <InfoTabs />
      </div>
    )
  }
}

export default withRouter(Industry)
