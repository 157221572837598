import React from 'react'
import { Link } from 'react-router'
import SliderTop from '../Slider/SliderDataControl/SliderDataControl'
import Slide3d from '../Slider/Slider3D/Slider3D'
import classnames from 'classnames'

class Sdm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slide: 'one',
    }
    this.checkVerif = this.checkVerif.bind(this)
  }

  checkVerif(value) {
    this.setState({
      slide: value,
    })
  }

  render() {
    const images = [
      'https://609111.selcdn.ru/ki/static/images/slider-ux/data-control/1.1.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/data-control/2.1.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/data-control/3.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/data-control/18.png',
    ]
    return (
      <div>
        <SliderTop />
        <div className="work-complex-block sdm-complex">
          <div className="complex-model-title">
            <h2>Функциональная модель КРИЗИС ИНТЕГРАТОР</h2>
          </div>
          <div className="budgeting-block">
            <div className="budgeting-block-top">
              <div className="budgeting-block-top-left">
                <div className="budgeting-block-elem">
                  <span>
                    Моделирование и автоматизация процессов бюджетирования на
                    предприятиях с разветвленной территориальной структурой
                  </span>
                </div>
                <div className="triangle-right" />
                <div className="budgeting-block-elem">
                  <span>
                    Ведение процесса бюджетирования снизу вверх и сверху вниз в
                    режиме реального времени
                  </span>
                </div>
                <div className="triangle-right" />
              </div>
              <div className="budgeting-block-top-center">
                <div className="budgeting-block-elem">
                  <img
                    className="budgeting-block-elem-schema"
                    src="https://609111.selcdn.ru/ki/static/images/scheme.svg"
                    alt=""
                  />
                </div>
                <div className="triangle-bottom" />
              </div>
              <div className="budgeting-block-top-right">
                <div className="triangle-left" />
                <div className="budgeting-block-elem">
                  <span>
                    Одновременная работа в системе более 2500 пользователей
                    через web-интерфейс
                  </span>
                </div>
                <div className="triangle-left" />
                <div className="budgeting-block-elem">
                  <span>
                    Ввод данных: фактических, плановых и корректировок
                  </span>
                </div>
                <div className="triangle-left" />
                <div className="budgeting-block-elem">
                  <span>Централизованное хранение данных</span>
                </div>
              </div>
            </div>
            <div className="budgeting-block-bottom">
              <div className="budgeting-block-bottom-left">
                <div className="budgeting-block-elem">
                  <span>Прогнозирование доходов и расходов</span>
                </div>
                <div className="triangle-right" />
                <div className="budgeting-block-elem">
                  <span>
                    Обработка более 200 тыс. временных рядов и работа с
                    неполными данными
                  </span>
                </div>
                <div className="triangle-right" />
              </div>
              <div className="budgeting-block-bottom-center">
                <div className="triangle-top" />
                <div className="budgeting-block-elem">
                  <div className="budgeting-block-bottom-center-text">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/stp-ico.svg"
                      alt=""
                    />
                    <span>Применение предиктивных моделей</span>
                  </div>
                </div>
                <div className="triangle-right" />
                <div className="budgeting-block-elem">
                  <div className="budgeting-block-bottom-center-text">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/stv-ico.svg"
                      alt=""
                    />
                    <span>Представление аналитических данных</span>
                  </div>
                </div>
              </div>
              <div className="budgeting-block-bottom-right">
                <div className="triangle-left" />
                <div className="budgeting-block-elem">
                  <span>
                    Гибко настраиваемая аналитическая отчетность в графическом и
                    табличном видах
                  </span>
                </div>
                <div className="triangle-left" />
                <div className="budgeting-block-elem">
                  <span>
                    Оперативное отслеживание процессов благодаря мобильной
                    версии системы
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Slide3d
          clas="Sdm ux-slide"
          type="not"
          image={images}
          titleGray="КРИЗИС ИНТЕГРАТОР Управление данными"
          title="Интерфейс"
          description="Система позволяет моделировать и автоматизировать процесс бюджетирования на предприятиях любого масштаба"
        />
        <div className="data-control-archy-block sdm-complex">
          <div className="ts-security-solution-desc-title-btn">
            <div className="complex-model-title-text">
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  {
                    'ts-security-btn-active': this.state.slide === 'one',
                  },
                )}
                onClick={() => this.checkVerif('one')}
              >
                Технологическая архитектура
              </div>
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  {
                    'ts-security-btn-active': this.state.slide === 'two',
                  },
                )}
                onClick={() => this.checkVerif('two')}
              >
                Функциональная архитектура
              </div>
            </div>
          </div>
          <div
            className={classnames('', {
              block: this.state.slide === 'one',
              'display-none': this.state.slide !== 'one',
            })}
          >
            <div>
              <div className="archy-block-top">
                <div className="archy-block-top-title">Пользователи</div>
                <div className="archy-block-top-module">
                  <div className="archy-block-top-module-title">
                    <div className="archy-block-top-module-title-left">
                      Front-end
                    </div>
                    <div className="archy-block-top-module-title-right">
                      Back-end
                    </div>
                  </div>
                  <div className="archy-block-top-module-elem">
                    <div className="archy-block-top-module-elem-item">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control1.png"
                        alt=""
                      />
                      <div className="archy-block-top-module-elem-item-text">
                        <span className="archy-title">Браузер</span>
                        <span className="archy-desc">
                          Интерфейс пользователя
                        </span>
                      </div>
                    </div>
                    <div className="archy-block-top-module-elem-item">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control2.png"
                        alt=""
                      />
                      <div className="archy-block-top-module-elem-item-text">
                        <span className="archy-title">Nginx сервер</span>
                        <span className="archy-desc">Балансировка</span>
                      </div>
                    </div>
                    <div className="archy-block-top-module-elem-item">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control3.png"
                        alt=""
                      />
                      <div className="archy-block-top-module-elem-item-text">
                        <span className="archy-title">Клиент</span>
                        <span className="archy-desc">Клиентская логика</span>
                      </div>
                    </div>
                    <div className="archy-block-top-module-elem-item w40">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control4.png"
                        alt=""
                      />
                      <div className="archy-block-top-module-elem-item-text">
                        <span className="archy-title">Back-end сервер</span>
                        <span className="archy-desc">
                          Функциональная логика
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="archy-block-top-module-elem">
                    <div className="archy-block-top-module-elem-item">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control5.png"
                        alt=""
                      />
                      <div className="archy-block-top-module-elem-item-text">
                        <span className="archy-title">Сервер базы данных</span>
                        <span className="archy-desc">Mongo DB</span>
                      </div>
                    </div>
                    <div className="archy-block-top-module-elem-item">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control6.png"
                        alt=""
                      />
                      <div className="archy-block-top-module-elem-item-text">
                        <span className="archy-title">Front-end сервер</span>
                        <span className="archy-desc">Транспорт запросов</span>
                      </div>
                    </div>
                    <div className="archy-block-top-module-elem-item back-none rest">
                      <span className="archy-title-rest">rest api</span>
                    </div>
                    <div className="archy-block-top-module-elem-item">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control2.png"
                        alt=""
                      />
                      <div className="archy-block-top-module-elem-item-text">
                        <span className="archy-title">Nginix сервер</span>
                        <span className="archy-desc">Балансировка</span>
                      </div>
                    </div>
                    <div className="archy-block-top-module-elem-item">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control7.png"
                        alt=""
                      />
                      <div className="archy-block-top-module-elem-item-text">
                        <span className="archy-title">Сервер базы данных</span>
                        <span className="archy-desc">PostgreSQL</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="archy-block-bottom">
                <div className="archy-block-bottom-title">Администратор</div>
                <div className="archy-block-bottom-module">
                  <div className="archy-block-bottom-module-title">
                    <div className="archy-block-bottom-module-title-left">
                      Модуль администрирования
                    </div>
                  </div>
                  <div className="archy-block-bottom-module-elem">
                    <div className="archy-block-bottom-module-elem-item w40">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control8.png"
                        alt=""
                      />
                      <div className="archy-block-top-module-elem-item-text">
                        <span className="archy-title">Клиент</span>
                        <span className="archy-desc">
                          Web-интерфейс администратора
                        </span>
                      </div>
                    </div>
                    <div className="archy-block-bottom-module-elem-item back-none rest">
                      <span className="archy-title-rest">rest api</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ecommerce-solution-desc-slide-bottom-title">
                Технологическая архитектура
              </div>
            </div>
          </div>
          <div
            className={classnames('work-complex-block', {
              block: this.state.slide === 'two',
              'display-none': this.state.slide !== 'two',
            })}
          >
            <div>
              <div className="work-complex-block-top">
                <div className="work-complex-logo">
                  КРИЗИС ИНТЕГРАТОР (Управление данными)
                </div>
                <div className="work-complex-info">
                  <div className="work-complex-info-block w-one">
                    <div className="work-complex-info-elem-item w-six">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/02.svg"
                        alt=""
                      />
                      <span>
                        Конструирование логики процесса бюджетирования
                      </span>
                    </div>
                    <div className="work-complex-info-elem-item w-six">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/03.svg"
                        alt=""
                      />
                      <span>
                        Согласование и корректировка плановых показателей
                      </span>
                    </div>
                    <div className="work-complex-info-elem-item w-six">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/visual7.png"
                        alt=""
                      />
                      <span>Анализ и контроль расходов</span>
                    </div>
                    <div className="work-complex-info-elem-item w-six">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/visual8.png"
                        alt=""
                      />
                      <span>Планирование и контроль доходов</span>
                    </div>
                    <div className="work-complex-info-elem-item w-six">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/data-control10.png"
                        alt=""
                      />
                      <span>
                        Настройка регламентированных отчетов и их выгрузка в
                        Excel
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="work-complex-pointer-top">
                <div className="work-complex-pointer-top-left">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/Group_30.svg"
                    alt=""
                  />
                  <span>Данные для построения аналитики</span>
                </div>
                <div className="work-complex-pointer-top-right">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/Group_30.svg"
                    alt=""
                    className="revert"
                  />
                  <span>Прогнозные данные</span>
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/Group_30.svg"
                    alt=""
                  />
                  <span>Данные для построения прогноза</span>
                </div>
              </div>
              <div className="work-complex-block-bottom">
                <div className="complex-block-bottom-left">
                  <div className="work-complex-logo">
                    КРИЗИС ИНТЕГРАТОР (Представление)
                    <Link to="performance">
                      <div className="first-tab-link">
                        Подробнее{' '}
                        <span className="infotabs-block-text-link-icon" />
                      </div>
                    </Link>
                  </div>
                  <div className="work-complex-info">
                    <div className="work-complex-info-block w-one">
                      <div className="work-complex-info-elem-item w-two">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/visual10.png"
                          alt=""
                        />
                        <span>
                          Представление аналитических данных в виде:
                          <span className="bottom-ul">
                            <ul>
                              <li>- таблиц</li>
                              <li>- диаграмм</li>
                              <li>- графиков</li>
                            </ul>
                          </span>
                        </span>
                      </div>
                      <div className="work-complex-info-elem-item w-two">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/visual5.png"
                          alt=""
                        />
                        <span>Настройка отчетов и их выгрузка в PDF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="complex-block-bottom-right">
                  <div className="work-complex-logo">
                    КРИЗИС ИНТЕГРАТОР (Аналитика)
                    <Link to="/analytics">
                      <div className="first-tab-link">
                        Подробнее{' '}
                        <span className="infotabs-block-text-link-icon" />
                      </div>
                    </Link>
                  </div>
                  <div className="work-complex-info">
                    <div className="work-complex-info-block w-one">
                      <div className="work-complex-info-elem-item w-one">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/visual9.png"
                          alt=""
                        />
                        <span>
                          Применение к данным моделей прогнозирования доходов и
                          расходов
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="work-complex-pointer-bottom">
                <div className="work-complex-pointer-bottom-block">
                  <span className="text-arrow-two">Прогнозные данные</span>
                  <img
                    className="arrow-two"
                    src="https://609111.selcdn.ru/ki/static/images/arrow_two.svg"
                    alt=""
                  />
                </div>
              </div>
              <div className="ecommerce-solution-desc-slide-bottom-title">
                Функциональная архитектура
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Sdm
