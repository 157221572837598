// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/Pattern0.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.performance-complex {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}),
    linear-gradient(
      135deg,
      #052c1d 0%,
      #0f4e46 30.32%,
      #0f3619 67.3%,
      #073125 100%
    );
}
`, "",{"version":3,"sources":["webpack://./src/assets/static/style/Solutions/performance.css"],"names":[],"mappings":"AAAA;EACE;;;;;;;KAOG;AACL","sourcesContent":[".performance-complex {\n  background: url('../../images/Pattern0.svg'),\n    linear-gradient(\n      135deg,\n      #052c1d 0%,\n      #0f4e46 30.32%,\n      #0f3619 67.3%,\n      #073125 100%\n    );\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
