import React from 'react'
import { Link, IndexLink } from 'react-router'
import ReactDOM from 'react-dom'
import EventListener, { withOptions } from 'react-event-listener'
// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class SliderSecurity extends React.Component {
  render() {
    return (
      <div>
        <div className="solutions-slider-top-block">
          <div className="solution-text">
            <div className="first-tab-title">
              КРИЗИС ИНТЕГРАТОР (Безопасность)
            </div>
            <span>Возможности</span>
            <span className="about-text-decs">
              КИ Безопасность позволяет в реальном времени в автоматическом
              режиме наполнять систему показателей безопасности объекта (KPI’s)
              на основе данных, полученных из разнородных систем и вырабатывать
              решения для руководителей и сотрудников службы безопасности на
              базе алгоритмов машинного обучения
            </span>
          </div>
          <div className="slider-team solution-slider">
            {/* <EventListener target={document} onClick={(event) => this.sliders(event)}/>*/}

            <Carousel interval={3000} showThumbs={false}>
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-30.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Системы обеспечения безопасности социально значимых
                      объектов (стадионы, концертные залы, места массового
                      отдыха)
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-8_(1).svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Создание высокоэффективных систем раннего предупреждения и
                      защиты военных объектов от террористических угроз
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_42.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Создание систем безопасности на базе риск-ориентированного
                      подхода для предотвращения террористических атак и
                      техногенных катастроф на стратегических промышленных
                      предприятиях РФ
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-4(1).svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Создание систем комплексной безопасности объектов
                      государственного значения
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default SliderSecurity
