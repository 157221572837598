// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  width: 100%;
  height: 150px;
  background-color: #2d2d2d;
  color: #848484;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  box-sizing: border-box;
}

.copy {
  padding-top: 75px;
}

.lang {
  text-align: right;
  right: 170px;
  position: relative;
  top: -15px;
  cursor: pointer;
}

.lang img {
  width: 35px;
  padding-right: 10px;
  vertical-align: bottom;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/assets/static/style/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,sBAAsB;EACtB,sBAAsB;AACxB","sourcesContent":[".footer {\n  width: 100%;\n  height: 150px;\n  background-color: #2d2d2d;\n  color: #848484;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.copy {\n  padding-top: 75px;\n}\n\n.lang {\n  text-align: right;\n  right: 170px;\n  position: relative;\n  top: -15px;\n  cursor: pointer;\n}\n\n.lang img {\n  width: 35px;\n  padding-right: 10px;\n  vertical-align: bottom;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
