import React from 'react'
import { Link, IndexLink } from 'react-router'
import InfoTabs from '../InfoTab/InfoTabs'

class Services extends React.Component {
  render() {
    return (
      <div>
        <div className="services-block">
          <div className="services-block-elem">
            <div className="services-block-elem-title">
              <div className="services-block-elem-text-title">Консалтинг</div>
            </div>
            <div className="services-block-elem-text">
              <div className="services-block-elem-text-article  ">
                <div className="partner-solution-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/group-29-file.svg"
                    alt="icon"
                  />
                  <div className="partner-solution-elem-decs">
                    Разработка и автоматизация методологии бюджетного
                    планирования и управленческого учета
                  </div>
                </div>
                <div className="partner-solution-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/group-8.svg"
                    alt="icon"
                  />
                  <div className="partner-solution-elem-decs">
                    Разработка и реализация плана перевода торговых транзакций в
                    Интернет на базе E-commerce платформ с использованием
                    технологий персонализации и персонификации покупателей
                  </div>
                </div>
                <div className="partner-solution-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/group-49-copy.png"
                    alt="icon"
                  />
                  <div className="partner-solution-elem-decs">
                    Формирование и реализация предложений по использованию
                    когнитивных систем для бизнес-планирования, управления
                    рисками, предиктивного анализа
                  </div>
                </div>
                <div className="partner-solution-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/Group51.svg"
                    alt="icon"
                  />
                  <div className="partner-solution-elem-decs">
                    Разработка и реализация стратегии транспортной безопасности
                    региона, города, транспортного предприятия
                  </div>
                </div>
              </div>
              <div className="services-block-elem-text-article">
                <div className="services-block-elem-text-desc">
                  <div className="services-block-elem-text-desc-elem">
                    Стратегия является ключевым активом компании вне зависимости
                    от масштаба и сферы деятельности. Законы конкурентной борьбы
                    устанавливают необходимость разработки современных
                    бизнес-моделей для работы с заказчиками, поставщиками,
                    контрагентами, персоналом, государственными органами.
                    Актуальность и качество этих бизнес-моделей зависит от
                    способности организации корректно интерпретировать
                    информацию и трансформировать ее в эффективную стратегию
                    роста. Для этого необходимо научиться использовать
                    современные средства работы с данными, методы предиктивного
                    анализа, интеллектуальные системы.
                  </div>
                  <div className="services-block-elem-text-desc-elem">
                    Задача КРИЗИС ИНТЕГРАТОР как консультанта заключается в
                    разработке сбалансированного плана трансформации компании с
                    использованием цифровых технологий и формировании
                    предложений по внедрению когнитивных систем управления.
                  </div>
                </div>
                <div className="services-block-elem-text-desc">
                  <div className="services-block-elem-text-desc-elem">
                    Стратегия компании по переходу к современной модели ведения
                    бизнеса на базе цифровых технологий лежит на стыке практик,
                    методов, научных дисциплин, трендов в области обработки
                    информации и Интернета, включая
                  </div>
                  <div className="services-block-elem-text-desc-elem">
                    <div className="services-block-elem-text-desc-list span-yellow-dot">
                      <ul className="list-height">
                        <li>Машинное обучение</li>
                        <li>UX/UI дизайн</li>
                        <li>Нейросети</li>
                        <li>Риск-ориентированный подход</li>
                        <li>Web - приложения</li>
                        <li>Компьютерное зрение</li>
                        <li>Аналитические платформы</li>
                        <li>Интернет Вещей</li>
                        <li>Обработка больших данных</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-elem">
            <div className="services-block-elem-title">
              <div className="services-block-elem-text-title">Разработка</div>
            </div>
            <div className="services-block-elem-text">
              <div className="services-block-elem-text-article">
                <div className="services-block-elem-text-desc">
                  <div className="services-block-elem-text-desc-elem">
                    Этап разработки рассматривается нами как часть жизненного
                    цикла продуктов, создаваемых КРИЗИС ИНТЕГРАТОР. Мы
                    используем методы, стандарты, технологии, подходы,
                    обеспечивающие непрерывный прогресс разработки ПО на базе
                    информации, получаемой от конечных пользователей и данных
                    исследования рынка.
                  </div>
                  <div className="services-block-elem-text-func-title mrg-top">
                    Это позволяет поддерживать высокий уровень качества ПО, в
                    том числе
                  </div>
                  <div className="services-block-elem-text-desc-list span-yellow-dot">
                    <ul>
                      <li>Уменьшить количество ошибок в релизах версий</li>
                      <li>
                        Обеспечить инновационность продукта с опережением
                        ожиданий пользователей
                      </li>
                      <li>
                        Повысить эффективность обратной связи от пользователей в
                        целях учета их требований и рекомендаций в следующих
                        релизах продукта
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="services-block-elem-text-desc">
                  <div className="services-block-elem-text-desc-list span-yellow-dot">
                    <ul>
                      <li>
                        Обеспечить релевантный отклик на прогрессивные мировые
                        тенденции в сфере новых средств разработки, юзабилити и
                        дизайна
                      </li>
                      <li>
                        Обеспечить непрерывность жизненного цикла продукта
                      </li>
                      <li>
                        Обеспечить соответствие продукта отраслевым стандартам
                        (в первую очередь для государственных организаций и
                        компаний)
                      </li>
                      <li>
                        Использовать лучшие практики зарубежных аналогов с целью
                        адаптации и локализации технологий, снижения времени на
                        разработку и реализацию трансфера технологий в
                        экосистему отечественных продуктов
                      </li>
                      <li>
                        Обеспечить скорость внесения изменений в продукт и
                        исправления ошибок
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="services-block-elem-text-func-title-big">
                Ключевыми подходами и технологиями для обеспечения высокого
                уровня ПО, создаваемого КРИЗИС ИНТЕГРАТОР, являются
              </div>
              <div className="service-block-scheme-two">
                {/* <div className="complex-model-bottom-block-module-elem">*/}
                {/* <div className="complex-model-bold">01.</div>*/}
                {/* <div>Гибкая методология Scrum</div>*/}
                {/* </div>*/}
                {/* <div className="triangle-right"></div>*/}

                {/* <div className="complex-model-bottom-block-module-elem">*/}
                {/* <div className="complex-model-bold">02.</div>*/}
                {/* <div>Цикл Деминга</div>*/}
                {/* </div>*/}
                {/* <div className="triangle-right"></div>*/}

                {/* <div className="complex-model-bottom-block-module-elem">*/}
                {/* <div className="complex-model-bold">03.</div>*/}
                {/* <div>Серия ГОСТ 34</div>*/}
                {/* </div>*/}
                {/* <div className="triangle-right"></div>*/}

                {/* <div className="complex-model-bottom-block-module-elem">*/}
                {/* <div className="complex-model-bold">04.</div>*/}
                {/* <div>Авторская методология внедрения</div>*/}
                {/* </div>*/}
                {/* <div className="triangle-right"></div>*/}
                {/* <div className="complex-model-bottom-block-module-elem">*/}
                {/* <div className="complex-model-bold">05.</div>*/}
                {/* <div>Партнерская программа производителя ПО </div>*/}
                {/* </div>*/}
                {/* <div className="triangle-right"></div>*/}
                {/* <div className="complex-model-bottom-block-module-elem">*/}
                {/* <div className="complex-model-bold">06.</div>*/}
                {/* <div>Регламент технической поддержки для всех продуктов</div>*/}
                {/* </div>*/}
                <div className="service-block-scheme-two-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/icon1.svg"
                    alt=""
                  />
                  <span>Гибкая методология SCRUM</span>
                </div>
                <div className="service-block-scheme-two-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/group-29-file.svg"
                    alt=""
                  />
                  <span>
                    Регламент технической поддержки для всех продуктов КРИЗИС
                    ИНТЕГРАТОР
                  </span>
                </div>
                <div className="service-block-scheme-two-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/Group 1.svg"
                    alt=""
                  />
                  <span>
                    Партнерская программа производителя ПО (ООО «КРИЗИС
                    ИНТЕГРАТОР»)
                  </span>
                </div>
                <div className="service-block-scheme-two-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/icon55.svg"
                    alt=""
                  />
                  <span>Авторская методология внедрения</span>
                </div>
                <div className="service-block-scheme-two-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/icon3.svg"
                    alt=""
                  />
                  <span>Цикл Деминга</span>
                </div>
                <div className="service-block-scheme-two-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/icon66.svg"
                    alt=""
                  />
                  <span>Серия ГОСТ 34</span>
                </div>
              </div>

              <div className="services-block-elem-text-func-title-big">
                Схема цикла разработки с использованием методологии SCRUM
              </div>

              {/* <div className="service-block-scheme-three">*/}
              {/* <div className="service-block-scheme-three-top">*/}
              {/* <div className="service-block-scheme-three-top-left">*/}
              {/* <div className="service-block-scheme-three-top-text">*/}
              {/* <span>БЭКЛОГ продукта</span>*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/arrow_top.svg" alt=""/>*/}
              {/* </div>*/}
              {/* <div className="complex-model-bottom-block-module-elem">*/}
              {/* <div className="complex-model-bold">*/}
              {/* 01.*/}
              {/* </div>*/}
              {/* <div>*/}
              {/* Приоритезация функции*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div className="triangle-right"></div>*/}
              {/* </div>*/}
              {/* <div className="service-block-scheme-three-top-center">*/}
              {/* <div className="service-block-scheme-three-top-text">*/}
              {/* <span>БЭКЛОГ релиза</span>*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/arrow_top.svg" alt=""/>*/}
              {/* </div>*/}
              {/* <div className="service-block-scheme-three-top-text">*/}
              {/* <span>БЭКЛОГ спринта</span>*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/arrow_top.svg" alt=""/>*/}
              {/* </div>*/}
              {/* <div className="complex-model-bottom-block-module-elem">*/}
              {/* <div className="complex-model-bold">*/}
              {/* 02.*/}
              {/* </div>*/}
              {/* <div>*/}
              {/* Планирование задач*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div className="triangle-right"></div>*/}
              {/* </div>*/}
              {/* <div className="service-block-scheme-three-top-right">*/}
              {/* <div className="service-block-scheme-three-top-text">*/}
              {/* <span>Спринт 2 недели</span>*/}
              {/* <img src="" alt=""/>*/}
              {/* </div>*/}
              {/* <div className="service-block-scheme-three-top-right-one">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/oval-3-copy.png" alt=""/>*/}
              {/* <div className="service-block-scheme-three-top-right-one-text">*/}
              {/* <span>*/}
              {/* СТЕНДАП*/}
              {/* </span>*/}
              {/* <span>*/}
              {/* ежедневно*/}
              {/* </span>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div className="service-block-scheme-three-top-right-two">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/oval-3.png" alt=""/>*/}
              {/* <div className="service-block-scheme-three-top-right-two-text">*/}
              {/* <div className="yellow">*/}
              {/* 03.*/}
              {/* </div>*/}
              {/* <span>*/}
              {/* Модульное тестирование*/}
              {/* </span>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div className="service-block-scheme-three-bottom">*/}
              {/* <div className="service-block-scheme-three-bottom-left">*/}
              {/* <span>scrum</span>*/}
              {/* </div>*/}
              {/* <div className="service-block-scheme-three-bottom-center">*/}
              {/* <div className="service-block-scheme-three-bottom-text">*/}
              {/* <span>Ретроспектива</span>*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/arrow_top.svg" alt=""/>*/}
              {/* </div>*/}
              {/* <div className="service-block-scheme-three-bottom-text">*/}
              {/* <span>Релиз продукта</span>*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/arrow_top.svg" alt=""/>*/}
              {/* </div>*/}
              {/* <div className="complex-model-bottom-block-module-elem">*/}
              {/* <div className="complex-model-bold">*/}
              {/* 05.*/}
              {/* </div>*/}
              {/* <div>*/}
              {/* Вовлечение участников команды*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div className="triangle-left"></div>*/}
              {/* <div className="complex-model-bottom-block-module-elem">*/}
              {/* <div className="complex-model-bold">*/}
              {/* 04.*/}
              {/* </div>*/}
              {/* <div>*/}
              {/* Демонстрация работы ПО*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div className="service-block-scheme-three-bottom-right">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/group-50.svg" alt=""/>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* </div>*/}

              <div className="service-block-scheme-four">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/Scrum_scheme.png"
                  alt="scrum"
                />
              </div>
            </div>
          </div>
          <div className="services-block-elem">
            <div className="services-block-elem-title">
              <div className="services-block-elem-text-title">
                Внедрение и сопровождение
              </div>
            </div>
            <div className="services-block-elem-text">
              <div className="services-block-elem-text-article">
                <div className="services-block-elem-text-desc">
                  <div className="services-block-elem-text-desc-elem">
                    Внедрение и сопровождение ПО КРИЗИС ИНТЕГРАТОР на стороне
                    конечного пользователя является частью проектного цикла по
                    созданию отдельных функциональных подсистем или комплексных
                    автоматизированных систем по управлению бизнес-процессами,
                    например финансового планирования, бюджетирования,
                    прогнозирования, генерации отчетности, обеспечения
                    безопасности. В ходе проектов формируются требования к
                    наполнению интерфейсов пользователя, функциям, отчетности,
                    дополнительным сервисам систем.{' '}
                  </div>
                  <div className="services-block-elem-text-desc-elem">
                    С учетом высокой ценности обратной связи от заказчика
                    (конечного пользователя) для внесения усовершенствований в
                    ПО и формирования «лучших практик» по его применению в
                    различных функциональных областях и отраслевых сегментах
                    компания КРИЗИС ИНТЕГРАТОР и ее партнеры придерживаются
                    проектного цикла, который позволяет формировать
                    дополнительные требования к ПО и учитывать их при выпуске
                    новых релизов. Данный подход является критически важным
                    элементом развития ПО в контексте цикла Деминга и позволяет
                    применять результаты внедрения в формировании плана
                    разработки и технической поддержки продукта.
                  </div>
                </div>
                <div className="services-block-elem-text-desc">
                  <div className="services-block-elem-text-func-title">
                    Для достижения целей проекта, определенных Заказчиком,
                    выполнение работ по внедрению и сопровождению решений на
                    базе ПО КРИЗИС ИНТЕГРАТОР состоит из следующих фаз
                  </div>
                  <div className="services-block-elem-text-desc-list span-yellow-dot">
                    <ul>
                      <li>Подготовка проекта</li>
                      <li>Концептуальное проектирование</li>
                      <li>Разработка методологии (при необходимости)</li>
                      <li>Реализация</li>
                      <li>Подготовка к продуктивному старту</li>
                      <li>Продуктивная эксплуатация и поддержка системы</li>
                      <li>
                        Контроль и тестирование настроек информационной системы
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="services-block-elem-text-func-title-big">
                Рекомендуемые фазы внедрения решений КРИЗИС ИНТЕГРАТОР
              </div>

              <div className="service-block-scheme-one">
                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">01.</div>
                  <div>Подготовка проекта</div>
                </div>
                <div className="triangle-right" />

                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">02.</div>
                  <div>Концептуальное проектирование</div>
                </div>
                <div className="triangle-right" />

                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">03.</div>
                  <div>Реализация</div>
                </div>
                <div className="triangle-right" />

                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">04.</div>
                  <div>Внедрение</div>
                </div>
                <div className="triangle-right" />
                <div className="complex-model-bottom-block-module-elem">
                  <div className="complex-model-bold">05.</div>
                  <div>Эксплуатация и поддержка</div>
                </div>
                <div className="triangle-right" />
                <div className="service-block-scheme-one-arrow">
                  <div className="service-block-scheme-one-arrow-left">
                    Разработка методологии
                  </div>
                  <div className="service-block-scheme-one-arrow-right">
                    Тестирование
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-elem">
            <div className="services-block-elem-title">
              <div className="services-block-elem-text-title">
                Облачный процессинг данных
              </div>
            </div>
            <div className="services-block-elem-text">
              <div className="services-block-elem-text-article">
                <div className="services-block-elem-text-desc">
                  <div className="services-block-elem-text-desc-elem">
                    Создание современного конкурентного прикладного программного
                    обеспечения, системы, ИТ- или Интернет – продукта для
                    решения функциональной или отраслевой задачи возможно лишь с
                    использованием прогрессивных средств и технологий
                    разработки, которые включают в себя языки программирования,
                    СУБД, файловые хранилища, библиотеки алгоритмов,
                    вычислительные ресурсы, распределенные кластерные решения.
                  </div>
                  <div className="services-block-elem-text-desc-elem">
                    Чем сложнее задача, чем больше данных для обработки, тем
                    более интеллектуальной должна быть система для решения этой
                    задачи. Небольшие компании, предприниматели, стартапы,
                    отдельные разработчики, студенты – все, кто ставит перед
                    собой цель разработку прикладных систем и выпуск
                    собственного ИТ-продукта, сталкиваются с необходимостью
                    разворачивания ландшафта для процессинга данных.
                  </div>
                </div>
                <div className="services-block-elem-text-desc">
                  <div className="services-block-elem-text-desc-elem">
                    На этом пути приходится либо инвестировать в собственную
                    аппаратно-программную инфраструктуру, заниматься поиском
                    подходящих решений, их конфигурированием и настройкой, либо
                    обратиться к крупным западным игрокам на рынке облачного
                    процессинга.
                  </div>
                  <div className="services-block-elem-text-desc-elem">
                    КРИЗИС ИНТЕГРАТОР развивает облачную платформу с открытым
                    кодом КИ Экосистема с размещением на территории РФ для
                    обработки данных и создания отраслевых решений.
                  </div>
                </div>
                <div className="services-block-elem-text-func">
                  <div className="services-block-elem-text-func-title-big">
                    Мы предоставляем
                  </div>
                  <div className="services-block-elem-text-func-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-23-copy.svg"
                      alt="icon"
                    />
                    <span>
                      Доступ к лучшим технологиям разработки и анализа данных
                    </span>
                  </div>
                  <div className="services-block-elem-text-func-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-24-copy.svg"
                      alt="icon"
                    />
                    <span>
                      Размещение в защищенных ЦОД на серверном оборудовании Dell
                    </span>
                  </div>
                  <div className="services-block-elem-text-func-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-4-copy-3.svg"
                      alt="icon"
                    />
                    <span>
                      Консультации по конфигурированию и настройке системного
                      ландшафта
                    </span>
                  </div>
                  <div className="services-block-elem-text-func-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-49.svg"
                      alt="icon"
                    />
                    <span>
                      Консультации по использованию когнитивных платформ
                      (машинное обучение, нейросети, компьютерное зрение)
                    </span>
                  </div>
                  <div className="services-block-elem-text-func-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/support.svg"
                      alt="icon"
                    />
                    <span>Консультации по стэку Hadoop</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="services-block-elem">*/}
          {/* <div className="industry-block-elem-title">*/}
          {/* <div className="industry-block-elem-text-title">*/}
          {/* <div className="first-tab-title">Экспертиза</div>*/}
          {/* <div className="industry-block-elem-text-title-bottom">*/}
          {/* Опыт в реализации проектов*/}
          {/* </div>*/}
          {/* </div>*/}

          {/* </div>*/}
          {/* <div className="industry-block-elem-text">*/}
          {/* <div className="industry-block-elem-text-icon">*/}
          {/* <div className="industry-block-elem-icon-item stv-img">*/}
          {/* <div className="new ">*/}
          {/* Новости*/}
          {/* </div>*/}
          {/* <div className="industry-block-elem-icon-item-text">*/}
          {/* <div className="industry-block-elem-icon-item-text-title">*/}

          {/* </div>*/}
          {/* <div className="industry-block-elem-icon-item-text-desc">*/}
          {/* Бюджетного планирования в государственных структурах*/}
          {/* </div>*/}
          {/* </div>*/}
          {/* </div>*/}
          {/* <div className="industry-block-elem-icon-item ecosystem-img">*/}
          {/* <div className="new ">*/}
          {/* Новости*/}
          {/* </div>*/}
          {/* <div className="industry-block-elem-icon-item-text">*/}
          {/* <div className="industry-block-elem-icon-item-text-title">*/}
          {/* ecosystem*/}
          {/* </div>*/}
          {/* <div className="industry-block-elem-icon-item-text-desc">*/}
          {/* Облачная платформа для решения отраслевых задач*/}
          {/* </div>*/}
          {/* </div>*/}
          {/* </div>*/}
          {/* </div>*/}
          {/* </div>*/}
          {/* </div>*/}
        </div>

        <div className="gray-line" />
        <InfoTabs />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Services
