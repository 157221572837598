import React, { Component } from 'react'
import Contacts from 'components/Contacts/Contacts'
import TitleContacts from 'components/TitleBlock/title_contacts'
import Header from 'components/Header/Header'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Contact extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="Контакты компании КРИЗИС ИНТЕГРАТОР"
          keywords="контакты, Москва, КРИЗИС ИНТЕГРАТОР"
          description="Информация о контактах компании КРИЗИС ИНТЕГРАТОР в Москве."
        />
        <Header />
        <TitleContacts />
        <Contacts />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Contact
