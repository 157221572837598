// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-child {
  width: 100%;
  background-color: #2d2d2d;
  color: #848484;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  box-sizing: border-box;
}

.footer-child a {
  color: #848484;
}

.footer-child-copy {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.footer-child-copy-elem {
  display: flex;
  margin: auto;
}

.footer-child-logo {
  display: flex;
  padding: 20px 0px;
}

.footer-child-logo-elem {
  display: flex;
  box-sizing: border-box;
  vertical-align: middle;
}

.footer-child-logo-elem img {
  max-width: 100px;
}

.footer-child-social {
  width: 10%;
  display: inline-block;
  vertical-align: middle;
}

.footer-child-social a {
  display: inline-block;
  width: 50%;
  text-align: center;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/assets/static/style/Footer/FooterChild.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".footer-child {\n  width: 100%;\n  background-color: #2d2d2d;\n  color: #848484;\n  font-size: 14px;\n  line-height: 17px;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.footer-child a {\n  color: #848484;\n}\n\n.footer-child-copy {\n  padding-top: 50px;\n  padding-bottom: 50px;\n  display: flex;\n}\n\n.footer-child-copy-elem {\n  display: flex;\n  margin: auto;\n}\n\n.footer-child-logo {\n  display: flex;\n  padding: 20px 0px;\n}\n\n.footer-child-logo-elem {\n  display: flex;\n  box-sizing: border-box;\n  vertical-align: middle;\n}\n\n.footer-child-logo-elem img {\n  max-width: 100px;\n}\n\n.footer-child-social {\n  width: 10%;\n  display: inline-block;\n  vertical-align: middle;\n}\n\n.footer-child-social a {\n  display: inline-block;\n  width: 50%;\n  text-align: center;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
