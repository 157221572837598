// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
  background-color: #f5f5f5;
}

.tabs-block {
  height: 455px;
  width: calc(60% / 2);
  display: inline-block;
  box-sizing: border-box;
  background-color: #f5f5f5;
  vertical-align: top;
}

.tabs .first-block {
  width: 40%;
}

.tabs .first-tab-desc {
  width: 80%;
}

.tabs-block-elem {
  width: 100%;
  box-sizing: border-box;
  padding-top: 50px;
}

.tabs-block-elem:first-child {
  padding-top: 100px;
}

.tabs-block-elem-icon {
  display: inline-block;
  width: 15%;
  height: auto;
  text-align: center;
  vertical-align: top;
}

.tabs-block-elem-info {
  width: 85%;
  display: inline-block;
  text-align: left;
}

.elem-info-title {
  color: #222222;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  padding-bottom: 15px;
  padding-right: 50px;
}

.elem-info-desc {
  color: #424242;
  font-size: 14px;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/static/style/Info.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,qBAAqB;EACrB,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".tabs {\n  width: 100%;\n  box-sizing: border-box;\n  padding-right: 40px;\n  background-color: #f5f5f5;\n}\n\n.tabs-block {\n  height: 455px;\n  width: calc(60% / 2);\n  display: inline-block;\n  box-sizing: border-box;\n  background-color: #f5f5f5;\n  vertical-align: top;\n}\n\n.tabs .first-block {\n  width: 40%;\n}\n\n.tabs .first-tab-desc {\n  width: 80%;\n}\n\n.tabs-block-elem {\n  width: 100%;\n  box-sizing: border-box;\n  padding-top: 50px;\n}\n\n.tabs-block-elem:first-child {\n  padding-top: 100px;\n}\n\n.tabs-block-elem-icon {\n  display: inline-block;\n  width: 15%;\n  height: auto;\n  text-align: center;\n  vertical-align: top;\n}\n\n.tabs-block-elem-info {\n  width: 85%;\n  display: inline-block;\n  text-align: left;\n}\n\n.elem-info-title {\n  color: #222222;\n  font-size: 18px;\n  font-weight: 300;\n  line-height: 22px;\n  padding-bottom: 15px;\n  padding-right: 50px;\n}\n\n.elem-info-desc {\n  color: #424242;\n  font-size: 14px;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
