import React from 'react'
import { Link, IndexLink } from 'react-router'
import classnames from 'classnames'
import Slider from '../Slider/SliderEcommerce/SliderEcommerce'
import Slide3d from '../Slider/Slider3D/Slider3D'

class Ecommerce extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slide: 'one',
      slideTwo: 'three',
    }
    this.checkVerif = this.checkVerif.bind(this)
    this.checkVerifTwo = this.checkVerifTwo.bind(this)
  }

  checkVerif(value) {
    this.setState({
      slide: value,
    })
  }

  checkVerifTwo(value) {
    this.setState({
      slideTwo: value,
    })
  }

  render() {
    const images = [
      'https://609111.selcdn.ru/ki/static/images/slider-ux/ecommerce/1.jpeg',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/ecommerce/2.jpeg',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/ecommerce/3.jpeg',
    ]
    return (
      <div className="ecommerce-block">
        <Slider />
        <div className="yellow-line" />

        {/* <div className="partner-solution service-ecomm">*/}
        {/*    <div className="transport-security-title">*/}
        {/*        <h2>КИ Партнеры</h2>*/}
        {/*        <span className="about-text-decs">*/}
        {/*            Наша компания тестно взаимодействует и разрабатывает продукты с мировыми лидерами в производстве электроники и комплектующих. Критизис Интегратор является авторизированным партнером компаний Elmo Motion Control, WITTENSTEIN Motion, МЦТС Эльбрус ...*/}
        {/*        </span>*/}
        {/*    </div>*/}
        {/*    <div className="partner-solution-block">*/}
        {/*        <div className="partner-solution-elem">*/}
        {/*            <img src="https://609111.selcdn.ru/ki/static/images/elmo-logo.png" alt="elmo motion control"/>*/}
        {/*            <div className="partner-solution-elem-title">*/}
        {/*                Elmo motion control*/}
        {/*            </div>*/}
        {/*            <div className="partner-solution-elem-decs">*/}
        {/*                Один из лидеров производства высокоточных систем управления двигателями. Используеться как в гражданских так и в ВПК*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="partner-solution-elem">*/}
        {/*            <img src="https://609111.selcdn.ru/ki/static/images/Logo-Wittenstein.png" alt="Wittenstein"/>*/}
        {/*            <div className="partner-solution-elem-title">*/}
        {/*                Wittenstein*/}
        {/*            </div>*/}
        {/*            <div className="partner-solution-elem-decs">*/}
        {/*                Производитель высокоточных сервоприводов.*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="partner-solution-elem">*/}
        {/*            <img src="https://609111.selcdn.ru/ki/static/images/mcts.png" alt="процессоры эльбрус"/>*/}
        {/*            <div className="partner-solution-elem-title">*/}
        {/*                МЦТС Эльбрус*/}
        {/*            </div>*/}
        {/*            <div className="partner-solution-elem-decs">*/}
        {/*                Российский производитель процессоров и комплектующих.*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="partner-solution-elem">*/}
        {/*            <img style={{*/}
        {/*                width: '240px',*/}
        {/*                height: 'auto',*/}
        {/*            }} src="https://609111.selcdn.ru/ki/static/images/resonit.png" alt="резонит"/>*/}
        {/*            <div className="partner-solution-elem-title">*/}
        {/*                Резонит*/}
        {/*            </div>*/}
        {/*            <div className="partner-solution-elem-decs">*/}
        {/*                Резонит является одним из лидеров на рынке печатных плат и контрактного производства в России.*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/* </div>*/}

        <div className="yellow-line" />

        {/* <div className="ecommerce-solution-desc pdn-btm-none">*/}
        {/*    <h2>Создание продукта вместе с нами</h2>*/}

        {/*    <div className="ecosystem-solution-desc-slide back-none">*/}
        {/*        <div className="ecommerce-solution-desc-slide-elem">*/}
        {/*            <div className="ecosystem-solution-desc-slide-elem">*/}
        {/*                <h3>Заказчик отслеживает каждый этап работы над продуктом</h3>*/}
        {/*                <span>- Разработка дорожной карты проекта</span>*/}
        {/*                <span>- Формирование команды</span>*/}
        {/*                <span>- Проектирование архитектуры</span>*/}
        {/*                <span>- Разработка продукта</span>*/}
        {/*                <span>- Тестирование прототипа</span>*/}
        {/*                <span>- Запуск продукта в производство</span>*/}
        {/*                <span>- Поддержка продукта - своих не бросаем</span>*/}
        {/*            </div>*/}
        {/*            <div className="ecosystem-solution-desc-slide-elem">*/}
        {/*                <div className="ecommerce-solution-desc-slide-elem-item">*/}
        {/*                    <img src="https://609111.selcdn.ru/ki/static/images/visual1.png" alt=""/>*/}
        {/*                    <span>Заказчик получает доступ ко всем производственным процессам</span>*/}
        {/*                </div>*/}
        {/*                <div className="ecommerce-solution-desc-slide-elem-item">*/}
        {/*                    <img src="https://609111.selcdn.ru/ki/static/images/doc_ecomm.svg" alt=""/>*/}
        {/*                    <span>Прозрачная работа внутри компании и взаимодейтсвие с партнерами.</span>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <span className="ecomm-slie-img ">*/}
        {/*                <img src="https://609111.selcdn.ru/ki/static/images/chip.png" alt=""/>*/}
        {/*            </span>*/}
        {/*        </div>*/}

        {/*    </div>*/}
        {/* </div>*/}

        <div className="security-solution-desc-up">
          <div className="ts-security-solution-desc-title">
            <div className="ts-security-solution-desc-title-btn">
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  'ts-security-btn-active',
                )}
              >
                УСЛУГИ
              </div>
            </div>
          </div>

          <div className="ecosystem-solution-desc-slide back-none">
            <div>
              <div className="ecosystem-solution-desc-slide-elem-up">
                <h3>ЦИФРОВОЙ КОНСАЛТИНГ ПО ИМПОРТОЗАМЕЩЕНИЮ</h3>
                <div className="ecosystem-solution-desc-slide-elem-body-text">
                  <span>
                    Аудит существующей ИТ-инфраструктуры, анализ
                    бизнес-процессов и потребностей заказчика
                  </span>
                  <span>
                    Разработка стратегии импортозамещения, создание поэтапной
                    дорожной карты перехода на отечественные инструменты
                  </span>
                  <span>
                    Подбор цифровых технологий, наиболее подходящих для решения
                    задач заказчика
                  </span>
                  <span>
                    Построение эффективных алгоритмов работы сервисов и
                    сотрудников с импортозамещающими цифровыми инструментами
                  </span>
                </div>
              </div>

              <div className="ecosystem-solution-desc-slide-elem-up">
                <h3>ИТ-ИНФРАСТРУКТУРА</h3>
                <div className="ecosystem-solution-desc-slide-elem-body-text">
                  <span>
                    Создание ИТ и инженерной инфраструктуры на основе
                    отечественных решений
                  </span>
                  <span>
                    Выполнение проектов в соответствии с требованиями ФСТЭК, ФСБ
                    и Минпромторга
                  </span>
                  <span>
                    Поставка и монтаж оборудования и программного обеспечения
                    российских партнеров
                  </span>
                  <span>
                    Реализация технических решений: ЦОД, офисные пространства и
                    производственные площадки.
                  </span>
                </div>
              </div>

              <div className="ecosystem-solution-desc-slide-elem-up">
                <h3>РАЗРАБОТКА ИМПОРТОЗАМЕЩАЮЩЕГО ПО</h3>
                <div className="ecosystem-solution-desc-slide-elem-body-text">
                  <span>
                    Создание уникальных инструментов для решения задач широкого
                    спектра отраслей экономики
                  </span>
                  <span>
                    Бесшовная интеграция ПО, данных, процессов и сервисов в уже
                    существующую архитектуру заказчика, создание единого
                    информационного пространства
                  </span>
                  <span>
                    Разработка проектов с применением гибкой методологии и
                    open-source технологий Postgres, Kafka, Redis, NiFi, Minio,
                    Drools, RabbitMQ
                  </span>
                  <span>
                    Аутсорсинговая разработка, дополнение команд заказчика
                    специалистами с уникальными компетенциями для решения задач
                    любой сложности
                  </span>
                </div>
              </div>

              <div className="ecosystem-solution-desc-slide-elem-up">
                <h3>ИНФОРМАЦИОННАЯ БЕЗОПАСНОСТЬ</h3>
                <div className="ecosystem-solution-desc-slide-elem-body-text">
                  <span>
                    Проведение аудита информационной безопасности, а также
                    исследований по требованиям ФСТЭК России для определения
                    возможных каналов утечки информации
                  </span>
                  <span>
                    Аттестация объектов информатизации, подготовка и контроль
                    эффективности мер защиты
                  </span>
                  <span>
                    Поставка и внедрение специальных технических средств защиты
                    информации с радиусом зон R2, r1, r1’
                  </span>
                  <span>
                    Защита персональных данных в соответствии с Федеральным
                    законом №152-ФЗ «О персональных данных»
                  </span>
                </div>
              </div>

              <div className="ecosystem-solution-desc-slide-elem-up">
                <h3>ПОДДЕРЖКА И СОПРОВОЖДЕНИЕ</h3>
                <div className="ecosystem-solution-desc-slide-elem-body-text">
                  <span>
                    Обслуживание и обеспечение бесперебойного функционирования
                    систем и непрерывности бизнес-процессов
                  </span>
                  <span>
                    Многоуровневая поддержка пользователей, обучение
                    специалистов заказчика работе с внедренными отечественными
                    решениями
                  </span>
                  <span>
                    Улучшение, оптимизация и доработка программных продуктов,
                    проверка и изменение исходного кода
                  </span>
                  <span>
                    L1, L2, L3 уровень поддержки и современные стандарты
                    оказания услуг: ITIL/ ITSM, ISO 20000.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ecommerce
