import React from 'react'
import { Link, IndexLink } from 'react-router'
import ReactDOM from 'react-dom'
import EventListener, { withOptions } from 'react-event-listener'
// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class SliderAnalytics extends React.Component {
  render() {
    return (
      <div>
        <div className="solutions-slider-top-block">
          <div className="solution-text">
            {/* <span>Выявление ассоциативных правил в массиве данных</span>*/}
            <span>Возможности</span>
            <span className="about-text-decs">
              КРИЗИС ИНТЕГРАТОР (Аналитика) – система обработки и анализа
              бизнес-данных, построения прогнозных моделей для формирования
              финансовых стратегий в режиме реального времени и принятия
              корректных управленческих решений в области бюджетирования и
              финансового планирования
            </span>
          </div>
          <div className="slider-team solution-slider">
            {/* <EventListener target={document} onClick={(event) => this.sliders(event)}/>*/}

            <Carousel interval={3000} showThumbs={false}>
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon1@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Повышение эффективности планирования путем формирования
                      прогнозных KPI’s и реализации «что-если»-анализа
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon2@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Обработка больших массивов данных из разнородных
                      источников с целью дальнейшего моделирования в режиме
                      реального времени
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon3@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Прогнозирование большого количества временных рядов за
                      короткое время с небольшим количеством наблюдений и
                      неполными данными
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon4.png"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Обновление модели при поступлении новых данных.
                      Корректировка прогноза с учетом влияния внешних факторов
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon5@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title"> </div>
                    <div className="partner-solution-elem-decs">
                      Рассмотрение моделей с выбором наилучшей на основе
                      различных критериев качества и обобщающей способности
                      модели
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon6@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Статистическая оценка влияния определённых финансовых и
                      экономических показателей на KPI’s (эконометрическое
                      моделирование)
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon7@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Мгновенный доступ к данным прогнозного моделирования через
                      КРИЗИС ИНТЕГРАТОР - Представление
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon8@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      100% российская разработка для использования в
                      государственном секторе
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default SliderAnalytics
