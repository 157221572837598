import React from 'react'
import { Link, IndexLink } from 'react-router'
import ReactDOM from 'react-dom'
import EventListener, { withOptions } from 'react-event-listener'
// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class SliderPerformance extends React.Component {
  render() {
    return (
      <div>
        <div className="solutions-slider-top-block">
          <div className="solution-text">
            <span>Возможности</span>
            <span className="about-text-decs">
              КРИЗИС ИНТЕГРАТОР (Представление) – мультиплатформенная система
              генерации и визуального представления аналитической отчетности на
              базе данных, полученных из разнородных источников, включая
              хранилища данных, приложения, файлы
            </span>
          </div>
          <div className="slider-team solution-slider">
            <Carousel interval={3000} showThumbs={false}>
              {/* <div>*/}
              {/* <div className="">*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_48.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">Пользовательские интерфейсы спроектированы в соответствии с мировыми тенденциями User eXperience (UX) с целью максимального удобства работы с приложением</div>*/}
              {/* </div>*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_49.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">Высокая скорость построения сложных, высоконагруженных отчетов с неограниченным количеством аналитик</div>*/}
              {/* </div>*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_50.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">Широкий функционал панели администрирования для быстрой и гибкой работы по настройке необходимых отчетных форм и представления бизнес-информации</div>*/}
              {/* </div>*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_51.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">Работа на стационарных компьютерах и планшетах. Единый интерфейс на всех устройствах</div>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* <div>*/}
              {/* <div className="">*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_48.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">Бесшовная интеграция с источниками данных с использованием конструктора запросов</div>*/}
              {/* </div>*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_49.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">Современная web архитектура приложения</div>*/}
              {/* </div>*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_50.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">Мгновенный доступ к данным прогнозного моделирования, полученных из КРИЗИС ИНТЕГРАТОР analytics (STP)</div>*/}
              {/* </div>*/}
              {/* <div className="partner-solution-elem">*/}
              {/* <img src="https://609111.selcdn.ru/ki/static/images/Group_51.svg" alt=""/>*/}
              {/* <div className="partner-solution-elem-title"></div>*/}
              {/* <div className="partner-solution-elem-decs">100% российская разработка для использования в государственном секторе</div>*/}
              {/* </div>*/}
              {/* </div>*/}
              {/* </div>*/}
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 57@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Высокая скорость построения сложных, высоконагруженных
                      отчетов с неограниченным количеством аналитик
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/group-35.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Широкий функционал панели администрирования для быстрой и
                      гибкой работы по настройке необходимых отчетных форм и
                      представления бизнес-информации
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/devices@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Работа на стационарных компьютерах и планшетах. Единый
                      интерфейс на всех устройствах
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 542x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Бесшовная интеграция с источниками данных с использованием
                      конструктора запросов
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 56@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Современная web-архитектура приложения
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon7@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Мгновенный доступ к данным прогнозного моделирования,
                      полученным из КРИЗИС ИНТЕГРАТОР
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 1.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Пользовательские интерфейсы спроектированы в соответствии
                      с мировыми тенденциями User eXperience (UX) с целью
                      максимального удобства работы с приложением
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon8@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      100% российская разработка для использования в
                      государственном секторе
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default SliderPerformance
