import React, { Component } from 'react'
import Header from 'components/Header/Header'
import TitlePartners from 'components/TitleBlock/title_partners'
import Partner from 'components/Partners/Partner'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Partners extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="Партнерство – КРИЗИС ИНТЕГРАТОР"
          keywords="партнерство, сотрудничество, КРИЗИС ИНТЕГРАТОР, документы"
          description="Компания КРИЗИС ИНТЕГРАТОР открыта для предложений о сотрудничестве. Документы. Партнеры: ПромБизнес Софт, Кредо Консалтинг, КРИЗИС ИНТЕГРАТОР."
        />
        <Header />
        <TitlePartners />
        <Partner />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Partners
