import React from 'react'

class TitlePartners extends React.Component {
  render() {
    return (
      <div>
        <div className="title-block title-partner-block">
          <div className="title-block-text">
            <h1>Партнерство</h1>
            <span>
              Наши партнеры — это консалтинговые компании,
              компании-разработчики, системные интеграторы, которые находятся в
              непосредственном контакте с конечными потребителями наших
              продуктов
            </span>
            <div className="partner-button" onClick={() => modalStore.open()}>
              Стать партнером
            </div>
          </div>
        </div>
        <div className="yellow-line" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TitlePartners
