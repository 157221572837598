import React from 'react'
import { Link } from 'react-router'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowingModalAuth: false,
    }
  }

  handleClickAuth = () => this.setState({ isShowingModalAuth: true })
  handleCloseAuth = () => this.setState({ isShowingModalAuth: false })

  render() {
    return (
      <div>
        <div className="header" id="top">
          <div className="header-block">
            <Link className="logo" to="/">
              <img
                style={{ height: '29px' }}
                src="https://609111.selcdn.ru/ki/static/images/logo/logo_new.png"
                alt="logo"
              />
            </Link>
            <nav className="nav">
              <ul className="nav-block">
                <li className="nav-elem toggle-btn">
                  <img
                    className="nav-elem-menu-icon"
                    src="https://609111.selcdn.ru/ki/static/images/menu.svg"
                    alt="icon"
                  />
                  Решения
                  <div className="toggle-menu">
                    <ul>
                      <li className="header-performance">
                        <Link to="/performance">КИ Представление данных</Link>
                      </li>
                      <li className="header-analytics">
                        <Link to="/analytics">КИ Аналитика</Link>
                      </li>
                      <li className="header-data-control">
                        <Link to="/data-control">КИ Управление данными</Link>
                      </li>
                      <li className="header-ts-security">
                        <Link to="/transport-security">
                          КИ Транспортная безопасность
                        </Link>
                      </li>
                      <li className="header-ecosystem">
                        <Link to="/ecosystem">КИ Экосистема</Link>
                      </li>
                      <li className="header-security">
                        <Link to="/security">КИ Безопасность</Link>
                      </li>
                      <li className="header-e-comm">
                        <Link to="/automation">КИ Импортозамещение</Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-elem">
                  <Link to="/services">Услуги</Link>
                </li>

                <li className="nav-elem">
                  <Link to="/industry">Отрасли</Link>
                </li>

                <li className="nav-elem">
                  <Link to="/about">О компании</Link>
                </li>

                <li className="nav-elem">
                  <Link to="/partners">Партнерство</Link>
                </li>
                <li className="nav-elem">
                  <Link to="/contacts">Контакты</Link>
                </li>
              </ul>
            </nav>
            <div className="user">
              <div onClick={() => modalStore.openLogin()} className="auth">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/user.svg"
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
