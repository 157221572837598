import React from 'react'
import { Link, IndexLink } from 'react-router'
import SliderTeam from '../Slider/SliderTeam'
import classnames from 'classnames'
import SliderComment from '../Slider/SliderComments'
import Slide3d from '../Slider/Slider3D/Slider3D'

const data = [
  {
    year: '2019',
    description: [
      'Создание КРИЗИС ИНТЕГРАТОР',
      'Начало внедрения решений КРИЗИС ИНТЕГРАТОР в области электронной коммерции (b2b. b2c)',
      'Тестирование решений в инфраструктуре Alfa Group',
      'Начало партнерства со Сбербанк',
      'Запуск платформы обучения Деловая Среда',
      'Начало партнерсва с Google Rus',
      'Запуск платформы Бизнесс Класс в сотрудничестве с Google',
    ],
  },
  {
    year: '2020',
    description: [
      'Запуск платформы для TV провайдера в Саудовской Аравии',
      'Разработка  платформы для компании Северсталь',
      'Реализация платнформы конструирования форумов Peerboard',
      'Начала сотрудничества с Департаментом информационных технологий города Москвы',
      'Запус проекта Московская электронная школа (МЭШ) https://school.mos.ru/',
    ],
  },
  {
    year: '2021',
    description: [
      'Запуск платформы федерального значения Российская Электронная Школа (РЭШ)',
      'Разработка системы анализа и генерации отчетности КИ Отчеты  - Представление по программе импортозамещения',
      'Реализация проектов по внедрению системы отчетновсти в федеральные органы власти',
      'Старт разработки КИ Безопасность – системы комплексной безопасности объектов на базе компьютерного зрения, и нейронных сетей',
    ],
  },
  {
    year: '2022',
    description: [
      'Запуск проект LogDoc - платформа логирования в рамках поддержки и ипортозамещения програмного обеспечения недружественных стран',
      'Внедрение LogDoc платформы в инфратсруктуры Alfa Group, Сбербанк, Северталь и другие',
      'Старт разработки интеллектуальной системы транспортной безопасности КИ Транспортная безопасность',
    ],
  },
]

class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: 'one',
      storyElems: {
        left: '2020',
        center: '2021',
        right: '2022§',
      },
      partners: '',
    }
    console.log(this.state)
    this.checkVerif = this.checkVerif.bind(this)
    this.checkPartner = this.checkPartner.bind(this)
    this.renderStoryElemsDescription =
      this.renderStoryElemsDescription.bind(this)
    this.renderStoryElems = this.renderStoryElems.bind(this)
    this.widthElem = this.widthElem.bind(this)
    this.changeStory = this.changeStory.bind(this)
  }

  checkVerif(value) {
    this.setState({
      open: value,
    })

    if (this.state.open === value) {
      this.setState({
        open: ' ',
      })
    }
  }

  checkPartner(value) {
    this.setState({
      partners: value,
    })

    if (this.state.partners === value) {
      this.setState({
        partners: ' ',
      })
    }
  }

  widthElem(value) {
    if (this.state.storyElems.center === value) {
      return '28%'
    }
    return '24%'
  }

  widthStoryElem(value) {
    if (
      this.state.storyElems.center === value ||
      this.state.storyElems.left === value ||
      this.state.storyElems.right === value
    ) {
      return '25%'
    }
    return '0%'
  }

  widthStoryElem(value) {
    if (
      this.state.storyElems.center === value ||
      this.state.storyElems.left === value ||
      this.state.storyElems.right === value
    ) {
      return 'inline-block'
    }
    return 'none'
  }

  changeStory(year) {
    const that = this

    let right
    let left

    data.map((index, i, arr) => {
      if (year === index.year) {
        if (arr[i + 1] !== undefined) {
          right = arr[i + 1].year
          if (arr[i - 1] !== undefined) {
            left = arr[i - 1].year
          } else {
            left = arr[i + 1].year
            right = arr[i + 2].year
          }
        } else {
          right = arr[i - 1].year

          left = arr[i - 2].year
        }

        that.setState({
          storyElems: {
            left,
            center: year,
            right,
          },
        })
      }
    })
  }

  renderStoryElems() {
    const that = this

    return data.map((index, i, arr) => (
      <div
        key={i}
        className="about-story-elem"
        style={{ width: that.widthElem(index.year) }}
      >
        {index.year === that.state.storyElems.center ? (
          <div className="about-story-elem-data active">{index.year}</div>
        ) : (
          <div
            className="about-story-elem-data"
            onClick={() => that.changeStory(index.year)}
          >
            {index.year}
          </div>
        )}

        {index.year === that.state.storyElems.center ? (
          <div className="about-story-elem-line active" />
        ) : (
          <div className="about-story-elem-line" />
        )}

        <div className="about-story-elem-background-data">{index.year}</div>
      </div>
    ))
  }

  renderStoryElemsDescription() {
    const that = this

    const year = 0
    data.sort()

    return data.map((index, i, arr) => (
      <div
        key={i}
        className="about-story-elem-description"
        style={{ display: that.widthStoryElem(index.year) }}
      >
        {index.description.map((indexy, iy) => {
          if (that.state.storyElems.left === index.year) {
            return (
              <div key={iy} className="block-stoty  ">
                <div className="about-story-elem-description-elem opanity">
                  {indexy}
                </div>
              </div>
            )
          }
          if (that.state.storyElems.center === index.year) {
            return (
              <div key={iy} className="block-stoty">
                {/* <div className="about-story-elem-description-big-bg">*/}
                {/* {index.year}*/}
                {/* </div>*/}
                <div className="about-story-elem-description-elem">
                  {indexy}
                </div>
              </div>
            )
          }

          if (that.state.storyElems.right === index.year) {
            return (
              <div key={iy} className="block-stoty  ">
                {/* <div className="about-story-elem-description-big-bg opanity">*/}
                {/* {index.year}*/}
                {/* </div>*/}

                <div className="about-story-elem-description-elem opanity">
                  {indexy}
                </div>
              </div>
            )
          }
          return null
        })}
        <div className="clear" />
      </div>
    ))
  }

  render() {
    const images = [
      '/static/images/sert/1.png',
      '/static/images/sert/2.png',
      '/static/images/sert/3.jpg',
      '/static/images/sert/4.jpg',
      '/static/images/sert/5.jpg',
      '/static/images/sert/6.jpg',
      '/static/images/sert/7.jpg',
    ]

    return (
      <div>
        <div className="about-page">
          {/* <div className="about-info">*/}
          {/* <div className="about-text-title">*/}
          {/* <div className="first-tab-title">Решения</div>*/}
          {/* <span>Доступность и простота</span>*/}
          {/* </div>*/}
          {/* <div className="about-info-block">*/}
          {/* <div className="about-info-block-elem">*/}
          {/* <span className="about-info-block-elem-text">*/}
          {/* Мы создаем системы, позволяющие моментально, «по требованию»*/}
          {/* строить прогнозные модели на базе архивных и текущих данных компаний,*/}
          {/* и предлагаем инструменты для реализации новых стратегий в области маркетинга и продаж.*/}
          {/* </span>*/}
          {/* <span className="about-info-block-elem-text">*/}
          {/* Наши решения КРИЗИС ИНТЕГРАТОР Управление данными, КРИЗИС ИНТЕГРАТОР  - Представление, КРИЗИС ИНТЕГРАТОР Аналитика*/}
          {/* обеспечивают полный функционал для работы с большими и сверхбольшими объемами информации,*/}
          {/* позволяют строить и визуально представлять аналитические модели любой сложности в режиме реального времени.*/}
          {/* </span>*/}
          {/* </div>*/}
          {/* <div className="about-info-block-elem">*/}
          {/* <span className="about-info-block-elem-text">*/}
          {/* Продукты КРИЗИС ИНТЕГРАТОР дают новые возможности для компаний, ориентированных на расширение своего присутствия в*/}
          {/* сети Интернет и позволяют в разы повысить продажи товаров и услуг он-лайн за счет предоставления средств*/}
          {/* отслеживания и анализа действий клиентов, их покупательской активности, предпочтений на протяжении всего*/}
          {/* цикла совершения ими сделок.*/}
          {/* </span>*/}
          {/* <span className="about-info-block-elem-text">*/}
          {/* Благодаря решениям КРИЗИС ИНТЕГРАТОР и заложенным в них алгоритмам стало возможным предсказывать поведение*/}
          {/* покупателей, вычислять «правильные экстремумы» вдоль их жизненного цикла и предлагать релевантные товары*/}
          {/* и сервисы в точках максимального спроса.*/}
          {/* </span>*/}
          {/* </div>*/}
          {/* </div>*/}
          {/* </div>*/}
          <div className="about-mission">
            <div className="about-mission-title">
              <h2>Миссия компании</h2>
              <div>
                <span>
                  Сложный, быстро меняющийся мир предлагает новые возможности
                  для людей и компаний, и в то же время устанавливает барьеры и
                  формирует риски на пути к их реализации.
                </span>
                <span>
                  КРИЗИС ИНТЕГРАТОР разрабатывает инновационные решения для
                  созидательной деятельности людей и организаций с
                  использованием цифровых технологий, направленной на личностную
                  самореализацию, творчество, рост качества жизни и безопасность
                  общества в России и мире. Мы устраняем барьеры и создаем
                  возможности.
                </span>
              </div>
            </div>
            <div className="about-mission-type">
              {/* <div className="about-mission-type-title">*/}
              {/* В основе нашей работы лежат следующие принципы:*/}
              {/* </div>*/}
              <div className="about-mission-type-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-29.svg"
                  alt=""
                />
                <div className="about-mission-type-elem-text">
                  <div className="about-mission-type-elem-text-title">
                    Снижение барьеров для предпринимательской деятельности
                  </div>
                  <div className="about-mission-type-elem-text-desc">
                    Технологии должны снижать барьеры для предпринимательства
                  </div>
                </div>
              </div>
              <div className="about-mission-type-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-33.svg"
                  alt=""
                />
                <div className="about-mission-type-elem-text">
                  <div className="about-mission-type-elem-text-title">
                    Искусственный интеллект
                  </div>
                  <div className="about-mission-type-elem-text-desc">
                    Умные системы должны стать неотъемлемой частью жизни и
                    служить людям
                  </div>
                </div>
              </div>
              <div className="about-mission-type-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-32.svg"
                  alt=""
                />
                <div className="about-mission-type-elem-text">
                  <div className="about-mission-type-elem-text-title">
                    Безопасность жизни
                  </div>
                  <div className="about-mission-type-elem-text-desc">
                    Технологии должны служить делу сохранения самого ценного –
                    жизни
                  </div>
                </div>
              </div>
              <div className="about-mission-type-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-34.svg"
                  alt=""
                />
                <div className="about-mission-type-elem-text">
                  <div className="about-mission-type-elem-text-title">
                    Когнитивные платформы
                  </div>
                  <div className="about-mission-type-elem-text-desc">
                    Цифровые интеллектуальные платформы с открытым кодом –
                    основа решения задач любого уровня сложности
                  </div>
                </div>
              </div>
              <div className="about-mission-type-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-35.svg"
                  alt=""
                />
                <div className="about-mission-type-elem-text">
                  <div className="about-mission-type-elem-text-title">
                    Доступность и открытость технологий
                  </div>
                  <div className="about-mission-type-elem-text-desc">
                    Каждый человек должен иметь доступ к технологиям для
                    самореализации и творчества
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="yellow-line" />
          <div className="about-story">
            <div className="about-text-title">
              <span>История КРИЗИС ИНТЕГРАТОР</span>
            </div>
            <div className="about-story-elems">
              {this.renderStoryElems()}
              {this.renderStoryElemsDescription()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default About
