import React, { Component } from 'react'
import Slider from 'components/Slider/Slider'
import Header from 'components/Header/Header'
import InfoTabs from 'components/InfoTab/InfoTabs'
import InfoTabsLink from 'components/InfoTab/InfoTabsLink'
import Info from 'components/InfoTab/Info'
import CallBack from 'components/Callback/CallBack'
import CallBackTwo from 'components/Callback/CallBackTwo'
import Footer from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Appp extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="КРИЗИС ИНТЕГРАТОР - разработка программного обеспечения: разработка и сопровождение Российского ПО"
          keywords="разработка, программное обеспечение, ПО, Москва, Россия, инновации, технологии, КРИЗИС ИНТЕГРАТОР"
          description="Услуги по разработке инновационных решений на базе цифровых технологий: разработка и сопровождение бизнес-приложений класса BI / ERP, платформ сбора, анализа и хранения больших данных, E-commerce решений, интеллектуальных систем. безопасности"
        />
        <Header />
        <Slider />
        <InfoTabs />
        <Info />
        <InfoTabsLink />
        <CallBackTwo />
        <Footer />
      </div>
    )
  }
}

export default Appp
