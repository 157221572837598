import React, { Component } from 'react'
import TsSecurityBlock from 'components/Solutions/TsScurity'
import TitleTransportSecurity from 'components/TitleBlock/title_transport_security'
import Header from 'components/Header/Header'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class TransportSecurity extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="КРИЗИС ИНТЕГРАТОР (Транспортная безопасность) – Программное обеспечение транспортной безопасности на базе нейротехнологий, машинного обучения и геопозиционирования"
          keywords="Транспортная безопасность, система безопасности, безопасность на дороге, разработка, Москва, Россия, КРИЗИС ИНТЕГРАТОР"
          description="Программное обеспечение КРИЗИС ИНТЕГРАТОР (Транспортная безопасность) позволяет осуществлять мониторинг, контроль, прогнозирование и предупреждение инцидентов на транспорте."
        />
        <Header />
        <TitleTransportSecurity />
        <TsSecurityBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default TransportSecurity
