import React from 'react'
import ReactDOM from 'react-dom'
import { Router, browserHistory } from 'react-router'
import Routes from 'app/Router'
import {} from '@assets/static/style/style.css'

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log('Looks like we are in development mode!')
}

const root = document.getElementById('root')
ReactDOM.render(
  <Router
    routes={Routes}
    history={browserHistory}
    onUpdate={() => window.scrollTo(0, 0)}
  />,
  root,
)
