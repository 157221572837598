import React from 'react'
import { Link } from 'react-router'
import SliderTop from 'components/Slider/SliderPerformance/SliderPerformance'
import Slide3d from 'components/Slider/Slider3D/Slider3D'

class Performance extends React.Component {
  render() {
    const images = [
      'https://609111.selcdn.ru/ki/static/images/slider-ux/stv/1.1.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/stv/2.1.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/stv/3.1.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/stv/6.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/stv/7.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/stv/14.png',
      'https://609111.selcdn.ru/ki/static/images/slider-ux/stv/17.png',
    ]
    return (
      <div>
        <SliderTop />
        <Slide3d
          clas="Performance ux-slide"
          type="not"
          image={images}
          titleGray="КРИЗИС ИНТЕГРАТОР  - Представление"
          title="Интерфейс"
          description="Система позволяет вводить, сортировать, фильтровать, агрегировать и дезагрегировать данные в любых разрезах"
        />

        <div className="work-complex-block performance-complex">
          <h2>Функциональная архитектура платформы КРИЗИС ИНТЕГРАТОР</h2>
          <div className="work-complex-block-top">
            <div className="work-complex-logo">
              КРИЗИС ИНТЕГРАТОР (Представление)
              {/* <img src="https://609111.selcdn.ru/ki/static/images/performance_logo.png" alt="" className="work-complex-logo-item"/>*/}
            </div>
            <div className="work-complex-info">
              <div className="work-complex-info-block w-one">
                <div className="work-complex-info-elem-item w-tree">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/visual1.png"
                    alt=""
                  />
                  <span>
                    Представление аналитических данных в виде таблиц и широкого
                    набора типов графиков и диаграмм
                  </span>
                </div>
                <div className="work-complex-info-elem-item w-tree">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/visual2.png"
                    alt=""
                  />
                  <span>
                    Привязка аналитических данных к регионам РФ с визуализацией
                    на карте РФ
                  </span>
                </div>
                <div className="work-complex-info-elem-item w-tree">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/visual3.png"
                    alt=""
                  />
                  <span>
                    Набор интерфейсов пользователя и навигация, реализованная на
                    базе современных подходов UX/UI
                  </span>
                </div>
              </div>
              <div className="work-complex-info-block w-one">
                <div className="work-complex-info-elem-item w-tree">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/visual4.png"
                    alt=""
                  />
                  <span>
                    Система многоуровневой фильтрации, гибкая настройка
                    необходимых фильтров без програмирования
                  </span>
                </div>
                <div className="work-complex-info-elem-item w-tree">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/visual5.png"
                    alt=""
                  />
                  <span>
                    Выгрузка в PDF отчет любых аналитических разрезов системы
                  </span>
                </div>
                <div className="work-complex-info-elem-item w-tree">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/users_logo.svg"
                    alt=""
                  />
                  <span>
                    Одновременная работа более 2500 пользователей в
                    территориально-распределенной структуре
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="work-complex-pointer-top">
            <div className="work-complex-pointer-top-left">
              <img
                src="https://609111.selcdn.ru/ki/static/images/Group_30(копия).svg"
                alt=""
              />
              <span>Данные для построения аналитики</span>
            </div>
            <div className="work-complex-pointer-top-right">
              <img
                className="revert180"
                src="https://609111.selcdn.ru/ki/static/images/Group_30(копия).svg"
                alt=""
              />
              <span>Прогнозные данные</span>
            </div>
          </div>
          <div className="work-complex-block-bottom">
            <div className="complex-block-bottom-left">
              <div className="work-complex-logo">
                КРИЗИС ИНТЕГРАТОР (Управление данными)
                <Link to="/data-control">
                  <div className="first-tab-link">
                    Подробнее <span className="infotabs-block-text-link-icon" />
                  </div>
                </Link>
              </div>
              <div className="work-complex-info">
                <div className="work-complex-info-block w-one">
                  <div className="work-complex-info-elem-item w-two">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/visual7.png"
                      alt=""
                    />
                    <span>Анализ и контроль расходов</span>
                  </div>
                  <div className="work-complex-info-elem-item w-two">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/visual8.png"
                      alt=""
                    />
                    <span>Планирование и контроль доходов</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="complex-block-bottom-right">
              <div className="work-complex-logo">
                КРИЗИС ИНТЕГРАТОР (Аналитика)
                <Link to="/analytics">
                  <div className="first-tab-link">
                    Подробнее <span className="infotabs-block-text-link-icon" />
                  </div>
                </Link>
              </div>
              <div className="work-complex-info">
                <div className="work-complex-info-block w-one">
                  <div className="work-complex-info-elem-item w-one">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/visual9.png"
                      alt=""
                    />
                    <span>
                      Применение к данным моделей прогнозирования доходов и
                      расходов
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="work-complex-pointer-bottom">
            <div className="work-complex-pointer-bottom-block">
              <span className="text-arrow-one">
                Данные для построения прогноза
              </span>
              <img
                className="arrow-one"
                src="https://609111.selcdn.ru/ki/static/images/arrow_one.svg"
                alt=""
              />
              <span className="text-arrow-two">Прогнозные данные</span>
              <img
                className="arrow-two"
                src="https://609111.selcdn.ru/ki/static/images/arrow_two.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Performance
