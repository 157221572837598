import React from 'react'
import { Link, IndexLink } from 'react-router'
import ReactDOM from 'react-dom'
import EventListener, { withOptions } from 'react-event-listener'
// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class SliderTeam extends React.Component {
  render() {
    return (
      <div className="slider-team">
        {/* <EventListener target={document} onClick={(event) => this.sliders(event)}/>*/}

        <Carousel interval={3000} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default SliderTeam
