import React, { Component } from 'react'
import NotFoundBlock from 'components/Page404/NotFound'
import Header from 'components/Header/Header'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class NotFound extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="Страница не найдена"
          keywords="Страница не найдена"
          description="Страница не найдена"
        />
        <Header />
        <NotFoundBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default NotFound
