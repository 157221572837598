import React, { Component } from 'react'
import Header from 'components/Header/Header'
import TitleServices from 'components/TitleBlock/title_services'
import ServicesBlock from 'components/Services/Services'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Services extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="Услуги компании КРИЗИС ИНТЕГРАТОР"
          keywords="услуги, консультации, разработка, внедрение, сопровождение, КРИЗИС ИНТЕГРАТОР, Москва, Россия"
          description="Компания КРИЗИС ИНТЕГРАТОР консультирует в области цифровых технологий, разрабатывает программное обеспечение, создает, внедряет и сопровождает системы."
        />
        <Header />
        <TitleServices />
        <ServicesBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Services
