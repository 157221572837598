import React from 'react'
import { Link, IndexLink } from 'react-router'

class FooterChild extends React.Component {
  render() {
    return (
      <div>
        <div className="footer-child">
          {/* <div className="footer-child-social">*/}
          {/*    <a href="https://www.facebook.com/КРИЗИС ИНТЕГРАТОР.ru/"  target="_blank" className="footer-child-social-fb">*/}
          {/*        <img src="https://609111.selcdn.ru/ki/static/images/fb-social.svg" alt=""/>*/}
          {/*    </a>*/}
          {/*    <a href="https://twitter.com/КРИЗИС ИНТЕГРАТОР_RU" target="_blank" className="footer-child-social-tw">*/}
          {/*        <img src="https://609111.selcdn.ru/ki/static/images/twitter-social.svg" alt=""/>*/}
          {/*    </a>*/}
          {/* </div>*/}
          <div
            itemScope
            itemType="http://schema.org/ContactPoint"
            className="footer-child-copy"
          >
            <span className="footer-child-copy-elem">
              © Copyright 2022 КРИЗИС ИНТЕГРАТОР. Все права защищены.{' '}
            </span>
            <span itemProp="email" className="footer-child-copy-elem">
              <a href="mailto:office@crisis-integrator.ru">
                office@crisis-integrator.ru
              </a>
            </span>
            {/* <span itemProp="telephone" className="footer-child-copy-elem"><a href='tel:+7 999 99 99'> +7 999 99 99</a> </span>*/}
          </div>
          <div className="footer-child-logo">
            <noindex style={{ margin: 'auto' }}>
              {/* <div className="footer-child-logo-elem">*/}
              {/*    <Link to="http://aoglonass.ru/" target="_blank">*/}
              {/*        <img src="https://609111.selcdn.ru/ki/static/images/logo/Bitmap_logo_gray.png" alt="АО ГЛОНАСС"/>*/}
              {/*    </Link>*/}
              {/* </div>*/}
              {/* <div className="footer-child-logo-elem">*/}
              {/*    <Link to="http://rusneuro.net/" target="_blank">*/}
              {/*        <img src="https://609111.selcdn.ru/ki/static/images/logo/Neuronet_logo.png" alt="НЕЙРОНЕТ"/>*/}
              {/*    </Link>*/}
              {/* </div>*/}
              <div className="footer-child-logo-elem">
                <Link to="http://sk.ru/news/" target="_blank">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/logo/skolkovo_logo_gray.png"
                    alt="Сколково"
                  />
                </Link>
              </div>
            </noindex>
          </div>
        </div>
      </div>
    )
  }
}

export default FooterChild
