import React from 'react'

class TitleServices extends React.Component {
  render() {
    return (
      <div>
        <div className="title-block title-services">
          <div className="title-block-text">
            <h2> </h2>
            <h1>Услуги</h1>
            <span>
              Мы консультируем в области цифровых технологий. Разрабатываем
              программное обеспечение. Создаем, внедряем и сопровождаем системы
            </span>
          </div>
        </div>
        <div className="yellow-line" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TitleServices
