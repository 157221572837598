import React from 'react'
import { Link, IndexLink } from 'react-router'
import InfoTabs from '../InfoTab/InfoTabs'
import Slider from '../Slider/SliderPartners'

class Partner extends React.Component {
  render() {
    return (
      <div>
        <div className="partner-slider">
          <Slider />
        </div>
        <div className="partner-solution">
          <div className="partner-text-title">
            Преимущества работы с компанией КРИЗИС ИНТЕГРАТОР
          </div>
          <div className="partner-solution-block">
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/development.svg"
                alt="icon"
              />
              <div className="partner-solution-elem-title">Развитие</div>
              <div className="partner-solution-elem-decs">
                Мы постоянно работаем над развитием и усовершенствованием своих
                продуктов
              </div>
            </div>
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/partner_sale.svg"
                alt="icon"
              />
              <div className="partner-solution-elem-title">
                Партнерские скидки
              </div>
              <div className="partner-solution-elem-decs">
                Мы предоставляем партнерские скидки, уровень которых
                определяется статусом партнера
              </div>
            </div>
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/promotion.svg"
                alt="icon"
              />
              <div className="partner-solution-elem-title">Продвижение</div>
              <div className="partner-solution-elem-decs">
                Наши партнеры получают все необходимые материалы для
                демонстрации преимуществ и возможностей работы с КРИЗИС
                ИНТЕГРАТОР
              </div>
            </div>
            <div className="partner-solution-elem">
              <img
                src="https://609111.selcdn.ru/ki/static/images/support.svg"
                alt="icon"
              />
              <div className="partner-solution-elem-title">Поддержка</div>
              <div className="partner-solution-elem-decs">
                Компания КРИЗИС ИНТЕГРАТОР оказывает экспертную, консалтинговую
                и техническую поддержку решений КРИЗИС ИНТЕГРАТОР
              </div>
            </div>
          </div>
        </div>
        <div className="gray-line" />
        <InfoTabs />
        <div className="yellow-line" />
        {/* <div className="partner-block-doc">*/}
        {/*    <div className="partner-text-title">Документы</div>*/}
        {/*    <div className="partner-block-doc-block">*/}
        {/*        <a href="/static/download/po?name=КРИЗИС ИНТЕГРАТОР_Двустороннее соглашение о конфиденциальности_21.08.2017.docx" className="partner-block-doc-elem">*/}
        {/*            <img src="https://609111.selcdn.ru/ki/static/images/pdf.svg" alt="icon"/>*/}
        {/*            <div className="partner-block-doc-elem-text">*/}
        {/*                <div className="block-doc-elem-title">Соглашение о неразглашении информации</div>*/}
        {/*                <div className="block-doc-elem-decs">pdf, 163 kb</div>*/}
        {/*            </div>*/}
        {/*        </a>*/}
        {/*        <a href="/static/download/po?name=КРИЗИС ИНТЕГРАТОР_Тёрмшит для партнеров_21.08.2017.docx" className="partner-block-doc-elem">*/}
        {/*            <img src="https://609111.selcdn.ru/ki/static/images/pdf.svg" alt="icon"/>*/}
        {/*            <div className="partner-block-doc-elem-text">*/}
        {/*                <div className="block-doc-elem-title">Предварительное соглашение об условиях сотрудничества</div>*/}
        {/*                <div className="block-doc-elem-decs">pdf, 181 kb</div>*/}
        {/*            </div>*/}
        {/*        </a>*/}
        {/*    </div>*/}
        {/* </div>*/}
        {/* <div className="partner-block-logo">*/}
        {/*    <div className="partner-text-title">Партнеры</div>*/}
        {/*    <div className="partner-block-logo-block">*/}
        {/*       <noindex>*/}
        {/*        <div className="partner-block-logo-elem">*/}
        {/*            <Link to="http://logdoc.org/" target="_blank"><img src="https://609111.selcdn.ru/ki/static/images/logo/logo_prom.png" alt="icon"/></Link>*/}
        {/*        </div>*/}
        {/*        <div className="partner-block-logo-elem">*/}
        {/*            <Link to="http://credoconsulting.ru/" target="_blank"><img src="https://609111.selcdn.ru/ki/static/images/logo/lodo_credo.png" alt="icon"/></Link>*/}
        {/*        </div>*/}
        {/*        <div className="partner-block-logo-elem">*/}
        {/*            <Link to="https://www.skyfinancing.ru/" target="_blank"><img src="https://609111.selcdn.ru/ki/static/images/logo/logo_skyfinance.png" alt="icon"/></Link>*/}
        {/*        </div>*/}
        {/*       </noindex>*/}
        {/*    </div>*/}
        {/* </div>*/}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Partner
