import React from 'react'
import Carousel3D from 'react-3d-carousel'
import classnames from 'classnames'
import EventListener from 'react-event-listener'

class Slide3d extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      images: this.props.image,
      width: 150,
      layout: 'classic',
      ease: 'linear',
      duration: 400,
      sliderValue: 1,
      renderValue: 1,
      isShowingModalSert: false,
      sertImg: '',
    }
    this.dotClick = this.dotClick.bind(this)
    this.triggerEvent = this.triggerEvent.bind(this)
    this.renderType = this.renderType.bind(this)
    this.renderDescription = this.renderDescription.bind(this)
  }

  handleClick = () => this.setState({ isShowingModalSert: true })
  handleClose = () => this.setState({ isShowingModalSert: false })

  onSides(event) {
    this.setState({ images: this.state.images.slice(0, event.target.value) })
  }

  onLayout(event) {
    this.setState({ layout: event.target.value })
  }

  onDuration(event) {
    this.setState({ duration: parseInt(event.target.value) })
  }

  onEase(event) {
    this.setState({ ease: event.target.value })
  }

  dotClick() {
    if (this.state.sliderValue === 3) {
      this.setState({ sliderValue: 1 })
    } else {
      this.setState({ sliderValue: this.state.sliderValue + 1 })
    }
    const next = document.querySelector(`.${this.props.clas} .next`)

    this.triggerEvent(next, 'click')
  }

  triggerEvent(el, eventName) {
    let event
    if (document.createEvent) {
      event = document.createEvent('HTMLEvents')
      event.initEvent(eventName, true, true)
    } else if (document.createEventObject) {
      // IE < 9
      event = document.createEventObject()
      event.eventType = eventName
    }
    event.eventName = eventName
    if (el.dispatchEvent) {
      el.dispatchEvent(event)
    } else if (el.fireEvent && htmlEvents[`on${eventName}`]) {
      // IE < 9
      el.fireEvent(`on${event.eventType}`, event) // can trigger only real event (e.g. 'click')
    } else if (el[eventName]) {
      el[eventName]()
    } else if (el[`on${eventName}`]) {
      el[`on${eventName}`]()
    }
  }

  renderDescription() {
    if (this.props.description !== '') {
      return <span className="slide-description">{this.props.description}</span>
    }
    return null
  }

  checkClick(event) {
    this.setState({ isShowingModalSert: false })
    // let image = event.target.innerHTML;
    const image = event.target.firstElementChild?.src

    if (event || this.state.images !== undefined) {
      if (event.target.tagName === 'FIGURE') {
        this.setState({ sertImg: image })
        this.handleClick(event.target.innerHTML)
      }
      if (event.target.className === 'docs-img') {
        this.setState({ sertImg: image })
        this.handleClick(event.target.innerHTML)
      }
    }
  }

  renderType() {
    if (this.props.type === 'cert') {
      const class3d = `sert-slid ${this.props.clas}`
      return (
        <div className={class3d}>
          <Carousel3D
            width={this.state.width}
            images={this.state.images}
            ease={this.state.ease}
            duration={this.state.duration}
            layout={this.state.layout}
          />
        </div>
      )
    }

    return (
      <div className={this.props.clas}>
        <Carousel3D
          width={this.state.width}
          images={this.state.images}
          ease={this.state.ease}
          duration={this.state.duration}
          layout={this.state.layout}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="slider3d">
        <EventListener
          target="window"
          onClick={(event) => this.checkClick(event)}
          onTouch={(event) => this.checkClick(event)}
        />
        <div className="tabs tabs-bg">
          <div className="tabs-block  first-block">
            <div className="first-tab-title">{this.props.titleGray}</div>
            <div className="first-tab-desc">{this.props.title}</div>
            {this.renderDescription()}
            <div className="dot-slider" onClick={this.dotClick}>
              <div
                className={classnames('dot-slider-elem', {
                  actives: this.state.sliderValue === 1,
                  inactive: this.state.sliderValue !== 1,
                })}
              />
              <div
                className={classnames('dot-slider-elem', {
                  actives: this.state.sliderValue === 2,
                  inactive: this.state.sliderValue !== 2,
                })}
              />
              <div
                className={classnames('dot-slider-elem', {
                  actives: this.state.sliderValue === 3,
                  inactive: this.state.sliderValue !== 3,
                })}
              />
            </div>
          </div>
          <div className="tabs-block-slider">
            <div>{this.renderType()}</div>
          </div>
          <div />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Slide3d
