import React from 'react'
import { Link } from 'react-router'

class TitleSdm extends React.Component {
  render() {
    return (
      <div>
        <div className="title-block title-sdm-block">
          <div
            itemScope
            itemType="http://schema.org/Product"
            className="title-block-text"
          >
            <h2 itemProp="brand">КРИЗИС ИНТЕГРАТОР</h2>
            <h1 itemProp="name" id="data-control">
              Управление данными
            </h1>
            <span itemProp="description">
              {' '}
              КРИЗИС ИНТЕГРАТОР (Управление данными) – система бюджетного
              планирования в реестре российского ПО Минкомсвязи
            </span>
            <div
              className="display-none"
              itemProp="offers"
              itemScope
              itemType="http://schema.org/Offer"
            >
              <span itemProp="price">Сумма</span>
              <span itemProp="priceCurrency">RUB</span>
            </div>
          </div>
        </div>
        <div className="yellow-line" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TitleSdm
