import React from 'react'
import { Link, IndexLink } from 'react-router'
import ReactDOM from 'react-dom'
import EventListener, { withOptions } from 'react-event-listener'
// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class SliderEcommerce extends React.Component {
  render() {
    return (
      <div>
        <div className="solutions-slider-top-block">
          <div className="solution-text">
            <div className="first-tab-title">
              КРИЗИС ИНТЕГРАТОР Импортозамещение
            </div>
            <span>Успешные проекты</span>
            <span className="about-text-decs">
              Для российских компания возникла ситуация, когда существует угроза
              отключения поддержки иностранного ПО, возможности его обновлений и
              даже возможности того, что обновление может нарушить
              отказоустойчивость бизнеса, сейчас остро стоит проблема поиска
              аналогов иностранному ПО, переходу на аналоги. Наш сайт
              импортозамещения поможет перейти организациям на российское ПО и
              подобрать импортозамещение оборудования. В сферы импортозамещения
              попали услуги программистов, иностранные сервисы, хостинг и аренда
              ПО на иностранных серверах.
            </span>
          </div>
          <div className="slider-team solution-slider">
            <div>
              <div className="partners-auto">
                <div className="partner-solution-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/slider-ux/automatic/1-min.jpg"
                    alt=""
                  />
                  <div className="partner-solution-elem-title">
                    Беспилотник (отечестванная альтернатива)
                  </div>
                  <div className="partner-solution-elem-decs">
                    <p>
                      {' '}
                      Беспилотник имеет на борту камеру 720Р, способную
                      передавать картинку в режиме реального времени.
                    </p>
                    <br />
                    <p> Дальность полёта устройства составляет 3000 метров.</p>
                    <br />
                    <p>
                      {' '}
                      В результате чего Вы можете вести запись фото и видео в
                      очень удаленных местах!
                    </p>
                    <br />
                    <p>
                      {' '}
                      Наш бесплитоник способен летать порядка 20-25 минут на
                      одной батарее с грузом до 1кг, но при наличии нескольких
                      аккумуляторов – время можно умножить в несколько раз!
                    </p>
                  </div>
                </div>
                <div className="partner-solution-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/slider-ux/automatic/2-min.jpeg"
                    alt=""
                  />
                  <div className="partner-solution-elem-title">
                    Shchuka.js (Молниеносная альтернатива зарубежному фреймворку
                    с тем же современным API)
                  </div>
                  <div className="partner-solution-elem-decs">
                    <p> Весит всего 3 КБ.</p>
                    <br />
                    <p>
                      {' '}
                      Вся мощь виртуальных компонентов DOM без накладных
                      расходов:
                    </p>
                    <br />
                    <p>
                      {' '}
                      Знакомая API и шаблоны: Класс ES6, хуки и функциональные
                      компоненты
                    </p>
                    <br />
                    <p>
                      {' '}
                      Высоко оптимизированный алгоритм diff и плавная гидратация
                      при рендеринге на стороне сервера.
                    </p>
                    <br />
                    <p> Поддерживает все современные браузеры.</p>
                  </div>
                </div>
                <div className="partner-solution-elem">
                  <img
                    src="https://609111.selcdn.ru/ki/static/images/slider-ux/automatic/3.png"
                    alt=""
                  />
                  <div className="partner-solution-elem-title">
                    LogDoc - Отечественная платформа для просмотра и анализ
                    логов в реальном времени.
                  </div>
                  <div className="partner-solution-elem-decs">
                    <p>
                      {' '}
                      Быстродействие и уникальные возможности по анализу логов,
                      в том числе отображение многострочном формате.
                    </p>
                    <br />
                    <p>
                      {' '}
                      Уменьшение Time To Market за счет описания структуры логов
                      на стороне инструмента, без дополнительных трудозатрат на
                      структуризацию логов в приложениях.
                    </p>
                    <br />
                    <p>
                      {' '}
                      После перехода с Kibana на LogDoc при разработке
                      собственных продуктов выявление проблем выросло в
                      несколько раз.
                    </p>
                    <br />
                    <p>
                      {' '}
                      Горизонтальное масштабирование мощностей позволяет
                      оптимизировать затраты на оборудование.
                    </p>
                  </div>
                </div>
                <div className="partner-solution-elem">
                  <img
                    style={{ height: '40px' }}
                    src="https://609111.selcdn.ru/ki/static/images/slider-ux/automatic/4.png"
                    alt=""
                  />
                  <div className="partner-solution-elem-title"> ADMIN SaaS</div>
                  <div className="partner-solution-elem-decs">
                    Основной наш продукт ADMIN SaaS, который является коробочным
                    решением для гос. проектов и крупных коммерческих
                    предприятий.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default SliderEcommerce
