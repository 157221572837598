import React from 'react'
import { Link, IndexLink } from 'react-router'

class InfoTabs extends React.Component {
  render() {
    return (
      <div>
        <div className="infotabs even">
          <div className="infotabs-block first-block react">
            <div className="first-tab-title">Решения</div>
            <div className="first-tab-desc">
              Кластер решений на единой платформе КРИЗИС ИНТЕГРАТОР
            </div>
            {/* <div className="first-tab-link">Подробнее <span className="infotabs-block-text-link-icon"></span></div>*/}
          </div>
          <div className="infotabs-block">
            <div className="infotabs-block-img security-img" />
            <div className="infotabs-block-text">
              <div className="infotabs-block-text-title">КИ Безопасность</div>
              <div className="infotabs-block-text-desc">
                Когнитивная платформа обеспечения безопасности на базе средств
                обработки видеопотока, методов машинного обучения и
                риск-ориентированного подхода
              </div>
              <Link to="security">
                <div className="infotabs-block-text-link">
                  Подробнее <span className="infotabs-block-text-link-icon" />
                </div>
              </Link>
            </div>
          </div>
          <div className="infotabs-block">
            <div className="infotabs-block-img tsecurity-img" />
            <div className="infotabs-block-text">
              <div className="infotabs-block-text-title">
                КИ Транспортная безопасность
              </div>
              <div className="infotabs-block-text-desc">
                Система на базе технологий ГЛОНАСС, средств и методов
                психофизиологического мониторинга человека, видеоанализа и
                машинного обучения
              </div>
              <Link to="transport-security">
                <div className="infotabs-block-text-link">
                  Подробнее <span className="infotabs-block-text-link-icon" />
                </div>
              </Link>
            </div>
          </div>
          <div className="infotabs-block">
            <div className="infotabs-block-img data-control-img" />
            <div className="infotabs-block-text">
              <div className="infotabs-block-text-title">
                КИ Управление данными
              </div>
              <div className="infotabs-block-text-desc">
                BI платформа для автоматизации процессов бюджетирования и
                финансового планирования
              </div>
              <Link to="data-control">
                <div className="infotabs-block-text-link">
                  Подробнее <span className="infotabs-block-text-link-icon" />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="infotabs odd">
          <div className="infotabs-block">
            <div className="infotabs-block-img stv-img" />
            <div className="infotabs-block-text">
              <div className="infotabs-block-text-title">КИ Представление</div>
              <div className="infotabs-block-text-desc">
                Система генерации управленческой отчетности и визуализации
                данных
              </div>
              <Link to="performance">
                <div className="infotabs-block-text-link">
                  Подробнее <span className="infotabs-block-text-link-icon" />
                </div>
              </Link>
            </div>
          </div>
          <div className="infotabs-block">
            <div className="infotabs-block-img pred-img" />
            <div className="infotabs-block-text">
              <div className="infotabs-block-text-title">КИ Аналитика</div>
              <div className="infotabs-block-text-desc">
                Система прогнозной аналитики и глубокого анализа данных
              </div>
              <Link to="analytics">
                <div className="infotabs-block-text-link">
                  Подробнее <span className="infotabs-block-text-link-icon" />
                </div>
              </Link>
            </div>
          </div>
          <div className="infotabs-block">
            <div className="infotabs-block-img ecosystem-img" />
            <div className="infotabs-block-text">
              <div className="infotabs-block-text-title">КИ Экосистема</div>
              <div className="infotabs-block-text-desc">
                Когнитивная платформа работы с большими данными для Цифровой
                экономики
              </div>
              <Link to="/ecosystem">
                <div className="infotabs-block-text-link">
                  Подробнее <span className="infotabs-block-text-link-icon" />
                </div>
              </Link>
            </div>
          </div>
          <div className="infotabs-block">
            <div className="infotabs-block-img e-com-img" />
            <div className="infotabs-block-text">
              <div className="infotabs-block-text-title">
                КИ Импортозамещение
              </div>
              <div className="infotabs-block-text-desc">
                Комплекс решений в области автоматизации
              </div>
              <Link to="/automation">
                <div className="infotabs-block-text-link">
                  Подробнее <span className="infotabs-block-text-link-icon" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default InfoTabs
