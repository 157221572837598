import React from 'react'
import { Link } from 'react-router'

class TitleSecurity extends React.Component {
  render() {
    return (
      <div>
        <div className="title-block title-security-block">
          <div
            itemScope
            itemType="http://schema.org/Product"
            className="title-block-text"
          >
            <h2 itemProp="brand">КРИЗИС ИНТЕГРАТОР</h2>
            <h1 itemProp="name" id="security">
              Безопасность
            </h1>
            <span itemProp="description">
              Аппаратно-программная платформа для построения комплексных систем
              безопасности на базе средств машинного обучения, искусственного
              интеллекта, способов и методов работы с большими данными, средств
              обработки видеопотока
            </span>
            <div
              className="display-none"
              itemProp="offers"
              itemScope
              itemType="http://schema.org/Offer"
            >
              <span itemProp="price">Сумма</span>
              <span itemProp="priceCurrency">RUB</span>
            </div>
          </div>
        </div>
        <div className="yellow-line" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TitleSecurity
