import React from 'react'
import { Link } from 'react-router'

class TitleAbout extends React.Component {
  render() {
    return (
      <div>
        <div className="title-block title-about-block">
          <div className="title-block-text">
            <h1>О компании</h1>
            <span>
              КРИЗИС ИНТЕГРАТОР – российская инновационная компания, разработчик
              бизнес-приложений класса BI / ERP, платформ сбора, анализа и
              хранения больших данных, E-commerce решений, интеллектуальных
              систем безопасности
            </span>
          </div>
        </div>
        <div className="yellow-line" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default TitleAbout
