import React, { Component } from 'react'
import SecurityBlock from 'components/Solutions/Security'
import TitleSecurity from 'components/TitleBlock/title_security'
import Header from 'components/Header/Header'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Security extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="КРИЗИС ИНТЕГРАТОР Безопасность – платформа для построения комплексных систем безопасности на базе средств машинного обучения, искусственного интеллекта, способов и методов работы с большими данными, средств обработки видеопотока"
          keywords="Security, разработка, аппаратно-программная платформа, системы безопасности, мониторнг, Москва, Россия, КРИЗИС ИНТЕГРАТОР"
          description="КРИЗИС ИНТЕГРАТОР Security позволяет осуществлять мониторинг безопасности объекта в реальном времени, прогнозировать негативные сценарии развития ситуации, обеспечивает соответствующие службы информацией для оперативного реагирования на угрозы."
        />
        <Header />
        <TitleSecurity />
        <SecurityBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Security
