import React from 'react'
import { Link, IndexLink } from 'react-router'
import classnames from 'classnames'
import Slider from '../Slider/SliderEcosystem/SliderEcosystem'

class Ecosystem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slide: 'one',
    }
    this.checkVerif = this.checkVerif.bind(this)
  }

  checkVerif(value) {
    this.setState({
      slide: value,
    })
  }

  render() {
    return (
      <div className="ecosystem-block">
        <Slider />
        <div className="ecosystem-block-scheme">
          <div className="ecosystem-block-scheme-title">
            <h2>Экосистема </h2>
          </div>
          <div className="ecosystem-block-scheme-block">
            <div className="ecosystem-block-scheme-block-left">
              <div className="ecosystem-block-scheme-block-left-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/users.svg"
                  alt=""
                />
                <span>Индивидуальные предприниматели и студенты</span>
              </div>
              <div className="ecosystem-block-scheme-block-left-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/keys.svg"
                  alt=""
                />
                <span>Госкомпании и корпорации</span>
              </div>
              <div className="ecosystem-block-scheme-block-left-elem">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/keys.svg"
                  alt=""
                />
                <span>Частные компании и стартапы</span>
              </div>
            </div>
            <div className="ecosystem-block-scheme-block-right">
              <div className="ecosystem-block-scheme-block-right-elem">
                <h3>Отрасли/секторы экономики и бизнеса</h3>
                <div className="ecosystem-block-scheme-block-right-elem-top">
                  <div className="ecosystem-block-scheme-block-right-elem-top-item">
                    Транспорт
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-top-item">
                    ИТ/Интернет
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-top-item">
                    Госсектор
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-top-item">
                    ЖКХ
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-top-item">
                    Производство
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-top-item">
                    Оборона
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-top-item">
                    Коммерция
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-top-item">
                    Здравохранение
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-top-item">
                    Образование
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-top-item circle-white">
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
              <img
                className="ecosystem-arrow-top"
                src="https://609111.selcdn.ru/ki/static/images/arrow.svg"
                alt=""
              />
              <div className="ecosystem-block-scheme-block-right-elem">
                <h3>Отраслевые решения на платформе Экосистема</h3>
                <div className="ecosystem-block-scheme-block-right-elem-center">
                  <div className="ecosystem-block-scheme-block-right-elem-center-item">
                    <div>
                      Системы управления ЖКХ на базе технологий «Интернета
                      Вещей» и геопозиционирования
                    </div>
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-center-item">
                    <div>Умный E-commerce</div>
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-center-item">
                    <div>Система Транспортной безопасности</div>
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-center-item">
                    <div>
                      ERP/BI/CRM решения для корпораций, среднего и малого
                      бизнеса
                    </div>
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-center-item">
                    <div>Системы принятия решений всех уровней</div>
                  </div>
                  <div className="ecosystem-block-scheme-block-right-elem-center-item">
                    <div>Многие другие прикладные решения</div>
                  </div>
                </div>
              </div>
              <img
                className="ecosystem-arrow-bottom"
                src="https://609111.selcdn.ru/ki/static/images/arrow.svg"
                alt=""
              />
              <div className="ecosystem-block-scheme-block-right-elem">
                <h3>Открытая платформа (Экосистема)</h3>
                <div className="ecosystem-block-scheme-block-right-elem-full-bottom">
                  Инфраструктура распределенных центров обработки данных (ЦОД).
                  Независимые провайдеры.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="yellow-line" />
        <div className="ecosystem-solution-desc">
          <div className="ts-security-solution-desc-title">
            <div className="ts-security-solution-desc-title-btn">
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  {
                    'ts-security-btn-active': this.state.slide === 'one',
                  },
                )}
                onClick={() => this.checkVerif('one')}
              >
                Транспортная безопасность
              </div>
              <div
                className={classnames(
                  'ts-security-solution-desc-title-btn-elem',
                  {
                    'ts-security-btn-active': this.state.slide === 'two',
                  },
                )}
                onClick={() => this.checkVerif('two')}
              >
                Безопасность объектов
              </div>
            </div>
            <div className="ts-security-solution-desc-title-btn-logo">
              Экосистема
            </div>
          </div>
          <div
            className={classnames('', {
              block: this.state.slide === 'one',
              'display-none': this.state.slide !== 'one',
            })}
          >
            <div className="ecosystem-solution-desc-slide">
              <div className="ecosystem-solution-desc-slide-text">
                <h2>Экосистема в транспортной безопасности</h2>
                <span>
                  Система позволяет в реальном времени реализовать полный цикл
                  сбора, анализа, хранения, визуализации информации о
                  местоположении транспортного средства, физическом и
                  психоэмоциональном состоянии водителя, рассчитать на основе
                  этих данных показатели риска, выработать ответные действия.
                </span>
                <span>
                  <b>Время отработки цикла – до 2 секунд.</b>
                </span>
              </div>
              <div className="ts-security-solution-desc-slide-block hadr-block">
                <div className="hadr-block-title">
                  <div className="hadr-block-title-icon">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_10.svg"
                      alt=""
                    />
                    <div>Глонас</div>
                  </div>
                  <div className="hadr-block-title-icon w60">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_37.svg"
                      alt=""
                    />
                    <div>GPRS</div>
                    <div className="hadr-block-title-arrow-border" />
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group_37.svg"
                      alt=""
                    />
                    <div>GPRS</div>
                  </div>
                </div>
                <div className="hadr-block-body">
                  <div className="hadr-block-body-block-icon">
                    <div className="hadr-block-body-block-icon-top">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/auto.svg"
                        alt=""
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/boat.svg"
                        alt=""
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/bus.svg"
                        alt=""
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/plane.svg"
                        alt=""
                      />
                    </div>
                    <div className="hadr-block-body-block-icon-body">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/Page1.png"
                        alt=""
                      />
                    </div>
                    <div className="hadr-block-body-block-icon-bottom">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/bus1.svg"
                        alt=""
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/group-31.svg"
                        alt=""
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/bus2.svg"
                        alt=""
                      />
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/auto.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hadr-block-body-block-main">
                    <div className="hadr-block-body-block-main-elem">
                      <div className="hadr-block-body-block-main-elem-title">
                        Транспортное средство
                      </div>
                      <div className="hadr-block-body-block-main-elem-text cam span-yellow-dot">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_34.svg"
                          alt=""
                        />
                        <ul>
                          <li>Ненормальное поведен</li>
                          <li>Возбужденное или подавленное состояние</li>
                        </ul>
                      </div>
                      <div className="hadr-block-body-block-main-elem-text mind span-yellow-dot">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Z.svg"
                          alt=""
                        />
                        <ul>
                          <li>Сонливость</li>
                          <li>Усталость</li>
                          <li>Стресс</li>
                          <li>Сонливость</li>
                        </ul>
                      </div>
                      <div className="hadr-block-body-block-main-elem-text watch span-yellow-dot">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_35.svg"
                          alt=""
                        />
                        <ul>
                          <li>Усталость</li>
                          <li>Стресс</li>
                        </ul>
                      </div>
                    </div>
                    <div className="hadr-block-body-block-main-elem-icon">
                      <div className="hadr-block-body-block-main-elem-icon-block">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_38.svg"
                          alt=""
                        />
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_39.svg"
                          alt=""
                        />
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_40.svg"
                          alt=""
                        />
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/heart.svg"
                          alt=""
                        />
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_41.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="hadr-block-body-block-arrow">
                    <div className="hadr-block-body-block-arrow-elem">
                      <img
                        className="revert-90"
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                        alt=""
                      />
                      <div className="">Данные</div>
                      <img
                        className="revert90"
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                        alt=""
                      />
                    </div>
                    <div className="hadr-block-body-block-arrow-elem">
                      <img
                        className="revert-90"
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                        alt=""
                      />
                      <div className="">Данные</div>
                      <img
                        className="revert90"
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hadr-block-body-block-main-two">
                    <div className="hadr-block-body-block-main-two-elem">
                      <div className="hadr-block-body-block-main-two-elem-title">
                        Центр обработки данных
                      </div>
                      <div className="hadr-block-body-block-main-two-elem-img">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/ecosystem.svg"
                          alt=""
                        />
                        {/* <span>Экосистема</span>*/}
                      </div>
                      <div className="hadr-block-body-block-main-two-elem-text span-yellow-dot">
                        <ul>
                          <li>Сбор</li>
                          <li>Анализ</li>
                          <li>Моделирование</li>
                          <li>Прогнозирование</li>
                          <li>Расчет рисков</li>
                        </ul>
                      </div>
                    </div>

                    <div className="hadr-block-body-block-main-two-arrow">
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow-small.svg"
                        alt=""
                      />
                      <span>Данные</span>
                      <img
                        src="https://609111.selcdn.ru/ki/static/images/arrow-yellow-small.svg"
                        alt=""
                      />
                    </div>

                    <div className="hadr-block-body-block-main-two-elem">
                      <div className="hadr-block-body-block-main-two-elem-icon">
                        <img
                          src="https://609111.selcdn.ru/ki/static/images/Group_12.svg"
                          alt=""
                        />
                        <div>Служба мониторинга и раннего предупреждения</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classnames('', {
              block: this.state.slide === 'two',
              'display-none': this.state.slide !== 'two',
            })}
          >
            <div className="ecosystem-solution-desc-slide-top-arrow">
              <img
                src="https://609111.selcdn.ru/ki/static/images/group-29-copy.svg"
                alt=""
              />
            </div>
            <div>
              <div className="ecosystem-solution-desc-slide-elem">
                <h3>Экосистема</h3>
                <div className="ecosystem-solution-desc-slide-elem-body-text">
                  <span>Реализует хранение и обработку данных</span>
                  <span>
                    Позволяет осуществлять предиктивный анализ поведения с
                    использованием нейронных сетей
                  </span>
                  <span>
                    Повышает эффективность алгоритмов, используя когнитивные
                    технологии
                  </span>
                  <span>
                    Представляет собой отказоустойчивый кластер на базе Apache
                    Hadoop
                  </span>
                </div>
                <div className="ecosystem-solution-desc-slide-elem-bottom-block">
                  <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem">
                    Data Processing
                  </div>
                </div>
              </div>
              <div className="ecosystem-solution-desc-slide-elem">
                <h3>Экосистема Видео</h3>
                <div className="ecosystem-solution-desc-slide-elem-body-text">
                  <span>
                    Агрегирует и подготавливает данные для дальнейшего
                    моделирования
                  </span>
                  <span>
                    Поддерживает работу с десятками видеокамер и неограниченным
                    количеством других устройств
                  </span>
                  <span>
                    Генерирует события и риски на основе данных из КИ Экосистема
                  </span>
                  <span>
                    Интегрируется с различными внешними источниками данных
                  </span>
                  <span>
                    Генерирует данные для КРИЗИС ИНТЕГРАТОР (Представление)
                  </span>
                </div>
                <div className="ecosystem-solution-desc-slide-elem-bottom-block">
                  <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem">
                    Features Extraction
                  </div>
                  <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem">
                    Data Transform
                  </div>
                  <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem">
                    Event Management
                  </div>
                </div>
              </div>
              <div className="ecosystem-solution-desc-slide-elem">
                <h3>КРИЗИС ИНТЕГРАТОР - Представление</h3>
                <div className="ecosystem-solution-desc-slide-elem-body-text">
                  <span>
                    Использует большинство известных форм представления
                  </span>
                  <span>
                    Оперирует широким спектром функциональных возможностей и
                    средствами интерактивной работы с аналитикой
                  </span>
                  <span>
                    Обладает продвинутым интерфейсом и является идеальным
                    средством для работы аналитика
                  </span>
                </div>

                <div className="ecosystem-solution-desc-slide-elem-bottom-block">
                  <div className="ecosystem-solution-desc-slide-elem-bottom-block-kostili">
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                      Graphs
                    </div>
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                      Timetables
                    </div>
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                      Heat maps
                    </div>
                  </div>
                  <div className="ecosystem-solution-desc-slide-elem-bottom-block-kostili">
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                      Maps
                    </div>
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                      Calculations
                    </div>
                    <div className="ecosystem-solution-desc-slide-elem-bottom-block-elem ">
                      Color workshop
                    </div>
                  </div>
                </div>
              </div>
              <div className="ecosystem-solution-desc-slide-elem back-none">
                <div className="ecosystem-solution-desc-slide-elem-item span-yellow-dot">
                  <h3>Пользователи</h3>
                  <span>Events</span>
                  <span>Lost items</span>
                  <span>Risks</span>
                  <span>Person Identification</span>
                  <span>Unusual behavior</span>
                </div>
                <div className="ecosystem-solution-desc-slide-elem-item">
                  <h3>Видеокамеры/Передающие устройства/Датчики</h3>
                  <div className="ecosystem-solution-icon-block">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/123.svg"
                      alt=""
                    />
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/invalid-name(3).svg"
                      alt=""
                    />
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/2343.svg"
                      alt=""
                    />
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/345.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ecosystem-solution-desc-slide-bottom-arrow">
              <div className="ecosystem-solution-desc-slide-bottom-arrow-one">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-29(1).svg"
                  alt=""
                />
              </div>
              <div className="ecosystem-solution-desc-slide-bottom-arrow-two">
                <img
                  className="revert"
                  src="https://609111.selcdn.ru/ki/static/images/group-29-copy.svg"
                  alt=""
                />
              </div>
              <div className="ecosystem-solution-desc-slide-bottom-arrow-three">
                <img
                  src="https://609111.selcdn.ru/ki/static/images/group-29-copy-2.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default Ecosystem
