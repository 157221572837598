import React from 'react'

class CallBack extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowingModal: false,
    }
  }

  handleClick = () => this.setState({ isShowingModal: true })
  handleClose = () => this.setState({ isShowingModal: false })

  render() {
    return (
      <div>
        <div className="callback-block">
          <div className="callback-header">Присоединяйтесь к нам!</div>
          <div className="callback-desc">
            Станьте нашим партнером и получите возможность продавать уникальные
            продукты КРИЗИС ИНТЕГРАТОР, а также доступ к нашей технической и
            маркетинговой поддержке.
          </div>
          <div className="callback-button" onClick={this.handleClick}>
            Отправить заявку
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default CallBack
