import React from 'react'
import MetaTags from 'react-meta-tags'

class MetaTagsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <MetaTags>
        <title>{this.props.title}</title>
        <meta name="description" content={this.props.description} />
        <meta name="keywords" content={this.props.keywords} />
      </MetaTags>
    )
  }
}

const mapStateToProps = (state) => ({})

export default MetaTagsComponent
