// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/title_image/notfound_title.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notefound-page {
  text-align: center;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}),
    linear-gradient(
      135deg,
      #110c3c 0%,
      #291054 25.92%,
      #103e82 67.3%,
      #110c3c 100%
    );
  box-sizing: border-box;
  padding: 190px 0;
  background-size: cover;
}

.notefound-page-img img {
  padding-right: 30px;
}

.notefound-page-img img:last-child {
  padding-right: 0;
}

.notefound-page-text {
  font-size: 40px;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 40px;
}

.notefound-page-button {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/assets/static/style/notfound.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB;;;;;;;KAOG;EACH,sBAAsB;EACtB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".notefound-page {\n  text-align: center;\n  background: url('../images/title_image/notfound_title.png'),\n    linear-gradient(\n      135deg,\n      #110c3c 0%,\n      #291054 25.92%,\n      #103e82 67.3%,\n      #110c3c 100%\n    );\n  box-sizing: border-box;\n  padding: 190px 0;\n  background-size: cover;\n}\n\n.notefound-page-img img {\n  padding-right: 30px;\n}\n\n.notefound-page-img img:last-child {\n  padding-right: 0;\n}\n\n.notefound-page-text {\n  font-size: 40px;\n  color: #fff;\n  padding-top: 30px;\n  padding-bottom: 40px;\n}\n\n.notefound-page-button {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
