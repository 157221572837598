import React, { Component } from 'react'
import SdmBlock from 'components/Solutions/Sdm'
import TitleSdm from 'components/TitleBlock/title_sdm'
import Header from 'components/Header/Header'
import CallBackTwo from 'components/Callback/CallBackTwo'
import FooterChild from 'components/Footer/FooterChild'
import MetaTags from 'components/MetaTags/MetaTags'

class Sdm extends Component {
  render() {
    return (
      <div>
        <MetaTags
          title="Data Managemen"
          keywords="Data Managemen, КРИЗИС ИНТЕГРАТОР"
          description="Data Managemen компании КРИЗИС ИНТЕГРАТОР"
        />
        <Header />
        <TitleSdm />
        <SdmBlock />
        <CallBackTwo />
        <FooterChild />
      </div>
    )
  }
}

export default Sdm
