import React from 'react'
import sendEmailValidator, { sendLogin } from '../Validator/sendEmailValidator'
import InputMask from 'react-input-mask'
import Modal from 'react-modal'

class modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      checkbox: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChecked = this.handleChecked.bind(this)
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleChecked(event) {
    this.setState({
      ...this.state,
      checkbox: !this.state.checkbox,
    })
  }

  handleClickSendHui() {
    const Validator = sendEmailValidator(this.state)
    if (Validator.isValid) {
      console.log('Mailer.sendMail(this.state);')
    } else {
      this.setState({
        errors: Validator.errors,
      })
    }
  }

  handleClickLogin() {
    const Validator = sendLogin(this.state)
    if (Validator.isValid) {
      console.log('Mailer.sendLogin(this.state);')
    } else {
      this.setState({
        errors: Validator.errors,
      })
    }
  }

  render() {
    const { showModal, showLogin, close } = this.props
    return (
      <div>
        {showModal && (
          <Modal isOpen={showModal} onRequestClose={close}>
            <div className="callback-modal">
              <div className="modal-request">
                <div className="modal-request-form-block">
                  <div className="modal-request-form-block-top">
                    <h2>Заявка на партнерство</h2>
                    <div className="form-callback">
                      <div
                        className={
                          this.state.errors.fio === 'valid'
                            ? 'form-callback-elem valid'
                            : this.state.errors.fio
                            ? 'form-callback-elem errors'
                            : 'form-callback-elem'
                        }
                      >
                        <span className="form-callback-elem-text">
                          Как к Вам обращаться?
                        </span>
                        <input
                          name="fio"
                          onChange={this.handleChange}
                          type="text"
                          placeholder="Ф.И.О"
                          required
                        />
                        <span className="errors-text">
                          {this.state.errors.fio}
                        </span>
                      </div>
                      <div
                        className={
                          this.state.errors.email === 'valid'
                            ? 'form-callback-elem twin-elem valid'
                            : this.state.errors.email
                            ? 'form-callback-elem twin-elem errors'
                            : 'form-callback-elem twin-elem'
                        }
                      >
                        <span className="form-callback-elem-text">E-mail</span>
                        <input
                          name="email"
                          onChange={this.handleChange}
                          type="text"
                          placeholder="sample@mail.ru"
                          required
                        />
                        <span className="errors-text">
                          {this.state.errors.email}
                        </span>
                      </div>
                      <div
                        className={
                          this.state.errors.phone === 'valid'
                            ? 'form-callback-elem twin-elem valid'
                            : this.state.errors.phone
                            ? 'form-callback-elem twin-elem errors'
                            : 'form-callback-elem twin-elem'
                        }
                      >
                        <span className="form-callback-elem-text">Телефон</span>
                        <InputMask
                          mask="+7(999)999-99-99"
                          maskChar=""
                          name="phone"
                          onChange={this.handleChange}
                          type="tel"
                          placeholder="+7 (___)___-__-__"
                          required
                        />
                        <span className="errors-text">
                          {this.state.errors.phone}
                        </span>
                      </div>
                      <div
                        className={
                          this.state.errors.description === 'valid'
                            ? 'form-callback-elem textarea twin-elem valid'
                            : this.state.errors.description
                            ? 'form-callback-elem twin-elem textarea errors'
                            : 'form-callback-elem textarea twin-elem'
                        }
                      >
                        <span className="form-callback-elem-text">
                          Описание
                        </span>
                        <textarea
                          name="description"
                          onChange={this.handleChange}
                          type="text"
                          placeholder="Мне интересно сотрудничество по ..."
                          required
                        />
                        <span className="errors-text">
                          {this.state.errors.email}
                        </span>
                      </div>
                    </div>
                    <div className={this.state.errors.checkbox ? 'errors' : ''}>
                      <label>
                        <input
                          onChange={this.handleChecked}
                          checked={this.state.checkbox}
                          type="checkbox"
                          className="checkbox"
                          hidden
                        />
                        <span className="checkbox-custom" />
                        <span className="offer-check-text">
                          Согласен с{' '}
                          <a
                            href="static/documents/files/Политика конфиденциальности_19012018.pdf"
                            target="_blank"
                          >
                            Политикой обработки персональных данных
                          </a>
                        </span>
                        <span className="errors-text">
                          {this.state.errors.checkbox}
                        </span>
                      </label>
                    </div>
                    <div className="upload-file">
                      {/* <img src="/static/images/download_file.png" alt=""/>*/}
                      {/* <div className="file-text">*/}
                      {/* <span className="file-title">Загрузить документы</span>*/}
                      {/* <span className="file-desc">pdf, doc, jpg не более 15 mb</span>*/}
                      {/* </div>*/}
                    </div>
                  </div>

                  <div className="modal-request-form-block-bottom">
                    <div
                      className="form-request-button-submit"
                      onClick={() => this.handleClickSendHui()}
                    >
                      Отправить заявку
                    </div>
                  </div>
                </div>
                <div className="modal-request-offer-block">
                  <div className="modal-request-offer-block-top">
                    <h2>Документы</h2>
                    <div className="modal-request-offer-block-text">
                      {/* <span className="modal-request-offer-block-text-elem">Вам будут доступны продукты КРИЗИС ИНТЕГРАТОР, позволяющие мгновенно и эффективно обрабатывать большие объемы данных.</span>*/}
                      <span className="modal-request-offer-block-text-elem">
                        Перед отправкой заявки скачайте и ознакомьтесь,
                        пожалуйста, с документами, на основании которых
                        осуществляется партнерство.
                        <div className="offer-check-text">
                          Размещение на сайте указанных документов не является
                          публичной офертой на заключение партнерского
                          соглашения с любым обратившимся либо предложением
                          делать оферты, а направление заявки не расценивается
                          как заключение какого-либо обязывающего соглашения
                          между Вами и ООО "КРИЗИС ИНТЕГРАТОР".{' '}
                        </div>
                        <div className="offer-check-text">
                          После направления заявки мы свяжемся с Вами для
                          обсуждения условий сотрудничества
                        </div>
                      </span>
                    </div>
                    <a
                      href="/static/download/po?name=КРИЗИС ИНТЕГРАТОР_Двустороннее соглашение о конфиденциальности_21.08.2017.docx"
                      className="download-file"
                    >
                      <img src="/static/images/pdf.svg" alt="icon" />
                      <div className="file-text">
                        <span className="file-title">
                          Соглашение о неразглашении информации
                        </span>
                        <span className="file-desc">pdf, 163 kb</span>
                      </div>
                    </a>
                    <a
                      href="/static/download/po?name=КРИЗИС ИНТЕГРАТОР_Тёрмшит для партнеров_21.08.2017.docx"
                      className="download-file"
                    >
                      <img src="/static/images/pdf.svg" alt="icon" />
                      <div className="file-text">
                        <span className="file-title">
                          Предварительное соглашение об условиях сотрудничества
                        </span>
                        <span className="file-desc">pdf, 181 kb</span>
                      </div>
                    </a>
                  </div>
                  <div className="modal-request-offer-block-bottom">
                    <div
                      onClick={() => console.log('openLogin()')}
                      className="form-request-button-lk"
                    >
                      <img src="/static/images/user_button.svg" alt="icon" />
                      Личный кабинет партнера
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {showLogin && (
          <Modal onClose={() => close()}>
            <div className="callback-modal">
              <div className="modal-request">
                <div
                  style={{
                    height: 'auto',
                    width: 'auto',
                  }}
                  className="modal-partner"
                >
                  <div className="modal-request-form-block-top">
                    <h2>Авторизация партнера</h2>
                    <div className="form-callback">
                      <div
                        className={
                          this.state.errors.email === 'valid'
                            ? 'form-callback-elem valid'
                            : this.state.errors.email
                            ? 'form-callback-elem errors'
                            : 'form-callback-elem'
                        }
                      >
                        <span className="form-callback-elem-text">
                          Электронный адрес
                        </span>
                        <input
                          name="email"
                          onChange={this.handleChange}
                          type="text"
                          placeholder="email@email.ru"
                          required
                        />
                        <span className="errors-text">
                          {this.state.errors.email}
                        </span>
                      </div>
                      <div
                        className={
                          this.state.errors.password === 'valid'
                            ? 'form-callback-elem valid'
                            : this.state.errors.password
                            ? 'form-callback-elem errors'
                            : 'form-callback-elem'
                        }
                      >
                        <span className="form-callback-elem-text">Пароль</span>
                        <input
                          name="password"
                          onChange={this.handleChange}
                          type="password"
                          placeholder="****"
                          required
                        />
                        <span className="errors-text">
                          {this.state.errors.password}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="modal-request-form-block-bottom">
                    <div
                      className="form-request-button-submit"
                      onClick={() => this.handleClickLogin()}
                    >
                      Войти
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default modal
