import React from 'react'
import { Link, IndexLink } from 'react-router'
import ReactDOM from 'react-dom'
import EventListener, { withOptions } from 'react-event-listener'
// var Carousel = require('nuka-carousel');
const { Carousel } = require('react-responsive-carousel')

class SliderDataControl extends React.Component {
  render() {
    return (
      <div>
        <div className="solutions-slider-top-block">
          <div className="solution-text">
            <div className="first-tab-title">
              КРИЗИС ИНТЕГРАТОР Управление данными
            </div>
            <span>Возможности</span>
            <span className="about-text-decs">
              КРИЗИС ИНТЕГРАТОР (Управление данными) – платформа класса BI/ERP
              для создания систем коллективной работы, хранения и обмена
              бизнес-информацией в реальном времени, формирования отчетности и
              поддержки управленческих решений в области финансового
              планирования, бюджетирования в территориально-распределенных
              компаниях и государственных организациях
            </span>
          </div>
          <div className="slider-team solution-slider">
            {/* <EventListener target={document} onClick={(event) => this.sliders(event)}/>*/}

            <Carousel interval={3000} showThumbs={false}>
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 52@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Высокопроизводительное хранилище данных с возможностями
                      «in-memory processing»
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 53@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Встроенные средства моделирования и настройки
                      бизнес-процессов без программирования
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 54@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Модульная архитектура, позволяющая по заданию
                      пользователей оперативно создавать новые функциональные
                      подсистемы на единой платформе
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 56@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Единый web-интерфейс для всех категорий пользователей,
                      адаптированный для работы на мобильных устройствах
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="">
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 1.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Новый подход к конструированию интерфейсов пользователя.
                      Высокий стандарт дизайна системы
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 60@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Снижение в 5─7 раз времени, затрачиваемого пользователем
                      на осуществление операций в системе по сравнению с
                      аналогичными решениями
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/Group 57@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      Высокая скорость генерации аналитической и
                      регламентированной отчетности для оперативного принятия
                      решений владельцами бизнес-процессов за счет интеграции с
                      КРИЗИС ИНТЕГРАТОР - (Представление){' '}
                    </div>
                  </div>
                  <div className="partner-solution-elem">
                    <img
                      src="https://609111.selcdn.ru/ki/static/images/icon8@2x.svg"
                      alt=""
                    />
                    <div className="partner-solution-elem-title" />
                    <div className="partner-solution-elem-decs">
                      100% российская разработка для использования в
                      государственном секторе
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

export default SliderDataControl
